import React, { Component } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import CSVReader from "react-csv-reader";
import Spinner from "react-bootstrap/Spinner";
import ListGroup from "react-bootstrap/ListGroup";
import TierDropdown from "../parts/tierDropdown";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { NotificationManager } from "react-notifications";

import ListGroupItem from "react-bootstrap/ListGroupItem";

const parserOptions = {
  header: true,
  dynamicTyping: true,
  skipEmptyLines: true,
  transformHeader: (header) => header.toLowerCase().replace(/\W/g, "_"),
};

class EmailListImport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      emails: {},
      template: "1",
      isLoading: false,
      msg: "",
      showResult: false,
      tiers: this.props.tiers,
      ztiers: this.props.ztiers,
      simpleTiers: this.props.simpleTiers,
      sendingEmails: false,
      percent: 0,
    };
  }

  sentEmails = 0;
  emailsToSend = 0;
  failedEmails = 0;
  successfulEmails = 0;

  sendEmails = async () => {
    this.setState({
      sendingEmails: true,
      isLoading: true,
    });

    let formatted = [];

    this.state.emails.forEach((items) => {
      const tierId = items["tierId"];
      //   items.forEach((item) => {
      //     this.submitEmailEndpoint(item[0], tierId, item[1]);
      //   });
      //this.submitEmailEndpoint(item)
      items.forEach((item) => {
        if (!item[1]) item[1] = "No note";

        formatted.push({
          email: item[0],
          tier: tierId,
          note: item[1],
        });
      });
    });

    const token = await this.props.token();

    const body = JSON.stringify({
      emails: formatted,
      type: "email",
      bulk: true,
    });

    try {
      const response = await fetch(
        "https://api.shieldshot.com/emails/add",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          body: body,
          method: "POST",
        }
      );

      const responseData = await response;
      if (responseData.status === 200) {
        NotificationManager.success("Import Success", "", 10000);
        this.props.hideModal();
      } else {
        NotificationManager.error(
          "Error: " + responseData.status,
          "Failed to add Email",
          10000
        );
      }
    } catch (err) {
      NotificationManager.error("Error: " + err, "Failed to add Email", 10000);
    }
  };

  percentTracker = () => {
    this.sentEmails++;
    const percent = (this.sentEmails / this.emailsToSend) * 100;
    this.setState({
      percent: percent,
    });

    if (percent >= 100) {
      this.props.hideModal(this.successfulEmails, this.failedEmails);
    }
  };

  setTier = (color, id, name, tierId, index) => {
    console.log("tier", id);
    console.log("index", index);

    let emails = this.state.emails;

    emails[index]["found"] = "true";
    emails[index]["tierId"] = id;

    this.setState({ emails: emails });
    console.log(emails);
  };

  readFile = (data, fileInfo) => {
    console.log(data);

    try {
      let emails = [];
      let temp_emails = [];
      let temp_tiers = [];
      let tiers = -1;

      data.forEach((item) => {
        if (this.state.template === "1") {
          if (item.email === null || item.email === "") {
            tiers++;
            emails[tiers] = [];
            emails[tiers]["tier"] = item.firstname;

            if (this.state.simpleTiers.includes(item.firstname)) {
              emails[tiers]["found"] = "true";

              emails[tiers]["tierId"] = this.state.simpleTiers.indexOf(
                item.firstname
              );
            } else {
              emails[tiers]["found"] = "false";
              emails[tiers]["tierId"] = 0;
            }
          } else {
            this.emailsToSend++;
            emails[tiers].push([item.email, item.status]);
          }
        } else if (this.state.template === "2") {
          this.emailsToSend++;
          if (!temp_emails[item.tier]) temp_emails[item.tier] = [];
          temp_emails[item.tier].push([item.email, item.note, item.tier]);

          if (!temp_tiers.includes(item.tier)) {
            temp_tiers.push(item.tier);
          }
        }
      });

      console.log(temp_emails);

      if (temp_emails) {
        temp_tiers.forEach((tier, index) => {
          console.log("im working");
          emails[index] = [];
          temp_emails[tier].forEach((item) => {
            emails[index]["tier"] = item[2];

            console.log(item[2]);

            if (this.state.simpleTiers.includes(item[2])) {
              emails[index]["found"] = "true";
              emails[index]["tierId"] = this.state.simpleTiers.indexOf(item[2]);
            } else {
              emails[index]["found"] = "false";
              emails[index]["tierId"] = 0;
            }

            emails[index].push([item[0], item[1]]);
          });
        });
      }

      console.log(emails);

      if (!emails.length) {
        this.setState({
          msg: "There doesn't seem to be any emails found in this file...",
        });
      }
      this.setState({
        emails,
        isLoading: false,
        showResult: true,
      });
    } catch (err) {
      console.log(err);
      NotificationManager.warning(
        "Looks like something went wrong with reading your CSV file. Please check your file and try again",
        "",
        10000
      );
    }
  };

  componentDidMount() {
    console.log(this.state.ztiers);
    this.setState({ init: false });
  }

  clickCSV = () => {
    document.getElementById("react-csv-reader-input").click();
  };

  render() {
    return (
      <React.Fragment>
        <Modal.Header closeButton>
          <h5>Import E-mail Lists from CSV files</h5>
        </Modal.Header>
        <Modal.Body className="p-3 px-5">
          <Form.Group>
            <Form.Label>
              Select a template if you're importing a CSV from Patreon, etc.
            </Form.Label>
            <Form.Control
              as="select"
              id="tour-12"
              custom
              value={this.state.template}
              onChange={(e) => {
                this.setState({ template: e.target.value });
              }}
            >
              <option value="1">Patreon</option>
              <option value="2">ShieldShot</option>
            </Form.Control>
          </Form.Group>

          <div className="hidden">
            <CSVReader
              id="custom-file-translate-scss"
              cssClass="custom-file-input"
              onFileLoaded={this.readFile}
              parserOptions={parserOptions}
            />
          </div>

          <div
            className="custom custom-file pointer"
            onClick={this.clickCSV}
            id="tour-13"
          >
            <label
              htmlFor="custom-file-translate-scss"
              className="custom-file-label"
            >
              Select a file
            </label>

            <small>
              ShieldShot segregates emailList by the "firstname" in patreon CSV
              files.{" "}
            </small>
          </div>

          {this.state.showResult && (
            <div className="mt-3">
              <hr />
              {this.state.msg}

              {this.state.isLoading ? (
                <div className="w-100 text-center">
                  <Spinner animation="border" variant="primary" />
                </div>
              ) : (
                <React.Fragment>
                  {this.state.emails.map((items, index) => (
                    <div className="mt-2 mb-4" key={index}>
                      <Row className="mb-1">
                        {items["found"] === "true" ? (
                          <React.Fragment>
                            <Col>
                              <b>{items["tier"]}</b>
                              <br />
                              <small className="badge badge-pill badge-success">
                                matching tier found
                              </small>
                            </Col>
                            <Col md={4} className="text-right">
                              <TierDropdown
                                defaultValue={items["tierId"]}
                                token=""
                                action={this.setTier}
                                size="sm"
                                tierData={this.state.tiers}
                                extraData={index}
                              />
                            </Col>
                          </React.Fragment>
                        ) : (
                          <React.Fragment>
                            <Col>
                              <b>{items["tier"]}</b>
                              <br />
                              <small className="badge badge-pill badge-danger">
                                No Matching Tier
                              </small>
                            </Col>
                            <Col md={4} className="text-right">
                              <TierDropdown
                                defaultValue={0}
                                token=""
                                action={this.setTier}
                                size="sm"
                                tierData={this.state.tiers}
                                extraData={index}
                              />
                            </Col>
                          </React.Fragment>
                        )}
                      </Row>

                      <ListGroup>
                        {items.map((item, index) => (
                          <ListGroupItem action key={index}>
                            {item[0]}
                          </ListGroupItem>
                        ))}
                      </ListGroup>
                    </div>
                  ))}
                </React.Fragment>
              )}
            </div>
          )}
        </Modal.Body>

        {this.state.showResult && !this.state.isLoading && (
          <Modal.Footer>
            <Button variant="outline-primary" onClick={this.sendEmails}>
              Import Emails
            </Button>
          </Modal.Footer>
        )}
      </React.Fragment>
    );
  }
}

export default EmailListImport;
