import React, { Component } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import SideBar from "./dashboard/sideBar";
import { NotificationContainer } from "react-notifications";

class dashboard extends Component {
  render() {
    return (
      <React.Fragment>
        <NotificationContainer />
        <div
          className="mt-3 container"
          id="mainView"
          style={{ paddingTop: "60px", flex: "1" }}
        >
          <Row className="fill">
            <Col xs={0} sm={1} md={0} lg={2}>
              <SideBar
                currentStorage={this.props.currentStorage}
                maxStorage={this.props.maxStorage}
              />
            </Col>
            <Col md={12} lg={10} className="fill" id="contentArea">
              {this.props.children}
            </Col>
          </Row>
        </div>
      </React.Fragment>
    );
  }
}

export default dashboard;
