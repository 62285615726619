import React, { Component } from "react";
import ListGroup from "react-bootstrap/ListGroup";
import history from "../../utils/history";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Moment from "moment";
import MomentLocalizer from "react-widgets-moment";
import ProgressBar from "react-bootstrap/ProgressBar";

Moment.locale("en");
MomentLocalizer();

class distributionItem extends Component {
  render() {
    const redirect = "/distribution/" + this.props.item[0];

    return (
      <ListGroup.Item action onClick={() => history.push(redirect)}>
        <Row>
          <Col className="text-left">{this.props.item[1][0].subject.S}</Col>
          <Col className="text-right text-secondary">
            {Moment.unix(this.props.item[1][0].createdAt.S).format("LL") ===
            "Invalid date"
              ? this.props.item[1][0].createdAt.S.substring(
                  0,
                  this.props.item[1][0].createdAt.S.indexOf("T")
                )
              : Moment.unix(this.props.item[1][0].createdAt.S).format("LL")}
          </Col>
        </Row>
        <Row>
          <Col className="text-left">
            {this.props.item[2].done ? (
              <small className=" font-weight-bold badge-pill badge badge-success">
                Distribution Complete
              </small>
            ) : (
              <React.Fragment>
                {this.props.item[2].scheduled ? (
                  <span className="font-weight-bold badge-pill badge badge-warning">
                    Scheduled on {this.props.item[2].schedule}
                  </span>
                ) : (
                  <ProgressBar now={this.props.item[2].percent} />
                )}
              </React.Fragment>
            )}
          </Col>
        </Row>
      </ListGroup.Item>
    );
  }
}

export default distributionItem;
