import React, { Component } from "react";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import { Prompt } from "react-router";
import { withRouter } from "react-router-dom";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
import Card from "react-bootstrap/Card";
import { CirclePicker } from "react-color";
import TierDropdown from "../parts/tierDropdown";

import { NotificationManager } from "react-notifications";
import Tour from "reactour";
import EndTour from "../parts/endTour";

class TierAdd extends Component {
  _isMounted = false;

  state = {
    tourTime: localStorage.getItem("tour") === "true" ? true : false,
    hideTour: false,
    tiers: [{ id: 0, tier: "Loading..." }],
    modified: false,
    validated: false,
    name: "",
    selectedColor: "",
    displayColor: "#000000",
    displayColor2: "#FFFFFF",
    notes: "no note",
    FormTier: "0",
    warning: false,
    warningMessage: "You have incomplete stuff on your form",
    loading: false,
    AWS: null,
    rel_id: 0,
    colors: [
      "#f44336",
      "#e91e63",
      "#ff91c6",
      "#cc99ff",
      "#9c27b0",
      "#673ab7",
      "#3f51b5",
      "#2196f3",
      "#03a9f4",
      "#00bcd4",
      "#5fe8de",
      "#009688",
      "#4caf50",
      "#8bc34a",
      "#cddc39",
      "#ffeb3b",
      "#ffc107",
      "#ff9800",
      "#ff5722",
      "#795548",
      "#607d8b",
    ],
    typeStep: 0,
  };

  componentDidMount(props) {
    this._isMounted = true;
  }

  getTierListEndpoint = async () => {
    console.log("getting tiers...");
    try {
      const token = await this.props.token();
      const body = JSON.stringify({});
      const response = await fetch(
        "https://api.shieldshot.com/tiers/list",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          body: body,
          method: "POST",
        }
      );

      const responseData = await response;
      if (responseData.status === 200) {
        const responseJson = await responseData.json();
        let list = [];

        responseJson.forEach((item) => {
          //
          if (item.rel_id.N !== "0") {
            let list_item = {
              id: item.id.S,
              title: item.rel_id.N,
              rel_id: item.rel_id.N,
              nickname: item.tierName.S,
              color: item.color.S,
              parentTier: item.parentTier.S,
            };

            list.push(list_item);
          }
        });

        if (this._isMounted) {
          this.getInheritanceInit(list);

          this.goToTier(responseJson);
        }
      } else {
        console.log("there's a problem with this endpoint");
      }
    } catch (err) {
      console.log("Error getting tier list from endpoint", err);
    }
  };

  inheritance = [];
  originalId = null;
  tiers = [];

  getInheritanceInit = (tiers) => {
    this.tiers = tiers;
    tiers.forEach((item) => {
      //console.log(item);
      item.inheritance = [];
      if (item.parentTier !== "0") {
        this.getInheritance(item.parentTier, [], item.rel_id);

        item.inheritance = this.inheritance;
        this.inheritance = [];
      }
    });

    localStorage.setItem("tiers", JSON.stringify(tiers));
    //this.setState({ tiers: tiers, isLoading: false });
    this.tiers = [];
  };

  getInheritance = (id, inheritance, originalId = null) => {
    if (originalId !== null) {
      this.originalId = id;

      //find the parent
      this.tiers.forEach((item) => {
        if (item.rel_id === id) {
          this.inheritance.push(item.nickname);
          this.getInheritance(item.parentTier, inheritance);
        } else {
        }
      });
    } else {
      if (id === 0 || this.originalId === id) {
        this.originalId = null;

        return;
      } else {
        //find the parent
        this.tiers.forEach((item) => {
          if (item.rel_id === id) {
            this.inheritance.push(item.nickname);

            this.getInheritance(item.parentTier, inheritance);
          }
        });
      }
    }
  };

  createTierEndpoint = async () => {
    try {
      const token = await this.props.token();

      const body = JSON.stringify({
        tierName: this.state.name,
        color: this.state.selectedColor,
        parentTierId: this.state.FormTier,
        notes: this.state.notes,
        type: "tier",
      });

      const response = await fetch(
        "https://api.shieldshot.com/tiers/add",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          body: body,
          method: "POST",
        }
      );

      const responseData = await response;

      if (responseData.status === 200) {
        this.getTierListEndpoint();

        localStorage.setItem("tourStep", "1");
      } else {
        this.setState({ warning: true, message: responseData.status });
        setTimeout(this.revertWarning, 5000);
        this.setState({ loading: false });

        console.log("failure on endpoint", responseData.status);
      }
    } catch (err) {
      this.setState({ warning: true, message: "Error: " + err });
      setTimeout(this.revertWarning, 5000);
      this.setState({ loading: false });

      console.log("Error on deletion", err);
    }
  };

  submitForm = (ev) => {
    if (ev) {
      ev.preventDefault();
      ev.stopPropagation();
    }
    if (this.state.loading) return;
    this.setState({ loading: true });
    if (
      this.state.name === "" ||
      this.state.FormTier === "" ||
      this.state.selectedColor === ""
    ) {
      //alert(this.state.FormTier);

      if (this.state.name === "")
        NotificationManager.warning(
          "Name is required",
          "You have incomplete stuff",
          10000
        );

      this.setState({ loading: false });
      if (this.state.selectedColor === "")
        NotificationManager.warning(
          "You need to select a color for your tier",
          "You have incomplete stuff",
          10000
        );
    } else this.createTierEndpoint();
  };

  goToTier = (rel_id) => {
    this.setState({ modified: false });
    this.props.history.push("/tiers");
  };

  revertWarning = () => {
    this.setState({ warning: false });
  };

  modified = () => {
    this.setState({ modified: true });
  };

  handleNameChange = (e) => {
    this.setState({ name: e.target.value });
    this.modified();
  };

  handleTierChange = (color, id, name, tierId) => {
    this.setState({ FormTier: id });
    this.modified();
  };

  handleTierChange = (color, id, name) => {
    this.setState({ FormTier: id });
    this.modified();
  };

  handleNoteChange = (e) => {
    this.setState({ notes: e.target.value });
    this.modified();
  };

  handleChangeComplete = (color) => {
    this.setState({
      selectedColor: color.hex,
      displayColor: color.hex,
      displayColor2: color.hex,
      modified: true,
    });
  };

  componentWillUnmount() {
    this._isMounted = false;
  }

  i = 0;

  typeWriterName(txt) {
    console.log("typing...");
    let name = this.state.name;
    if (this.i < txt.length) {
      name = name + txt.charAt(this.i);
      this.i++;
      this.setState({ name: name });
      setTimeout(() => this.typeWriterName(txt), 20);
    }
  }

  typeWriterNotes(txt) {
    console.log("typing...");
    let name = this.state.notes;
    if (this.i < txt.length) {
      name = name + txt.charAt(this.i);
      this.i++;
      this.setState({ notes: name });
      setTimeout(() => this.typeWriterNotes(txt), 10);
    }
  }

  render() {
    const steps = [
      {
        selector: "#tour-01",
        content: "This is the Add Tier Page",
      },
      {
        selector: "#tour-02",
        content: "Type in your tier's name here",
        action: () => {
          if (this.state.typeStep === 0) {
            this.typeWriterName("Tier 1 - Thank you for your support!");
            this.setState({ typeStep: 1 });
          }
        },
      },
      {
        selector: "#tour-03",
        content: "Select a color for tier.",
        action: () => {
          if (this.state.selectedColor === "") {
            setTimeout(() => this.setState({ selectedColor: "#ff91c6" }), 200);
          }
        },
        style: {
          backgroundColor: "#ff91c6",
        },
      },
      {
        selector: "#tour-04",
        content: "Add notes here",
        action: () => {
          if (this.state.typeStep === 1) {
            this.i = 0;
            this.typeWriterNotes(
              ".Usually 20 selfies? This is my $1 tier. I've got to note this down"
            );
            this.setState({ typeStep: 2, notes: "" });
          }
        },
      },
      {
        selector: "#submitButton",
        content: "Save by clicking submit.",
      },
      {
        content: "Wait for it to be saved",
        action: () => {
          if (!this.state.loading) {
            this.submitForm();
          }
        },
      },
    ];

    return (
      <React.Fragment>
        <EndTour end={() => this.setState({ tourTime: false })} />
        <Tour
          steps={steps}
          rounded={25}
          isOpen={this.state.tourTime && !this.state.hideTour}
          closeWithMask={false}
          onRequestClose={() => {
            this.setState({ hideTour: true });
          }}
          lastStepNextButton={
            <span className="btn btn-outline-primary">Okay</span>
          }
        />

        <Prompt
          when={this.state.modified}
          message={(location) => `You have unsaved changes. Continue?`}
        />
        <div>
          <Card className="text-left border-0" id="tour-01">
            <Card.Header
              className="border-0"
              style={{
                backgroundColor: "#f7f9fa",
              }}
            >
              <h3>Add Tier</h3>
            </Card.Header>

            <Form onSubmit={this.submitForm}>
              <Card.Body className="border-0">
                <Form.Row>
                  <Form.Group
                    as={Col}
                    xs={8}
                    xm={8}
                    controlId="tierName"
                    id="tour-02"
                  >
                    <Form.Label className="font-weight-bold">
                      Tier Name
                    </Form.Label>

                    <Form.Control
                      type="text"
                      onChange={this.handleNameChange}
                      value={this.state.name}
                      required
                    />
                    <Form.Control.Feedback type="invalid">
                      Pleace provide a name
                    </Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group as={Col} controlId="TierParent" className="">
                    <Form.Label className="font-weight-bold">
                      includes
                    </Form.Label>

                    <TierDropdown
                      action={this.handleTierChange}
                      token={this.props.token}
                      defaultValue={0}
                    />
                  </Form.Group>
                </Form.Row>
                <Form.Row id="tour-03">
                  <Form.Group as={Col} controlId="color">
                    <Form.Label
                      className="font-weight-bold"
                      style={{
                        color: this.state.displayColor,
                      }}
                    >
                      Select a Color
                      <span
                        className="ml-2 pl-1 pr-1 rounded-circle"
                        style={{
                          color: this.state.displayColor2,

                          width: "25px",
                        }}
                      >
                        ◆
                      </span>
                    </Form.Label>

                    <CirclePicker
                      color={this.state.selectedColor}
                      circleSpacing={13.5}
                      colors={this.state.colors}
                      width="100%"
                      onChangeComplete={this.handleChangeComplete}
                    />
                  </Form.Group>
                </Form.Row>
                <Form.Row id="tour-04">
                  <Form.Group as={Col} controlId="notes">
                    <Form.Label className="font-weight-bold">Notes</Form.Label>
                    <Form.Control
                      type="textarea"
                      placeholder="Add a Note here... Not required"
                      onChange={this.handleNoteChange}
                      value={this.state.notes}
                    />
                  </Form.Group>
                </Form.Row>
                <hr />
                <Form.Row>
                  <Form.Group
                    as={Col}
                    controlId="formGridSubmit"
                    className="mb-0"
                  >
                    {!this.state.loading ? (
                      <Button id="submitButton" type="submit">
                        Submit
                      </Button>
                    ) : (
                      <Button id="loadingButton" variant="primary">
                        <Spinner
                          as="span"
                          animation="grow"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                          className="mr-2"
                        />
                        Submitting...
                      </Button>
                    )}
                  </Form.Group>
                </Form.Row>
              </Card.Body>
            </Form>
          </Card>
        </div>
      </React.Fragment>
    );
  }
}

export default withRouter(TierAdd);
