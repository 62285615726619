import React, { Component } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import History from "../../utils/history";
import { Router } from "react-router-dom";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Spinner from "react-bootstrap/Spinner";
import { NotificationManager } from "react-notifications";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { faMailBulk } from "@fortawesome/free-solid-svg-icons";

class Feedback extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = { subject: "", feedback: "", thanks: "", submitting: false };
  }

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  handleFeedback = (e) => {
    if (this._isMounted) this.setState({ feedback: e.target.value });
  };

  handleSubject = (e) => {
    if (this._isMounted) this.setState({ subject: e.target.value });
  };

  submitForm = async (e) => {
    e.preventDefault();
    const token = await this.props.token();

    if (this.state.subject === "" || this.state.feedback === "") {
      alert("Incomplete details!");
    } else {
      this.setState({ submitting: true });

      try {
        const body = JSON.stringify({
          subject: this.state.subject,
          feedback: this.state.feedback,
        });

        const response = await fetch(
          "https://api.shieldshot.com/users/feedback",
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
            body: body,
            method: "POST",
          }
        );

        const responseData = await response;

        if (responseData.status === 200) {
          this.setState({ thanks: "Thanks for your feedback!" });
        } else {
          //alert("feedback send error " + responseData.status);
          NotificationManager.warning(
            "Error " + responseData.status,
            "Something went wrong",
            10000
          );
          this.setState({ submitting: false });
        }
      } catch (err) {
        NotificationManager.warning(
          "Syntax Error.",
          "Something went wrong",
          10000
        );
      }
    }
  };

  render() {
    return (
      <div className="mt-5 pt-5 mx-auto w-100 px-3">
        <div className="  col-md-6 col-sm-12 mx-auto text-left ">
          <React.Fragment>
            <Row className="mt-5 bigRound shadow-lg">
              <Col className="bg-primary text-white text-center" md={3}>
                <div className="container d-flex h-100  align-items-center">
                  <div className="container justify-content-center align-self-center">
                    <FontAwesomeIcon
                      icon={faMailBulk}
                      style={{ fontSize: "60px" }}
                    />
                  </div>
                </div>
              </Col>
              <Col className="p-3 bg-white">
                <h2 className="mt-4 futura">Send Feedback</h2>
                {this.state.thanks ? (
                  <div>
                    <h6 className="mb-5">Thanks for your feedback!</h6>
                    <Router history={History}>
                      <Button
                        className="pill-button mx-auto px-3"
                        onClick={History.goBack}
                      >
                        Go Back
                      </Button>
                    </Router>
                  </div>
                ) : (
                  <Form onSubmit={this.submitForm}>
                    <div className="bg-white p-3  rounded">
                      <Form.Control
                        type="text"
                        placeholder="Subject"
                        onChange={this.handleSubject}
                        className="mb-3 materialInput"
                        required
                      />
                      <textarea
                        type="textarea"
                        rows={5}
                        placeholder="Tell us what you think"
                        onChange={this.handleFeedback}
                        required
                        style={{ border: "1px solid rgb(221, 221, 221)" }}
                        className="w-100 rounded p-2 materialInput"
                      />
                    </div>
                    <div className="text-center">
                      <Button
                        type="submit"
                        disabled={this.state.submitting}
                        className="pill-button mx-auto px-3"
                      >
                        {this.state.submitting ? (
                          <Spinner
                            animation="border"
                            variant="white"
                            size="sm"
                          />
                        ) : (
                          "Submit"
                        )}
                      </Button>
                    </div>
                  </Form>
                )}
              </Col>
            </Row>
          </React.Fragment>
        </div>
      </div>
    );
  }
}

export default Feedback;
