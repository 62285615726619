import React, { Component } from "react";
import Loader from "react-loader-spinner";

const bgColors = {
  default: "#81b71a",
  blue: "#00B1E1",
  cyan: "#37BC9B",
  green: "#8CC152",
  red: "#E9573F",
  yellow: "#F6BB42",
};

class LoadingScreen extends Component {
  seconds = 0;
  constructor(props) {
    super(props);
    this.state = { bgColor: "#00BFFF", tooLong: "" };
    this.interval = setInterval(() => {
      let tooLong = "";
      let randomColor =
        bgColors[
          Object.keys(bgColors)[
            Math.floor(Math.random() * Object.keys(bgColors).length)
          ]
        ];
      this.seconds++;
      if (this.seconds > 10)
        tooLong = "Loading seems taking longer than expected...";
      if (this.seconds > 20)
        tooLong = "Loading is too long. Consider refreshing.";
      this.setState(() => ({ bgColor: randomColor, tooLong: tooLong }));
    }, 1000);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  render() {
    return (
      <div
        className="text-center py-auto"
        style={{ minHeight: "100vh", display: "flex", alignItems: "center" }}
      >
        <div className="container">
          <Loader
            type="Oval"
            color={this.state.bgColor}
            height={80}
            width={80}
          />
          <br />
          {this.state.tooLong}
        </div>
      </div>
    );
  }
}

export default LoadingScreen;
