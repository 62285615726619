import React, { Component } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
import Form from "react-bootstrap/Form";
import { Prompt } from "react-router";
import { withRouter } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import TierDropdownPill from "../tierDropdownPill";
import Dropzone from "../Dropzone";
import ProgressBar from "react-bootstrap/ProgressBar";
import Collapse from "react-bootstrap/Collapse";
import SomethingWrong from "../../media/somethingwrong.jpg";
import { confirm } from "../confirmation";
import { NotificationManager } from "react-notifications";
import { Img } from "react-image";

import History from "../../utils/history";

import MonthPicker from "react-month-picker";
// import MonthBox from "../parts/monthBox";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlus,
  faDownload,
  faExclamationTriangle,
  faTrash,
  faArrowCircleLeft,
  faArrowCircleRight,
} from "@fortawesome/free-solid-svg-icons";

import arrayMove from "array-move";
import { SortableContainer, SortableElement } from "react-sortable-hoc";

import Moment from "moment";
import MomentLocalizer from "react-widgets-moment";

import Tour from "reactour";
import EndTour from "../parts/endTour";

Moment.locale("en");
MomentLocalizer();

// const SortablePhoto = SortableElement((item) => <Photo {...item} />);
// const SortableGallery = SortableContainer(({ items }) => (
//   <Gallery
//     photos={items}
//     renderImage={(props) => <SortablePhoto {...props} />}
//     targetRowHeight={150}
//   />
// ));

const SortablePhotoV2 = SortableElement((item, index) => {
  return (
    <Col
      lg={2}
      md={3}
      sm={4}
      xs={4}
      className="p-1 m-0 rounded no-overflow col-thumb"
    >
      <Img
        src={item.data.src}
        loader={
          <div
            style={{
              display: "flex",
              justifyItems: "center",
              justifyContent: "center",
            }}
          >
            <Spinner variant="primary" animation="border" />
          </div>
        }
        className="img-thumbnail pointer"
      />
    </Col>
  );
});

const SortableGalleryV2 = SortableContainer(({ items }) => {
  return (
    <Row
      className="text-left mx-4 mx-sm-0 mx-md-0 mx-lg-0 mx-xl-0 p-0 "
      key="sortableRowGallery"
    >
      {items.map((value, index) => (
        <SortablePhotoV2 key={index} data={value} index={index} />
      ))}
    </Row>
  );
});

class PhotoSetDetails extends Component {
  _isMounted = false;
  _fileNos = 0;
  _fileNosPeak = 0;
  intervalID = 0;
  pickAMonth = React.createRef();

  initGetPhotos = false;

  //SortablePhoto = SortableElement((item) => <Photo {...item} />);

  state = {
    tourTime: localStorage.getItem("tour") === "true" ? true : false,
    redirected: false,
    id: this.props.match.params.id,
    image: "",
    file: null,
    message: "",
    isUploading: false,
    user: this.props.user,
    loading: true,
    setid: "",
    copyright: "",
    createdAt: "",
    notes: "",
    preload: true,
    photoCount: 0,
    filesToSend: 0,
    percent: 0,
    collapseProgress: false,
    init: true,
    isLoading: true,
    images: [
      // url, id, redirect
    ],
    loadedImages: [],
    fullImages: [],
    currentFullImage: "",
    AWS: this.props.AWS,
    editNote: false,
    editCopyright: false,
    oldCopyright: "",
    oldNotes: "",
    oldName: "",
    setStatus: "",
    isRequesting: false,
    isStatusSuccess: false,
    allowDownload: false,
    disableCopyright: false,
    disableNotes: false,
    disableName: false,
    editName: false,
    showModal: false,
    editTier: false,
    statusColor: "#DDDDDD",
    sortMode: false,
    initGetPhotos: false,
    sortDesc: "Sort Photos",
    activeMonth: Moment().unix(),
    monthValue: {
      year: parseInt(Moment().format("YYYY")),
      month: parseInt(Moment().format("M")),
    },
    dropRef: null,
    dragging: false,
    disableDragging: false,
    prevItem: null,
    nextItem: null,
    nextIndex: null,
    prevIndex: null,
    versionTime: 1591213166,
    notSubscribed: false,
  };

  deb = 0;
  drag_timeout = null;
  debounce_timeout = null;
  dropped_something = false;

  setDragging(i, force = false) {
    if (force) {
      this.deb = 0;
      clearInterval(this.drag_timeout);
      clearInterval(this.debounce_timeout);
    }
    if (this.deb <= 0)
      this.drag_timeout = setTimeout(() => {
        this.setState({ dragging: i });
      }, 5);

    if (!force) this.deb = 2;

    if (!force) this.debounce();
  }

  debounce() {
    this.debounce_timeout = setTimeout(() => {
      this.deb--;
      if (this.deb > 0) this.debounce();
      else console.log("drag state change allowed again");
    }, 100);
  }

  constructor(props) {
    super(props);
    this.escFunction = this.escFunction.bind(this);
  }

  getToken = async () => {
    const token = await this.props.token();
    this.setState({ token: token });
  };

  openModal = (i, id, index, photoStatus, prev, next) => {
    console.log("prev", prev);
    console.log("current index id", index);
    console.log("next", next);

    if (prev > 0) {
      this.setState({ prevItem: this.state.images[prev], prevIndex: prev });
    } else {
      this.setState({ PrevItem: null, prevIndex: null });
    }

    if (next < this.state.images.length) {
      this.setState({ nextItem: this.state.images[next], nextIndex: next });
    } else {
      this.setState({ nextItem: null, prevIndex: null });
    }

    console.log(this.state.images[prev]);

    console.log("photostatus", photoStatus);
    if (photoStatus === "SUCCESS") {
      if (this._isMounted) {
        this.setState({ currentIndexId: index });
        if (!this.state.fullImages.includes(id)) {
          let origKey;

          if (this.state.createdAt < this.state.versionTime) {
            origKey =
              "photosets/" +
              this.state.user.sub +
              "/" +
              this.state.setid +
              "/" +
              i;
          } else {
            let filename = i.replace(/\.[^/.]+$/, "") + ".jpg";

            origKey =
              "photosets/" +
              this.state.user.sub +
              "/" +
              this.state.setid +
              "/optimized/" +
              filename;
          }

          console.log(id);

          this.getImage(origKey, id, i);
        } else {
          this.setState({
            currentFullImage: this.state.fullImages[id],
            showModal: true,
            currentImageId: id,
            currentFileName: i,
          });
        }
      }
    } else {
      NotificationManager.warning(
        "Please check back later",
        "This photo is still processing",
        10000
      );
    }
  };

  closeModal = () => {
    if (this._isMounted)
      this.setState({ showModal: false, currentImageId: "" });
  };

  getImage = (key, id, fileName) => {
    console.log("getting image...");
    this.setState({ currentFullImage: "" });
    if (this._isMounted) {
      let s3 = new this.state.AWS.S3();

      const s3params = {
        Bucket: "shieldshot-uploads",
        Key: key,
        Expires: 1000 * 60 * 3,
      };

      s3.getSignedUrlPromise("getObject", s3params, (err, url) => {
        if (err) console.log("Something's wrong with this image");
        else {
          let fullImages = this.state.fullImages;
          fullImages[id] = url;

          this.setState({
            fullImages: fullImages,
            showModal: true,
            currentFullImage: url,
            currentImageId: id,
            currentFileName: fileName,
          });
        }
      });
    }
  };

  escFunction(event) {
    if (event.keyCode === 27) {
      //Do whatever when esc is pressed
      if (this.state.editName)
        this.setState({ photosetName: this.state.oldName, editName: false });
      if (this.state.editNote)
        this.setState({ notes: this.state.oldNotes, editNote: false });
      if (this.state.editCopyright)
        this.setState({
          copyright: this.state.oldCopyright,
          editCopyright: false,
        });

      console.log("escaped!");
    }
  }

  incrementFileNos = async () => {
    const percent = (this._fileNos / this._fileNosPeak) * 100;

    this.setState({
      percent: percent,
    });
  };

  decrementFileNos = async (ev = null) => {
    const percent = (this._fileNos / this._fileNosPeak) * 100;

    this.setState({ percent: percent });
    if (this.state.percent >= 100 || this.state.notSubscribed)
      setTimeout(() => {
        this.setState({
          percent: 0,
          collapseProgress: false,
          isUploading: false,
          isActiveSuccess: false,
          disableDragging: false,
        });
        this.dropped_something = false;
        if (ev !== null) ev.target.value = null;
        console.log(this.state.disableDragging);

        if (this.state.notSubscribed === false)
          NotificationManager.success(
            "Your photos will arrive shortly after we process it.",
            "Upload done!",
            10000
          );
      }, 3000);
  };

  collapseDelay() {
    this.setState({ percent: 0, collapseProgress: false });
  }

  uploadFile = (ev, i) => {
    console.log("upload #" + i + " starting");
    const s3 = new this.state.AWS.S3();

    let file = ev.target.files[i];
    let name = ev.target.files[i].name;

    i++;

    this.incrementFileNos();

    let s3params = {
      Body: file,
      Bucket: "shieldshot-temp",
      Key: name,
      ServerSideEncryption: "AES256",
    };

    let putObjectPromise = s3.putObject(s3params).promise();

    putObjectPromise
      .then((data) => {
        this._fileNos++;
        this.decrementFileNos(ev);
        this.addPhotoEndpoint(name);
        console.log(
          "upload #" + i + " finished. Calling method to upload next photo."
        );
        if (!this.state.notSubscribed) {
          if (i < this.state.filesToSend)
            setTimeout(this.uploadFile, 200, ev, i);
          this.setDistributionStatus("Processing");
        }
      })
      .catch((err) => {
        console.log(err, err.stack);
        this._fileNos++;
        this.decrementFileNos(ev);
        if (!this.state.notSubscribed)
          if (i < this.state.filesToSend)
            setTimeout(this.uploadFile, 200, ev, i);
      });
  };

  onChange = async (ev) => {
    ev.persist();

    // upload starts here
    // start uploading
    if (this._isMounted)
      this.setState({ allowDownload: false, isStatusSuccess: false });

    let fileLength = ev.target.files.length;
    this._fileNosPeak = fileLength;
    this._fileNos = 0;

    this.setState({
      filesToSend: fileLength,
      isUploading: true,
      collapseProgress: true,
    });

    this.uploadFile(ev, 0);
  };

  onDropUpload = (files, i) => {
    console.log("upload #" + i + " starting");
    const s3 = new this.state.AWS.S3();

    console.log(files);

    let file = files[i];
    let name = files[i].name;

    i++;

    this.incrementFileNos();

    let s3params = {
      Body: file,
      Bucket: "shieldshot-temp",
      Key: name,
      ServerSideEncryption: "AES256",
    };

    let putObjectPromise = s3.putObject(s3params).promise();

    putObjectPromise
      .then((data) => {
        this._fileNos++;
        this.decrementFileNos();
        this.addPhotoEndpoint(name);
        console.log(
          "upload #" + i + " finished. Calling method to upload next photo."
        );
        if (i < this.state.filesToSend)
          if (!this.state.notSubscribed) {
            setTimeout(this.onDropUpload, 200, files, i);
            this.setDistributionStatus("Processing");
          }
      })
      .catch((err) => {
        console.log(err, err.stack);
        this._fileNos++;
        this.decrementFileNos();

        if (i < this.state.filesToSend)
          setTimeout(this.onDropUpload, 200, files, i);
      });
  };

  onDrop = async (files) => {
    this.dropped_something = true;
    let fileLength = files.length;

    this._fileNosPeak = fileLength;
    this._fileNos = 0;

    console.log(files);
    this.setState({
      filesToSend: fileLength,
      isUploading: true,
      collapseProgress: true,
      allowDownload: false,
      isStatusSuccess: false,
      dragging: false,
      disableDragging: true,
    });

    this.onDropUpload(files, 0);
  };

  addPhotoEndpoint = async (name) => {
    try {
      const token = await this.props.token();

      const body = JSON.stringify({
        setId: this.state.setid,
        fileName: name,
        copyright: this.state.copyright,
        // type: "photo",
      });

      const response = await fetch(
        "https://api.shieldshot.com/photosets/add/photos",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          body: body,
          method: "POST",
        }
      );

      const responseData = await response;
      //const responseJson = await responseData.json();
      //console.log(responseJson);

      if (responseData.status === 200) {
        // this.setState({ filesToSend: this.state.filesToSend - 1 });
        // if (this.state.filesToSend === 0) this.setState({ isUploading: false });
      } else if (responseData.status === 400) {
        NotificationManager.warning(
          "The file type of " + name + " is not supported",
          name,
          20000
        );
      } else if (responseData.status === 401) {
        NotificationManager.warning(
          "Storage Limit reached! Consider upgrading your subscription to add more storage.",
          name,
          20000
        );
      } else if (responseData.status === 402) {
        NotificationManager.warning(
          "Photo Limit reached! Consider upgrading your subscription to add more storage.",
          name,
          20000
        );
      } else if (responseData.status === 403) {
        NotificationManager.warning(
          "You are not subscribed to any plan. You must have a subscription in order to upload photos.",
          name,
          20000
        );
        this.setState({ notSubscribed: true });
      } else {
        console.log("failure on endpoint", responseData.status);
        NotificationManager.error(
          "Upload Failure of file " + name + " Response " + responseData.status,
          name,
          10000
        );
      }
    } catch (err) {
      //console.log("error on add endpoint", err);
      NotificationManager.error(
        "Syntax Error",
        "Photoset Upload Failure",
        10000
      );
    }
  };

  clickUpload() {
    document.getElementById("uploadButton").click();
  }

  componentDidMount() {
    this._isMounted = true;
    window.scrollTo(0, 0);

    //setTimeout(this.props.setListeners, 3000);

    if (localStorage.getItem("photoset_" + this.state.id)) {
      const local = JSON.parse(
        localStorage.getItem("photoset_" + this.state.id)
      );

      if (local.monthValue)
        this.setState({
          setid: local.setid,
          photosetName: local.photosetName,
          tier: local.tier,
          copyright: local.copyright,
          notes: local.notes,
          createdAt: local.createdAt,
          setStatus: local.setStatus,
          isActiveSuccess: local.isActiveSuccess,
          activeMonth: local.activeMonth,
          monthValue: local.monthValue,
          isLoading: false,
          preload: false,
        });

      this.getPhotosEndpoint();
      this.initGetPhotos = true;
    }

    this.getPhotosetDetailsEndpoint();
    document.addEventListener("keydown", this.escFunction, false);

    document.addEventListener("dragenter", this.handleDragIn);
    document.addEventListener("dragleave", this.handleDragOut);
    document.addEventListener("dragover", this.handleDrag);
    document.addEventListener("drop", this.handleDrop);
  }

  handleDrag = (e) => {};
  handleDragIn = (e) => {
    if (!this.state.dragging) {
      this.setDragging(true);
      console.log("dragging in!");
    }
  };
  handleDragOut = (e) => {
    if (this.state.dragging) {
      this.setDragging(false);
      console.log("dragging out!");
    }
  };
  handleDrop = (e) => {
    console.log("dropped something", this.dropped_something);
    if (!this.dropped_something) {
      // clearTimeout(this.drag_timeout);
      // clearTimeout(this.debounce_timeout);
      // this.state.dragging = false;
      this.setDragging(false, true);
      console.log("disable dragging on drop", this.state.dragging);
      this.deb = 0;
    }
  };

  onSortEnd = ({ oldIndex, newIndex }) => {
    this.setState({ images: arrayMove(this.state.images, oldIndex, newIndex) });
  };

  requestDownload = async () => {
    this.setState({ isRequesting: true });
    try {
      const token = await this.props.token();
      const body = JSON.stringify({
        setId: this.state.setid,
      });

      const response = await fetch(
        "https://api.shieldshot.com/photosets/download",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          body: body,
          method: "POST",
        }
      );

      const responseData = await response;

      if (responseData.status === 200) {
        NotificationManager.success(
          "ShieldShot will be zipping your set for download. We'll notify you when it's ready to download.",
          "Download Request Successful",
          10000
        );
      } else {
        NotificationManager.warning(
          "Error " + responseData.status,
          "Uh oh, something went wrong",
          10000
        );
      }
      this.setState({ isRequesting: false });
    } catch (err) {
      NotificationManager.error(
        "Error: " + err,
        "Uh oh, something went wrong",
        10000
      );
      this.setState({ isRequesting: false });
    }
  };

  getPhotosetDetailsEndpoint = async () => {
    try {
      const token = await this.props.token();
      const body = JSON.stringify({
        rel_id: this.state.id,
        type: "photosets",
      });

      const response = await fetch(
        "https://api.shieldshot.com/photosets/details",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          body: body,
          method: "POST",
        }
      );

      const responseData = await response;

      if (responseData.status === 200) {
        const data = await responseData.json();

        if (!data.length) History.push("/404");

        console.log("PHOTOSETS endpoint", data);

        let sst = { S: "Loading" };
        if (data[0]) {
          if (data[0].setStatus) sst = data[0].setStatus.S;
        }

        let activeMonth = 0;
        let monthValue;

        if (data[0].activeMonth) {
          monthValue = {
            year: parseInt(Moment.unix(data[0].activeMonth.S).format("YYYY")),
            month: parseInt(Moment.unix(data[0].activeMonth.S).format("M")),
          };
          activeMonth = data[0].activeMonth.S;
        } else {
          monthValue = this.state.monthValue;
          activeMonth = this.state.activeMonth;
        }

        if (this._isMounted)
          this.setState({
            setid: data[0].id.S,
            photosetName: data[0].photosetName.S,
            tier: data[0].tier.S,
            copyright: data[0].copyright.S,
            notes: data[0].notes.S,
            createdAt: data[0].createdAt.S,
            setStatus: sst.S,
            activeMonth: activeMonth,
            isLoading: false,
            preload: false,
            monthValue: monthValue,
          });

        if (!this.initGetPhotos) this.getPhotosEndpoint();
        this.intervalID = setInterval(this.getPhotosEndpoint, 10000);

        if (sst.S === "Ready for Distribution" || sst.S === "Empty")
          this.setState({ isActiveSuccess: true });
        else if (sst.S === "Processing")
          this.setState({ isActiveSuccess: false });

        this.updateLocalStorage();
      } else {
        if (responseData.status === 404) History.push("/404");
        console.log("there's a problem with the endpoint", responseData);
      }
    } catch (err) {
      console.log("Error getting photoset list from endpoint", err);
    }
  };

  updateLocalStorage = () => {
    let isActiveSuccess;

    const setStatus = this.state.setStatus;

    if (setStatus === "Ready for Distribution" || setStatus === "Empty")
      isActiveSuccess = true;
    else if (setStatus === "Processing") isActiveSuccess = false;

    const itemLocalStorage = {
      setid: this.state.setid,
      photosetName: this.state.photosetName,
      tier: this.state.tier,
      copyright: this.state.copyright,
      notes: this.state.notes,
      createdAt: this.state.createdAt,
      setStatus: this.state.setStatus,
      activeMonth: this.state.activeMonth,
      monthValue: this.state.monthValue,
      isActiveSuccess: isActiveSuccess,
    };

    localStorage.setItem(
      "photoset_" + this.state.id,
      JSON.stringify(itemLocalStorage)
    );
  };

  setDistributionStatus = async (status) => {
    if (status !== this.state.setStatus) {
      try {
        const token = await this.props.token();

        const body = JSON.stringify({
          setid: this.state.setid,
          type: "photosetStatus",
          data: status,
        });

        const response = await fetch(
          "https://api.shieldshot.com/photosets/update",
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
            body: body,
            method: "POST",
          }
        );

        if (response.status === 200) {
          console.log("distribution entry success");
        } else {
          console.log("distribution entry failure");
        }
      } catch (error) {
        console.warn("ASYNC DISTRIBUTION SUBMIT FAILURE", error);
      }

      let statusColor = "#EEEEEE";

      if (status === "Empty") {
        statusColor = "#ff8370";
        if (this._isMounted) this.setState({ allowDownload: false });
      }
      if (status === "Processing") {
        statusColor = "#f7a92a";

        if (this._isMounted)
          this.setState({ allowDownload: false, isActiveSuccess: false });
      }
      if (status === "Ready for Distribution") {
        statusColor = "#5ccf4a";
        if (this._isMounted)
          this.setState({ allowDownload: true, isActiveSuccess: true });
      }
      if (this._isMounted)
        this.setState({ setStatus: status, statusColor: statusColor });

      this.updateLocalStorage();
    }
  };

  getPhotosEndpoint = async () => {
    this.initGetPhotos = true;
    if (this.props.active) {
      try {
        const token = await this.props.token();
        const body = JSON.stringify({
          setId: this.state.setid,
          //type: "photos",
        });

        //console.log("Getting photos...");

        const response = await fetch(
          "https://api.shieldshot.com/photosets/list/photos",
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
            body: body,
            method: "POST",
          }
        );

        const responseData = await response;

        if (responseData.status === 200) {
          const data = await responseData.json();
          const s3 = new this.state.AWS.S3();
          if (this._isMounted)
            this.setState({
              photoCount: data.length,
            });
          let photoProcessing = 0;

          data.forEach(async (item, index) => {
            if (item.photoStatus.S !== "SUCCESS") photoProcessing++;
            else {
              if (this.state.images[index]) {
                if (item.photoStatus.S !== this.state.images[index].status) {
                  // modify that photostatus
                  let images = this.state.images;
                  images[index].status = item.photoStatus.S;
                  if (this._isMounted) this.setState({ images: images });
                }
              }
            }

            if (item.thumbsStatus.S === "SUCCESS") {
              if (!this.state.loadedImages.includes(item.id.S)) {
                let filename =
                  item.fileName.S.replace(/\.[^/.]+$/, "") + ".jpg";

                let key =
                  "photosets/" +
                  this.state.user.sub +
                  "/" +
                  this.state.setid +
                  "/thumbs/" +
                  filename;

                let loadedImages = this.state.loadedImages;

                let s3params = {
                  Bucket: "shieldshot-uploads",
                  Key: key,
                  Expires: 1000 * 60,
                };

                let newImage = [];

                s3.getSignedUrlPromise("getObject", s3params, (err, url) => {
                  if (err) console.log("fuck" + err);
                  else {
                    newImage = {
                      src: url,
                      key: item.id.S,
                      width: 0.5,
                      height: 0.5,
                      status: item.photoStatus.S,
                      f: item.fileName.S,
                      alt: "thumbs",
                    };

                    const newImages = this.state.images.concat(newImage);

                    loadedImages.push(item.id.S);
                    let cnt = this.state.photoCount;
                    cnt++;

                    if (this._isMounted) {
                      this.setState({
                        //photoCount: this.state.photoCount + 1,
                        images: newImages,
                        loadedImages: loadedImages,
                        photoCount: cnt,
                        initGetPhotos: true,
                        init: false,
                      });
                    }
                  }
                });
              }
            } else {
              console.log("skipping photo...");
            }
          });
          if (photoProcessing === 0) {
            if (data.length) {
              this.setDistributionStatus("Ready for Distribution");
              if (this._isMounted) this.setState({ allowDownload: true });
            } else {
              this.setDistributionStatus("Empty");

              if (this._isMounted)
                this.setState({ allowDownload: false, init: false });
            }
            if (this._isMounted) this.setState({ isStatusSuccess: true });
          } else this.setDistributionStatus("Processing");
        } else {
          console.log("something went wrong with getting list stuff");
        }
      } catch (err) {
        console.log("something went wrong with getting list", err);
      }
    }
  };

  deletePhoto = async () => {
    if (await confirm("Are you sure you want to delete this photo?")) {
      const token = await this.props.token();
      console.log(this.state.currentImageId);
      const body = JSON.stringify({
        photoId: this.state.currentImageId,
      });

      try {
        const response = await fetch(
          "https://api.shieldshot.com/photosets/delete/photos",
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
            body: body,
            method: "POST",
          }
        );

        const responseData = await response;
        if (responseData.status === 200) {
          let images = this.state.images;
          images.splice(this.state.currentIndexId, 1);
          if (this._isMounted) this.setState({ images: images });
          this.closeModal();
        } else {
          Notification.warning(
            responseData.response,
            "Something went wrong with deleting your photoset",
            10000
          );
          console.log("failed to delete photo");
        }
      } catch (err) {
        console.log("Deletion failed", err);
      }
    }
  };

  componentWillUnmount() {
    this._isMounted = false;
    document.removeEventListener("keydown", this.escFunction, false);

    document.removeEventListener("dragenter", this.handleDragIn);
    document.removeEventListener("dragleave", this.handleDragOut);
    document.removeEventListener("dragover", this.handleDrag);
    document.removeEventListener("drop", this.handleDrop);
    clearInterval(this.intervalID);
  }

  startEditNote = (ev) => {
    const notes = this.state.notes;
    if (this._isMounted) this.setState({ editNote: true, oldNotes: notes });
  };

  startEditCopyright = (ev) => {
    const copyright = this.state.copyright;
    if (this._isMounted)
      this.setState({ editCopyright: true, oldCopyright: copyright });
  };

  startEditName = (ev) => {
    const name = this.state.photosetName;
    if (this._isMounted) this.setState({ editName: true, oldName: name });
  };

  handleEditNote = (ev) => {
    if (this._isMounted) this.setState({ notes: ev.target.value });
  };

  handleCopyright = (ev) => {
    if (this._isMounted) this.setState({ copyright: ev.target.value });
  };

  handleEditName = (ev) => {
    if (this._isMounted) this.setState({ photosetName: ev.target.value });
  };

  keyPress(e) {
    if (e.key === "Escape") {
      console.log("escape!");
    }
  }

  addErrorSrc = (e) => {
    e.target.src = SomethingWrong;
    console.log("error works!");
  };

  editNoteSubmit = async (ev) => {
    ev.preventDefault();

    if (this.state.notes === "")
      if (this._isMounted) this.setState({ notes: "no note" });

    this.setState({ disableNotes: true });

    try {
      const token = await this.props.token();

      const body = JSON.stringify({
        setid: this.state.setid,
        type: "notes",
        data: this.state.notes,
      });

      const response = await fetch(
        "https://api.shieldshot.com/photosets/update",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          body: body,
          method: "POST",
        }
      );

      const responseData = await response;

      if (response.status === 200) {
        if (this._isMounted) this.setState({ editNote: false });
        NotificationManager.success("Notes updated.", "", 10000);
        this.updateLocalStorage();
      } else {
        if (this._isMounted) this.setState({ editNote: false });
        NotificationManager.error("Notes failed to update.", "", 10000);
      }

      this.setState({ disableNotes: false });

      console.log("ASYNC RESPONSE COMPLETE", responseData);
    } catch (error) {
      console.warn("ASYNC NOTES SUBMIT FAILURE", error);
    }
  };

  editCopyrightSubmit = async (ev) => {
    ev.preventDefault();

    if (this.state.copyright === "")
      this.setState({ copyright: "no copyright" });

    this.setState({ disableCopyright: true });

    try {
      const token = await this.props.token();

      const body = JSON.stringify({
        setid: this.state.setid,
        type: "copyright",
        data: this.state.copyright,
      });

      const response = await fetch(
        "https://api.shieldshot.com/photosets/update",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          body: body,
          method: "POST",
        }
      );

      const responseData = await response;

      if (response.status === 200) {
        if (this._isMounted) this.setState({ editCopyright: false });
        NotificationManager.success("Copyright updated.", "", 5000);
        this.updateLocalStorage();
      } else {
        NotificationManager.error("Copyright failed to update.", "", 10000);
      }

      this.setState({ disableCopyright: false });

      console.log("ASYNC RESPONSE COMPLETE", responseData);
    } catch (error) {
      console.warn("ASYNC COPYRIGHT SUBMIT FAILURE", error);
    }
  };

  editNameSubmit = async (ev) => {
    ev.preventDefault();

    if (this.state.photosetName === "") {
      //this.setMessage("You cannot have an empty name!", "", true);
      NotificationManager.warning("You cannot have an empty name", "", 10000);
      return;
    }

    this.setState({ disableName: true });

    try {
      const token = await this.props.token();

      const body = JSON.stringify({
        setid: this.state.setid,
        type: "photosetName",
        data: this.state.photosetName,
      });

      const response = await fetch(
        "https://api.shieldshot.com/photosets/update",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          body: body,
          method: "POST",
        }
      );

      const responseData = await response;

      if (response.status === 200) {
        this.setState({ editName: false });
        //this.setMessage("Name updated!", "", true);
        NotificationManager.success("Name Updated", "", 10000);
        this.updateLocalStorage();
      } else {
        //this.setMessage("Name failed to update!", "", true);
        NotificationManager.warning(
          "Error " + response.status,
          "Failed to change name",
          10000
        );
      }

      this.setState({ disableName: false });

      console.log("ASYNC RESPONSE COMPLETE", responseData);
    } catch (error) {
      console.warn("ASYNC Name SUBMIT FAILURE", error);
    }
  };

  handleTierChange = async (color, id, name, tierId) => {
    try {
      const token = await this.props.token();

      const body = JSON.stringify({
        setid: this.state.setid,
        type: "tier",
        data: id,
      });

      const response = await fetch(
        "https://api.shieldshot.com/photosets/update",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          body: body,
          method: "POST",
        }
      );

      const responseData = await response;

      if (response.status === 200) {
        this.setState({ editName: false });
        //this.setMessage("Tier updated!", "", true);
        this.updateLocalStorage();
      } else {
        //this.setMessage("Tier failed to update!", "", true);
        NotificationManager.warning(
          response.status,
          "Tier failed to update",
          10000
        );
      }

      console.log("ASYNC RESPONSE COMPLETE", responseData);
    } catch (error) {
      console.warn("ASYNC Name SUBMIT FAILURE", error);
    }
  };

  deletePhotoset = async () => {
    if (await confirm("Are you sure you want to delete this photoset?")) {
      try {
        const token = await this.props.token();

        const body = JSON.stringify({
          setId: this.state.setid,
        });

        console.log(this.state.setid);

        const response = await fetch(
          "https://api.shieldshot.com/photosets/delete",
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
            body: body,
            method: "POST",
          }
        );

        if (response.status === 200) {
          this.props.history.push("/photosets");
        } else {
          NotificationManager.warning(
            "Error " + response.status,
            "Deletion Failed",
            10000
          );
        }
      } catch (err) {
        NotificationManager.warning(
          "Something went wrong.",
          "Deletion Failed",
          10000
        );
      }
    }
  };

  toggleSortMode = () => {
    let sortDesc = "";
    if (this.state.sortMode) {
      sortDesc = "Sort Photos";
      this.sendReorderedList();
    } else sortDesc = "Save Changes";

    this.setState({ sortMode: !this.state.sortMode, sortDesc: sortDesc });
  };

  sendReorderedList = async () => {
    try {
      const token = await this.props.token();

      let reorderedList = [];

      this.state.images.forEach((item, index) => {
        const list = {
          photoId: item.key,
          orderId: index,
        };

        reorderedList.push(list);
      });

      console.log(reorderedList);

      const body = JSON.stringify({
        type: "order",
        data: reorderedList,
      });

      const response = await fetch(
        "https://api.shieldshot.com/photosets/update",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          body: body,
          method: "POST",
        }
      );

      const responseData = await response;

      if (responseData.status === 200) {
        NotificationManager.success("", "Sort saved", 10000);
      } else {
        NotificationManager.warning(
          "Error " + responseData.status,
          "Sort Request Problem",
          10000
        );
      }
    } catch (err) {
      NotificationManager.warning("Error " + err, "Sort Request Error", 10000);
    }
  };

  disableMe = () => {
    this.setState({ dragging: false });
  };

  handleChangeActiveMonth = async () => {
    try {
      const token = await this.props.token();

      let activeMonth = Moment([
        parseInt(this.state.monthValue.year),
        parseInt(this.state.monthValue.month) - 1,
        1,
      ]).unix();

      console.log("month", this.state.monthValue);

      const body = JSON.stringify({
        type: "activeMonth",
        setid: this.state.setid,
        data: activeMonth,
      });

      const response = await fetch(
        "https://api.shieldshot.com/photosets/update",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          body: body,
          method: "POST",
        }
      );

      const responseData = await response;

      if (responseData.status === 200) {
        NotificationManager.success("", "Active month changed", 10000);
      } else {
        NotificationManager.warning(
          "Error " + responseData.status,
          "Something went wrong",
          10000
        );
      }
    } catch (err) {
      NotificationManager.warning(
        "Something went wrong",
        "Request Error",
        10000
      );
    }
  };

  render() {
    let steps = [
      {
        selector: "#contentArea",
        content: "This is the Photoset Details Page",
      },
      {
        selector: "#tour-1",
        content: "You can change the name of your Photoset by clicking here",
      },
      {
        selector: "#addButton",
        content: "You can add photos to your photoset by clicking this",
      },
      {
        content: "... or by dragging your photos anywhere in the browser.",
      },
      {
        selector: "#addButton",
        content: "You'll need to subscribe to a plan to upload photos.",
      },
      {
        selector: "#tour-2",
        content: "Edit your photoset's Tier by clicking this button.",
      },
      {
        selector: "#tour-3",
        content: "Edit your photoset's Reward month by clicking this button.",
      },
      {
        selector: "#tour-4",
        content:
          "This button is for when you want to sort the order of your photos.",
      },
      {
        selector: "#tour-5",
        content: "Download your photoset with this button.",
      },
      {
        selector: "#tour-6",
        content: "Delete your photoset with this button",
      },
      {
        selector: "#tour-7",
        content:
          "This is the status of your photoset. It changes from empty, to processing, to 'Ready for Distribution'. You can only start distributing photosets when the status isn't empty or processing.",
      },
      {
        selector: "#tour-8",
        content: "Edit your notes here",
      },
      {
        selector: "#tour-9",
        content:
          "Edit your copyright here. We embed this data to your photos so we can add a tracker to your photos",
      },
      {
        selector: "#tour-10",
        content: "Uploaded photosets will show up here.",
      },
      {
        selector: "#v-pills-emailList-tab",
        content: "For now, let's go to the Email List page",
      },
      {
        content: "",
        action: () => {
          if (!this.state.redirected) {
            History.push("/emailList");
            this.setState({ redirected: true });
          }
        },
      },
    ];

    const makeText = (m) => {
      if (m && m.year && m.month)
        return pickerLang.months[m.month - 1] + " " + m.year;
      return "?";
    };

    const pickerLang = {
      months: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
      from: "From",
      to: "To",
    };

    return (
      <React.Fragment>
        <Prompt
          when={this.state.isUploading}
          message={(location) =>
            `An upload is still ongoing! Are you sure you want to leave this page?`
          }
        />

        <Modal size="lg" show={this.state.showModal} onHide={this.closeModal}>
          <Modal.Header closeButton>{this.state.currentFileName}</Modal.Header>
          <Modal.Body className="p-0" style={{ minHeight: "60px" }}>
            {this.state.prevItem && (
              <div
                className="prevButton"
                onClick={() =>
                  this.openModal(
                    this.state.prevItem.f,
                    this.state.prevItem.key,
                    this.state.prevIndex,
                    this.state.prevItem.status,
                    this.state.prevIndex - 1,
                    this.state.prevIndex + 1
                  )
                }
              >
                <FontAwesomeIcon icon={faArrowCircleLeft} />
              </div>
            )}

            {this.state.nextItem && (
              <div
                className="nextButton"
                onClick={() =>
                  this.openModal(
                    this.state.nextItem.f,
                    this.state.nextItem.key,
                    this.state.nextIndex,
                    this.state.nextItem.status,
                    this.state.nextIndex - 1,
                    this.state.nextIndex + 1
                  )
                }
              >
                <FontAwesomeIcon icon={faArrowCircleRight} />
              </div>
            )}

            {this.state.currentFullImage !== "" && (
              <Img
                className="w-100 no-pointer-events"
                src={this.state.currentFullImage}
                loader={
                  <div
                    style={{
                      display: "flex",
                      justifyItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Spinner variant="primary" animation="border" />
                  </div>
                }
              />
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="outline-danger"
              onClick={this.deletePhoto}
              disabled={!this.state.isStatusSuccess}
            >
              <FontAwesomeIcon icon={faExclamationTriangle} className="mr-1" />
              Delete
            </Button>
          </Modal.Footer>
        </Modal>

        <EndTour end={() => this.setState({ tourTime: false })} />
        <Tour
          steps={steps}
          rounded={25}
          isOpen={this.state.tourTime}
          closeWithMask={false}
          onRequestClose={() => {
            this.setState({ tourTime: false });
          }}
        />

        <div style={{ height: "15px" }}>
          <Collapse in={this.state.collapseProgress}>
            <div>
              <ProgressBar animated now={this.state.percent} />
            </div>
          </Collapse>
        </div>

        {this.state.dragging &&
          !this.state.disableDragging &&
          !this.state.sortMode && (
            <Dropzone
              disableMe={this.disableMe}
              onDrop={this.onDrop}
            ></Dropzone>
          )}

        <div className="min100vh">
          {this.state.preload ? (
            <Spinner
              as="span"
              animation="border"
              role="status"
              aria-hidden="true"
              className="mr-2"
              variant="primary"
            />
          ) : (
            <React.Fragment>
              <Row>
                <Col md={6} sm={12}>
                  <div className="text-left p-3 pb-0 ">
                    {this.state.editName ? (
                      <Form onSubmit={this.editNameSubmit}>
                        <Form.Group controlId="TextArea">
                          <Form.Control
                            type="text"
                            rows="3"
                            disabled={this.state.disableName}
                            defaultValue={this.state.photosetName}
                            onChange={this.handleEditName}
                          />
                          <Form.Label className="text-right">
                            <small>Press enter to save new Photoset name</small>
                          </Form.Label>
                        </Form.Group>
                      </Form>
                    ) : (
                      <React.Fragment>
                        <h5 className="mb-0 inline f">
                          {this.state.photosetName}
                          <sup
                            className="ml-2 text-primary pointer text-align-top"
                            style={{ fontSize: "0.7rem" }}
                            onClick={this.startEditName}
                            id="tour-1"
                          >
                            Edit
                          </sup>
                        </h5>
                      </React.Fragment>
                    )}

                    <span
                      className=" font-weight-bold mr-2 badge"
                      id="tour-7"
                      style={{ backgroundColor: this.state.statusColor }}
                    >
                      {this.state.setStatus}
                    </span>

                    <div className="mt-1" style={{ height: "30px" }}>
                      <span id="tour-2">
                        <TierDropdownPill
                          token={this.props.token}
                          action={this.handleTierChange}
                          className="m-3"
                          defaultValue={this.state.tier}
                        />
                      </span>
                    </div>
                  </div>
                </Col>

                <Col
                  className="text-left text-md-right p-3"
                  id="photosetDetailsButtons"
                >
                  <span id="tourAddButton">
                    {this.state.isUploading ? (
                      <Button
                        id="loadingButton"
                        variant="primary"
                        className="btn-sm mr-1 mt-1"
                        disabled
                      >
                        <Spinner
                          as="span"
                          animation="grow"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                          className="mr-2"
                        />
                        Submitting...
                      </Button>
                    ) : (
                      <Button
                        variant="primary"
                        className="mr-1 mt-1 btn-sm"
                        onClick={this.clickUpload}
                        id="addButton"
                      >
                        <FontAwesomeIcon icon={faPlus} className="mr-1" />
                        Add Photos
                      </Button>
                    )}
                  </span>

                  <MonthPicker
                    className="d-inline"
                    ref={this.pickAMonth}
                    years={{
                      min: { year: 2019, month: 2 },
                      max: { year: 2200, month: 1 },
                    }}
                    age={0}
                    value={this.state.monthValue}
                    lang={[
                      "Jan",
                      "Feb",
                      "Mar",
                      "Apr",
                      "May",
                      "Jun",
                      "Jul",
                      "Aug",
                      "Sep",
                      "Oct",
                      "Nov",
                      "Dec",
                    ]}
                    onChange={(year, month) => {
                      this.setState({
                        monthValue: {
                          month: parseInt(month),
                          year: parseInt(year),
                        },
                      });
                    }}
                    onDismiss={this.handleChangeActiveMonth}
                  >
                    <Button
                      variant="warning"
                      className="mr-1 mt-1 btn-sm"
                      id="tour-3"
                      onClick={() => {
                        this.pickAMonth.current.show();
                      }}
                    >
                      {makeText(this.state.monthValue)}
                    </Button>
                  </MonthPicker>

                  <Button
                    id="tour-4"
                    variant="outline-dark"
                    className="mr-1 mt-1 btn-sm"
                    onClick={this.toggleSortMode}
                  >
                    {this.state.sortDesc}
                  </Button>

                  <Button
                    id="tour-5"
                    variant="outline-dark"
                    className=" mt-1 mr-1  btn-sm"
                    onClick={this.requestDownload}
                    disabled={!this.state.allowDownload}
                  >
                    {this.state.isRequesting ? (
                      <Spinner animation="border" size="sm" variant="primary" />
                    ) : (
                      <FontAwesomeIcon icon={faDownload} />
                    )}
                  </Button>

                  <Button
                    id="tour-6"
                    variant="outline-danger"
                    className=" mt-1  btn-sm mr-1"
                    onClick={this.deletePhotoset}
                    disabled={
                      !this.state.isStatusSuccess ||
                      this.state.setState === "Processing" ||
                      this.setState === "Empty"
                    }
                  >
                    <FontAwesomeIcon icon={faTrash} />
                  </Button>

                  <input
                    id="uploadButton"
                    type="file"
                    name="file"
                    onChange={(e) => this.onChange(e)}
                    className="hidden"
                    multiple
                  />
                  {/* <div>
                    <small>
                      <b>Uploaded </b> {this.state.createdAt}
                      <b className="ml-3"> Schedule </b> Not yet scheduled
                    </small>
                  </div> */}
                </Col>
              </Row>

              <div className="text-left pt-0 pl-3 pr-3 pb-3">
                <small>
                  <Row>
                    <Col>
                      {!this.state.editNote ? (
                        <React.Fragment>
                          <span
                            id="tour-8"
                            className="text-primary mr-2 pointer"
                            onClick={this.startEditNote}
                          >
                            Edit Note
                          </span>
                          <span>{this.state.notes}</span>
                        </React.Fragment>
                      ) : (
                        <Form onSubmit={this.editNoteSubmit}>
                          <Form.Group controlId="TextArea">
                            <Form.Control
                              type="text"
                              rows="3"
                              disabled={this.state.disableNotes}
                              defaultValue={this.state.notes}
                              onChange={this.handleEditNote}
                            />
                            <Form.Label className="text-right">
                              <small>Press enter to save your notes</small>
                            </Form.Label>
                          </Form.Group>
                        </Form>
                      )}
                    </Col>

                    <Col>
                      {!this.state.editCopyright ? (
                        <React.Fragment>
                          <span
                            className="text-primary mr-2 pointer"
                            onClick={this.startEditCopyright}
                            id="tour-9"
                          >
                            Edit Copyright
                          </span>
                          <span>{this.state.copyright}</span>
                        </React.Fragment>
                      ) : (
                        <Form onSubmit={this.editCopyrightSubmit}>
                          <Form.Group controlId="TextArea">
                            <Form.Control
                              type="text"
                              rows="3"
                              disabled={this.state.disableCopyright}
                              defaultValue={this.state.copyright}
                              onChange={this.handleCopyright}
                            />
                            <Form.Label className="text-right">
                              <small>Press enter to save your copyright</small>
                            </Form.Label>
                          </Form.Group>
                        </Form>
                      )}
                    </Col>
                  </Row>
                </small>
              </div>

              {this.state.initGetPhotos && (
                <div className="small text-left text-secondary">
                  {this.state.loadedImages.length} Photo
                  {this.state.loadedImages.length !== 1 && "s"}
                </div>
              )}
            </React.Fragment>
          )}

          <div id="tour-10">
            {this.state.sortMode ? (
              <SortableGalleryV2
                items={this.state.images}
                onSortEnd={this.onSortEnd}
                axis={"xy"}
              />
            ) : (
              <React.Fragment>
                {this.state.loadedImages.length ? (
                  <Row className="text-left mx-sm-0 mx-md-0 mx-lg-0 mx-xl-0 p-0">
                    {this.state.images.map((image, index) => (
                      <Col
                        lg={2}
                        md={3}
                        sm={4}
                        xs={6}
                        key={image.id + "-" + index}
                        className="p-1 m-0 rounded col-thumb"
                      >
                        <Img
                          src={image.src}
                          loader={
                            <div className="d-flex align-items-center w-100">
                              <Spinner
                                variant="primary"
                                className="justify-content-center align-self-center"
                                animation="border"
                              />
                            </div>
                          }
                          onClick={() =>
                            this.openModal(
                              image.f,
                              image.key,
                              index,
                              image.status,
                              index - 1,
                              index + 1
                            )
                          }
                          className="img-thumbnail pointer"
                        />

                        <div className="fileNamePopup text-secondary">
                          <small>
                            <span>
                              <b>{image.f}</b>
                            </span>
                          </small>
                        </div>
                      </Col>
                    ))}
                  </Row>
                ) : (
                  <React.Fragment>
                    {!this.state.isLoading ? (
                      <React.Fragment>
                        {!this.state.init ? (
                          <div className=" pt-3 mt-3">
                            <h5 className="text-secondary">
                              Looks like you have no photos yet.
                            </h5>
                            <small>
                              To start uploading photos, click the{" "}
                              <Button
                                size="sm"
                                style={{ lineHeight: "1.1", fontSize: "14px" }}
                                onClick={this.clickUpload}
                                className="mx-2"
                              >
                                + Add Photos
                              </Button>
                              button.
                              <br />
                              <br />{" "}
                              <h5 className="text-secondary">
                                {" "}
                                Or drag your photos anywhere to the browser.
                              </h5>
                            </small>
                          </div>
                        ) : (
                          <Spinner animation="border" variant="primary" />
                        )}
                      </React.Fragment>
                    ) : (
                      ""
                    )}
                  </React.Fragment>
                )}
              </React.Fragment>
            )}
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default withRouter(PhotoSetDetails);
