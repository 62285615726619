import React, { Component } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Toast from "react-bootstrap/Toast";
import EmailList from "./tierDetailsEmailListItem";
import Spinner from "react-bootstrap/Spinner";
import ListGroup from "react-bootstrap/ListGroup";
import Form from "react-bootstrap/Form";
import { confirm } from "../confirmation";
import { CirclePicker } from "react-color";
import Collapse from "react-bootstrap/Collapse";
import { NotificationManager } from "react-notifications";
import History from "../../utils/history";

import TierDropdown from "../parts/tierDropdown";

import Tour from "reactour";
import EndTour from "../parts/endTour";

import Moment from "moment";
import MomentLocalizer from "react-widgets-moment";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCog,
  faExclamationTriangle,
} from "@fortawesome/free-solid-svg-icons";

Moment.locale("en");
MomentLocalizer();

class TierDetails extends Component {
  _isMounted = false;

  state = {
    tourTime: localStorage.getItem("tour") === "true" ? true : false,
    message: "",
    showToast: false,
    ToastTitle: "",
    loading: false,
    user: this.props.user,
    init: true,
    rel_id: this.props.match.params.id,
    color: "#EEEEEE",
    selectedColor: "#EEEEEE",
    editColor: false,
    emailList: [],
    emailListLoading: true,
    editNotes: false,
    oldNotes: "",
    disableNotes: false,
    editName: false,
    oldName: false,
    disableName: false,
    editColorButton: "Edit Color",
    isLoading: true,
    showParentTierChange: false,
  };

  componentDidMount() {
    this._isMounted = true;

    this.getTierDetailsEndpoint();
    this.getEmailListEndpoint();

    // const dynamodb = new this.props.AWS.DynamoDB();

    // console.log("working");

    // let params = {
    //   TableName: "tiers",
    //   IndexName: "tiers_index",
    //   KeyConditionExpression: "rel_id = :rel_id AND userid = :userid",
    //   ExpressionAttributeValues: {
    //     ":userid": {
    //       S: this.props.user.sub,
    //     },
    //     ":rel_id": {
    //       N: this.props.match.params.id,
    //     },
    //   },
    // };

    // dynamodb.query(params, (err, data) => {
    //   if (err) {
    //     alert("DYNAMODB: failure in getting latest data");
    //     console.log(err.stack);
    //   } else {
    //     console.log(data);

    //     this.setState({
    //       tierName: data.Items[0].tierName.S,
    //       createdAt: data.Items[0].createdAt.S.substring(
    //         0,
    //         data.Items[0].createdAt.S.indexOf("T")
    //       ),
    //       color: data.Items[0].color.S,
    //       id: data.Items[0].id.S,
    //       parentTier: data.Items[0].parentTier.S,
    //       notes: data.Items[0].notes.S,
    //     });

    //     if (this._isMounted) {
    //       let c = this.state.color.substring(1); // strip #
    //       let rgb = parseInt(c, 16); // convert rrggbb to decimal
    //       let r = (rgb >> 16) & 0xff; // extract red
    //       let g = (rgb >> 8) & 0xff; // extract green
    //       let b = (rgb >> 0) & 0xff; // extract blue

    //       let luma = 0.2126 * r + 0.7152 * g + 0.0722 * b; // per ITU-R BT.709

    //       let fontColor = "#333333";

    //       if (luma < 100) fontColor = "#FFFFFF";

    //       this.setState({ isLoading: false, fontColor: fontColor });
    //       //this.getEmailList();
    //       this.getEmailListEndpoint();
    //     }
    //   }
    // });
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  getTierListEndpoint = async () => {
    console.log("getting tiers...");
    try {
      const token = await this.props.token();
      const body = JSON.stringify({});
      const response = await fetch(
        "https://api.shieldshot.com/tiers/list",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          body: body,
          method: "POST",
        }
      );

      const responseData = await response;
      if (responseData.status === 200) {
        const responseJson = await responseData.json();
        let list = [];

        responseJson.forEach((item) => {
          //
          if (item.rel_id.N !== "0") {
            let list_item = {
              id: item.id.S,
              title: item.rel_id.N,
              rel_id: item.rel_id.N,
              nickname: item.tierName.S,
              color: item.color.S,
              parentTier: item.parentTier.S,
            };

            list.push(list_item);
          }
        });

        if (this._isMounted) {
          //          localStorage.setItem("tiers", JSON.stringify(list));
          this.getInheritanceInit(list);
        }
      } else {
        console.log("there's a problem with this endpoint");
      }
    } catch (err) {
      console.log("Error getting tier list from endpoint", err);
    }
  };

  inheritance = [];
  originalId = null;
  tiers = [];

  getInheritanceInit = (tiers) => {
    this.tiers = tiers;
    tiers.forEach((item) => {
      //console.log(item);
      item.inheritance = [];
      if (item.parentTier !== "0") {
        this.getInheritance(item.parentTier, [], item.rel_id);

        item.inheritance = this.inheritance;
        this.inheritance = [];
      }
    });

    localStorage.setItem("tiers", JSON.stringify(tiers));
    //this.setState({ tiers: tiers, isLoading: false });
    this.tiers = [];
  };

  getInheritance = (id, inheritance, originalId = null) => {
    if (originalId !== null) {
      this.originalId = id;

      //find the parent
      this.tiers.forEach((item) => {
        if (item.rel_id === id) {
          this.inheritance.push(item.nickname);
          this.getInheritance(item.parentTier, inheritance);
        } else {
        }
      });
    } else {
      if (id === 0 || this.originalId === id) {
        this.originalId = null;

        return;
      } else {
        //find the parent
        this.tiers.forEach((item) => {
          if (item.rel_id === id) {
            this.inheritance.push(item.nickname);

            this.getInheritance(item.parentTier, inheritance);
          }
        });
      }
    }
  };

  getTierDetailsEndpoint = async () => {
    try {
      const token = await this.props.token();

      const body = JSON.stringify({ rel_id: this.state.rel_id });

      const response = await fetch(
        "https://api.shieldshot.com/tiers/details",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          body: body,
          method: "POST",
        }
      );

      const responseData = await response;

      if (responseData.status === 200) {
        const responseJson = await responseData.json();

        if (!responseJson.length) History.push("/404");
        else
          this.setState({
            tierName: responseJson[0].tierName.S,
            createdAt: responseJson[0].createdAt.S,
            color: responseJson[0].color.S,
            id: responseJson[0].id.S,
            parentTier: parseInt(responseJson[0].parentTier.S),
            notes: responseJson[0].notes.S,
            selectedColor: responseJson[0].color.S,
          });

        console.log(this.state.parentTier);

        if (this._isMounted) {
          let c = this.state.color.substring(1); // strip #
          let rgb = parseInt(c, 16); // convert rrggbb to decimal
          let r = (rgb >> 16) & 0xff; // extract red
          let g = (rgb >> 8) & 0xff; // extract green
          let b = (rgb >> 0) & 0xff; // extract blue

          let luma = 0.2126 * r + 0.7152 * g + 0.0722 * b; // per ITU-R BT.709

          let fontColor = "#333333";

          if (luma < 150) fontColor = "#FFFFFF";

          this.setState({ isLoading: false, fontColor: fontColor });
          //this.getEmailList();
          //this.getEmailListEndpoint();
        }
      } else {
        console.log("failure on endpoint", responseData.status);
      }
    } catch (err) {
      console.log("error on add endpoint", err);
    }
  };

  setShow(sta) {
    if (sta === true && this.state.showToast === true) {
      this.setState({ showToast: false });
      setTimeout(() => {
        this.setState({ showToast: true });
      }, 300);
    } else {
      this.setState({ showToast: sta });
    }
  }

  deleteTierEndpoint = async () => {
    if (await confirm("Are you sure?")) {
      const token = await this.props.token();
      const body = JSON.stringify({
        tierId: this.state.id,
        rel_id: this.state.rel_id,
      });

      try {
        const response = await fetch(
          "https://api.shieldshot.com/tiers/delete",
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
            body: body,
            method: "POST",
          }
        );

        const responseData = await response;
        if (responseData.status === 200) {
          localStorage.removeItem("tiers");
          this.props.history.push("/tiers");
        } else {
          NotificationManager.warning(
            "Error: " + responseData.status,
            "Something went wrong with deleting the tier",
            10000
          );
        }
      } catch (err) {
        NotificationManager.warning(
          "Syntax Error.",
          "Uh oh, something went wrong with deleting the tier",
          10000
        );
      }
    }
  };

  getEmailListEndpoint = async () => {
    try {
      const token = await this.props.token();

      const body = JSON.stringify({
        rel_id: this.props.match.params.id,
        //type: "emails",
      });

      const response = await fetch(
        "https://api.shieldshot.com/emails/details",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          body: body,
          method: "POST",
        }
      );

      const responseData = await response;

      if (responseData.status === 200) {
        const responseJson = await responseData.json();

        let emailList = [];

        responseJson.forEach((item) => {
          let tier = parseInt(item.tier.S);
          if (typeof tier === "undefined") tier = 0;

          //
          let list_item = {
            id: item.id.S,
            note: item.note.S,
            email: item.email.S,
          };

          emailList.push(list_item);
        });

        if (this._isMounted) {
          this.setState({
            emailList: emailList,
            isLoading: false,
            count: emailList.length,
            init: false,
            emailListLoading: false,
          });
        }

        //if (this._isMounted) this.setState({ emailList: emailList });
      } else {
        NotificationManager.warning(
          "Error: " + responseData.status,
          "Something went wrong with getting tier details",
          10000
        );
      }
    } catch (err) {
      NotificationManager.warning(
        "Syntax Error.",
        "Uh oh, something went wrong with  getting tier details",
        10000
      );
    }
  };

  startEditNotes = () => {
    this.setState({ oldNotes: this.state.notes, editNotes: true });
  };

  handleEditNotes = (ev) => {
    this.setState({ notes: ev.target.value });
  };

  handleEditColors = async () => {
    let button = "";
    this.state.editColor === true
      ? (button = "saving...")
      : (button = "Save Changes");

    if (this._isMounted)
      this.setState({
        editColor: !this.state.editColor,
        editColorButton: button,
      });

    if (this.state.editColor) {
      if (this.state.color !== this.state.selectedColor) {
        try {
          const token = await this.props.token();

          const body = JSON.stringify({
            tierid: this.state.id,
            type: "color",
            data: this.state.selectedColor,
          });

          const response = await fetch(
            "https://api.shieldshot.com/tiers/update",
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
              body: body,
              method: "POST",
            }
          );

          const responseData = await response;

          if (response.status === 200) {
            this.setState({
              color: this.state.selectedColor,
              editColorButton: "Edit color",
            });
            this.getTierListEndpoint();
            NotificationManager.success("Color updated", "", 10000);
          } else {
            NotificationManager.warning(
              "Error: " + responseData.status,
              "Something went wrong with updating color",
              10000
            );
          }
        } catch (error) {
          NotificationManager.warning(
            "Syntax Error.",
            "Uh oh, something went wrong with  updating color",
            10000
          );
        }
      } else this.setState({ editColorButton: "Edit color" });
    }
  };

  editNotesSubmit = async (ev) => {
    ev.preventDefault();

    if (this.state.notes === "") this.setState({ notes: "no note" });

    this.setState({ disableNotes: true });

    try {
      const token = await this.props.token();

      const body = JSON.stringify({
        tierid: this.state.id,
        type: "notes",
        data: this.state.notes,
      });

      const response = await fetch(
        "https://api.shieldshot.com/tiers/update",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          body: body,
          method: "POST",
        }
      );

      const responseData = await response;

      if (response.status === 200) {
        this.setState({ editNotes: false });

        NotificationManager.success("Notes updated", "", 10000);
        this.getTierListEndpoint();
      } else {
        NotificationManager.warning(
          "Error: " + responseData.status,
          "Something went wrong with updating notes",
          10000
        );
      }

      this.setState({ disableNotes: false });
    } catch (error) {
      NotificationManager.warning(
        "Syntax Error",
        "Something went wrong with updating notes",
        10000
      );
    }
  };

  toggleChangeParentTier = async (color, rel_id, name, tierId) => {
    this.setState({ parentTier: rel_id });

    try {
      const token = await this.props.token();

      const body = JSON.stringify({
        tierid: this.state.id,
        type: "parentTier",
        data: rel_id,
      });

      const response = await fetch(
        "https://api.shieldshot.com/tiers/update",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          body: body,
          method: "POST",
        }
      );

      const responseData = await response;

      if (response.status === 200) {
        NotificationManager.success("Parent tier updated", "", 10000);
        this.getTierListEndpoint();
      } else {
        NotificationManager.warning(
          "Error: " + responseData.status,
          "Something went wrong with updating notes",
          10000
        );
      }
    } catch (error) {
      NotificationManager.warning(
        "Syntax Error",
        "Something went wrong with updating parent tier",
        10000
      );
    }

    this.handleParentChange(false);
  };

  startEditName = () => {
    this.setState({ oldName: this.state.tierName, editName: true });
  };

  handleEditName = (ev) => {
    this.setState({ tierName: ev.target.value });
  };

  handleChangeComplete = async (color) => {
    if (this._isMounted) this.setState({ selectedColor: color.hex });
  };

  editNameSubmit = async (ev) => {
    ev.preventDefault();

    if (this.state.tierName === "") {
      this.setMessage("Can't have empty tier name!", "There's a problem", true);
      return;
    }

    this.setState({ disableName: true });

    try {
      const token = await this.props.token();

      const body = JSON.stringify({
        tierid: this.state.id,
        type: "tierName",
        data: this.state.tierName,
      });

      const response = await fetch(
        "https://api.shieldshot.com/tiers/update",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          body: body,
          method: "POST",
        }
      );

      const responseData = await response;

      if (response.status === 200) {
        this.setState({ editName: false });
        this.getTierListEndpoint();
        NotificationManager.success("Tier name updated", "", 10000);
      } else {
        NotificationManager.warning(
          "Error: " + responseData.status,
          "Something went wrong with updating Tier name",
          10000
        );
      }

      this.setState({ disableName: false });
    } catch (error) {
      NotificationManager.warning(
        "Syntax Error",
        "Something went wrong with updating Tier name",
        10000
      );
    }
  };

  handleParentChange = (i) => {
    this.setState({ showParentTierChange: i });
  };

  setMessage = (msg, title = "", setshow = "") => {
    this.setState({ message: msg, ToastTitle: title });
    if (setshow !== "") this.setShow(setshow);
  };

  render() {
    const steps = [
      {
        selector: "#contentArea",
        content: "This is the Tier Details page",
      },
      {
        selector: "#tour-1",
        content: "You can change the name of your tier by clicking here",
      },
      {
        selector: "#tour-2",
        content: "Change your tier's color by clicking this",
      },
      {
        selector: "#tour-3",
        content: "Delete your tier by clicking this button.",
      },
      {
        selector: "#tour-4",
        content: "Edit your notes by clicking this",
      },
      {
        selector: "#tour-5",
        content:
          "In here, you'll see the list of emails that are subscribed in this tier. Right now it's empty since we haven't added any emails yet.",
      },
      {
        selector: "#v-pills-photosets-tab",
        content:
          "Next, we'll look at how to add Photosets. Let's go to the photosets page.",
      },
      {
        content: "",
        action: () => {
          History.push("/photosets");
        },
      },
    ];

    if (this.state.isLoading || this.state.emailListLoading)
      return <Spinner animation="border" variant="primary" />;

    return (
      <React.Fragment>
        <EndTour end={() => this.setState({ tourTime: false })} />
        <Tour
          steps={steps}
          rounded={25}
          isOpen={this.state.tourTime}
          closeWithMask={false}
          onRequestClose={() => {
            this.setState({ tourTime: false });
          }}
        />

        <Toast
          style={{
            position: "absolute",
            bottom: "1rem",
            right: "1rem",
            zIndex: 2000,
          }}
          className="pointer"
          onClick={() => this.setShow(false)}
          onClose={() => this.setShow(false)}
          show={this.state.showToast}
          delay={5000}
          autohide
        >
          {/* <Toast.Header>
            <img
              src="holder.js/20x20?text=%20"
              className="rounded mr-2"
              alt=""
            />
            <strong className="mr-auto">{this.state.ToastTitle}</strong>
            <small>11 mins ago</small> 
          </Toast.Header> */}
          <Toast.Body>{this.state.message}</Toast.Body>
        </Toast>

        <div>
          <Row>
            <Col>
              <div className="text-left p-3 pb-0">
                {this.state.editName ? (
                  <Form onSubmit={this.editNameSubmit}>
                    <Form.Group controlId="TextArea">
                      <Form.Control
                        type="text"
                        rows="3"
                        disabled={this.state.disableName}
                        defaultValue={this.state.tierName}
                        onChange={this.handleEditName}
                      />
                      <Form.Label className="text-right">
                        <small>Press enter to save new Tier Name</small>
                      </Form.Label>
                    </Form.Group>
                  </Form>
                ) : (
                  <React.Fragment>
                    <h5 className="mb-0 inline f">
                      {this.state.tierName}
                      <sup
                        className="ml-2 text-primary pointer text-align-top"
                        style={{ fontSize: "0.7rem" }}
                        onClick={this.startEditName}
                        id="tour-1"
                      >
                        Edit
                      </sup>
                    </h5>
                    <small>
                      <span
                        className="font-weight-bold badge badge-pill"
                        style={{
                          backgroundColor: this.state.color,
                          color: this.state.fontColor,
                        }}
                      >
                        Tier {this.state.rel_id}
                      </span>
                      <b className="ml-2">Created </b>
                      {Moment.unix(this.state.createdAt).format("LL")}
                    </small>
                  </React.Fragment>
                )}
              </div>
            </Col>
            <Col className="text-left text-md-right p-3" sm={12} md={7}>
              {this.state.isLoading ? (
                ""
              ) : (
                <React.Fragment>
                  <div className="d-inline m-1" style={{ maxWidth: "100px" }}>
                    <div
                      className={
                        this.state.showParentTierChange ? "d-inline" : "d-none"
                      }
                    >
                      <TierDropdown
                        token={this.props.token}
                        action={this.toggleChangeParentTier}
                        defaultValue={this.state.parentTier}
                        size="sm"
                      />
                    </div>

                    <Button
                      className={
                        !this.state.showParentTierChange ? "btn-sm" : "d-none"
                      }
                      onClick={() => this.handleParentChange(true)}
                    >
                      Change Parent Tier
                    </Button>
                  </div>

                  <Button
                    style={{
                      backgroundColor: this.state.selectedColor,
                      borderColor: this.state.selectedColor,
                    }}
                    className="m-1 btn-sm"
                    onClick={this.handleEditColors}
                    id="tour-2"
                  >
                    <FontAwesomeIcon icon={faCog} className="mr-1" />
                    {this.state.editColorButton}
                  </Button>
                  <Button
                    id="tour-3"
                    variant="outline-danger"
                    className=" btn-sm m-1"
                    onClick={this.deleteTierEndpoint}
                  >
                    <FontAwesomeIcon
                      icon={faExclamationTriangle}
                      className="mr-1"
                    />
                    Delete
                  </Button>
                </React.Fragment>
              )}
            </Col>
          </Row>

          <div className="text-left pt-0 pl-3 pr-3 pb-3 mb-3">
            <small>
              <Row>
                <Col>
                  {!this.state.editNotes ? (
                    <React.Fragment>
                      <span
                        className="text-primary mr-2 pointer"
                        onClick={this.startEditNotes}
                        id="tour-4"
                      >
                        Edit Note
                      </span>
                      <span>{this.state.notes}</span>
                    </React.Fragment>
                  ) : (
                    <Form onSubmit={this.editNotesSubmit}>
                      <Form.Group controlId="TextArea">
                        <Form.Control
                          type="text"
                          rows="3"
                          disabled={this.state.disableNotes}
                          defaultValue={this.state.notes}
                          onChange={this.handleEditNotes}
                        />
                        <Form.Label className="text-right">
                          <small>Press enter to save your notes</small>
                        </Form.Label>
                      </Form.Group>
                    </Form>
                  )}
                </Col>
              </Row>
            </small>
          </div>

          <div>
            {this.state.isLoading ? (
              ""
            ) : (
              <Collapse in={this.state.editColor}>
                <div className=" mb-4  text-left bg-white p-2">
                  <h4>Choose a color</h4>
                  <CirclePicker
                    color={this.state.selectedColor}
                    circleSpacing={13.5}
                    colors={this.state.colors}
                    width="100%"
                    onChangeComplete={this.handleChangeComplete}
                  />
                </div>
              </Collapse>
            )}
          </div>
          <div className="text-left" id="tour-5">
            <small>
              <h5 className="text-secondary">
                E-mails subscribed to this Tier
              </h5>
              {this.state.emailListLoading ? (
                <Spinner animation="grow" variant="secondary" />
              ) : (
                <div className="text-left bg-white">
                  {this.state.emailList.length ? (
                    <ListGroup variant="flush">
                      {this.state.emailList.map((item) => (
                        <EmailList key={item.id} data={item} />
                      ))}
                    </ListGroup>
                  ) : (
                    <div className="m-2 p-2 text-secondary text-center">
                      No emails subscribed to this tier.
                    </div>
                  )}
                </div>
              )}
            </small>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default TierDetails;
