import React from "react";
import PropTypes from "prop-types";

import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { confirmable, createConfirmation } from "react-confirm";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";

class Confirmation extends React.Component {
  render() {
    const {
      proceedLabel,
      cancelLabel,
      // title,
      confirmation,
      show,
      proceed,
      enableEscape = true,
    } = this.props;
    return (
      <div className="static-modal shadow">
        <Modal
          show={show}
          onHide={() => proceed(false)}
          backdrop={enableEscape ? true : "static"}
          keyboard={enableEscape}
        >
          <Modal.Header style={{ backgroundColor: "#ffce19" }}></Modal.Header>
          <Modal.Body
            className="text-center pb-4 pt-3 text-dark"
            style={{ backgroundColor: "#ffce19" }}
          >
            <h1>
              <FontAwesomeIcon icon={faExclamationTriangle} />
            </h1>

            {confirmation}
          </Modal.Body>
          <Modal.Footer
            className="text-center"
            style={{ backgroundColor: "#ffc800" }}
          >
            <Button variant="secondary" onClick={() => proceed(false)}>
              {cancelLabel}
            </Button>
            <Button variant="danger" onClick={() => proceed(true)}>
              {proceedLabel}
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

Confirmation.propTypes = {
  okLabbel: PropTypes.string,
  cancelLabel: PropTypes.string,
  title: PropTypes.string,
  confirmation: PropTypes.string,
  show: PropTypes.bool,
  proceed: PropTypes.func, // called when ok button is clicked.
  enableEscape: PropTypes.bool,
};

export function confirm(
  confirmation,
  proceedLabel = "OK",
  cancelLabel = "cancel",
  options = {}
) {
  return createConfirmation(confirmable(Confirmation))({
    confirmation,
    proceedLabel,
    cancelLabel,
    ...options,
  });
}
