import React, { Component } from "react";
import { NotificationManager } from "react-notifications";
import Pagination from "react-bootstrap/Pagination";

import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Spinner from "react-bootstrap/Spinner";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

import { Img } from "react-image";

import LoadingScreen from "../parts/loadingScreen";

import Moment from "moment";
import MomentLocalizer from "react-widgets-moment";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowCircleLeft,
  faArrowCircleRight,
} from "@fortawesome/free-solid-svg-icons";

Moment.locale("en");
MomentLocalizer();

class AdminDashboard extends Component {
  _isMounted = false;

  state = {
    loading: true,
    activePage: 1,
    pages: [],
    count: 0,
    photosets: [],
    tiers: [],
    selectedPages: [],
    photolist: [],
    showModal: false,
    selectedPhotoset: 0,
    modalLoading: true,
    modalPhotos: [],
    photosetDetails: [],
    photoCount: 0,
    currentFullImage: "",
    currentIndexId: 0,
    currentFileName: "",
    nextItem: null,
    prevItem: null,
    currentImageId: "",
    modalMode: "thumbs",
    prevIndex: null,
    nextIndex: null,
    versionTime: 1591213166,
  };

  componentDidMount() {
    this._isMounted = true;
    this.getAdminList();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  openModal = (i, id, index, photoStatus, prev, next) => {
    console.log("prev", prev);
    console.log("current index id", index);
    console.log("next", next);

    this.setState({ modalMode: "single" });

    // i filename
    // id is key
    // index
    // photostatus
    // prev index
    // next index

    if (prev > 0) {
      this.setState({
        prevItem: this.state.modalPhotos[prev],
        prevIndex: prev,
      });
    } else {
      this.setState({ PrevItem: null, prevIndex: null });
    }

    if (next < this.state.modalPhotos.length) {
      this.setState({
        nextItem: this.state.modalPhotos[next],
        nextIndex: next,
      });
    } else {
      this.setState({ nextItem: null, prevIndex: null });
    }

    if (photoStatus === "SUCCESS") {
      if (this._isMounted) {
        this.setState({ currentIndexId: index });

        let origKey;

        if (this.state.photosetDetails.createdAt.S < this.state.versionTime) {
          origKey =
            "photosets/" +
            this.state.photosetDetails.userid.S +
            "/" +
            this.state.selectedPhotoset +
            "/" +
            i;
        } else {
          let filename = i.replace(/\.[^/.]+$/, "") + ".jpg";

          origKey =
            "photosets/" +
            this.state.photosetDetails.userid.S +
            "/" +
            this.state.selectedPhotoset +
            "/optimized/" +
            filename;
        }

        console.log(id);

        this.getImage(origKey, id, i);
      }
    } else {
      NotificationManager.warning(
        "Please check back later",
        "This photo is still processing",
        10000
      );
    }
  };

  getImage = (key, id, fileName) => {
    console.log("getting image...", key);
    this.setState({ currentFullImage: "" });

    if (this._isMounted) {
      let s3 = new this.props.AWS.S3();

      const s3params = {
        Bucket: "shieldshot-uploads",
        Key: key,
        Expires: 1000 * 60 * 60,
      };

      s3.getSignedUrlPromise("getObject", s3params, (err, url) => {
        if (err) console.log("Error on this image");
        else {
          console.log("url", url);

          this.setState({
            currentFullImage: url,
            currentImageId: id,
            currentFileName: fileName,
          });
        }
      });
    }
  };

  pagination = (i = this.state.activePage) => {
    let divisions = Math.ceil(this.state.count / 10);
    if (divisions < 1) divisions = 1;

    let pages = [];

    if (i < 1) i = 1;
    if (i > divisions) i = divisions;
    this.setState({ activePage: i });

    for (let number = 1; number <= divisions; number++) {
      pages.push(
        <Pagination.Item
          key={number}
          active={number === i}
          onClick={() => {
            this.setState({ activePage: number });
            this.pagination(number);
          }}
        >
          {number}
        </Pagination.Item>
      );
    }

    // select the pages to show
    const floor = (i - 1) * 10;
    const ceil = (i - 1) * 10 + 10;

    console.log("floor", floor);
    console.log("ceil", ceil);

    const photosets = this.state.photosets;
    const selectedPages = photosets.slice(floor, ceil);

    console.log(selectedPages);

    let sp = [];

    selectedPages.forEach((item) => {
      if (item.photosetName) {
        let sp_list = {
          photosetName: item.photosetName.S,
          createdAt: item.createdAt.S,
          id: item.id.S,
          photosetStatus: item.photosetStatus.S,
          userid: item.userid.S,
          photos: this.state.photolist[item.id.S],
        };

        sp.push(sp_list);
      } else {
        NotificationManager.info(
          JSON.stringify(item),
          "Issues detected",
          20000
        );
        console.log("Issue detected", item);
      }
    });

    console.log(sp);

    if (this._isMounted)
      this.setState({
        pages: pages,
        selectedPages: sp,
        loading: false,
      });
  };

  getAdminList = async () => {
    //admin/photosets/list
    try {
      const token = await this.props.token();

      const body = JSON.stringify({
        // type: "photosets",
      });

      const response = await fetch(
        "https://api.shieldshot.com/admin/photosets/list",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          body: body,
          method: "POST",
        }
      );

      const responseData = await response;
      if (responseData.status === 200) {
        const responseJson = await responseData.json();

        console.log(responseJson);
        let photos = [];
        const photolist = responseJson.photolist;

        photolist.forEach((item) => {
          const setid = item.setid.S;
          photos[setid] = item.photos;
        });

        this.setState({
          count: responseJson.photosetlist.length,
          photosets: responseJson.photosetlist,
          photolist: photos,
          tiers: responseJson.tierList,
        });

        this.pagination();
      } else {
        NotificationManager.warning(
          "Error " + responseData.status,
          "Something went wrong",
          10000
        );
        this.setState({ loading: false });
      }
    } catch (err) {
      console.log("Error getting photoset list from endpoint", err);
    }
  };

  showModal = (id) => {
    if (this._isMounted)
      this.setState({
        showModal: true,
        selectedPhotoset: id,
        modalPhotos: [],
        photosetDetails: [],
        modalLoading: true,
      });

    this.getPhotosetDetails();
  };

  getPhotosetDetails = async () => {
    try {
      const token = await this.props.token();

      const body = JSON.stringify({
        setId: this.state.selectedPhotoset,
      });

      const response = await fetch(
        "https://api.shieldshot.com/admin/photosets/details",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          body: body,
          method: "POST",
        }
      );

      const responseData = await response;
      if (responseData.status === 200) {
        const responseJson = await responseData.json();

        console.log(responseJson);
        this.setState({ photosetDetails: responseJson[0] });
        this.getPhotos(
          this.state.photosetDetails.userid.S,
          this.state.selectedPhotoset
        );
      } else {
        NotificationManager.warning(
          "Error " + responseData.status,
          "Something went wrong",
          10000
        );
      }
      this.setState({ modalLoading: false });
    } catch (err) {
      console.log("Error getting photoset list from endpoint", err);
      this.setState({ modalLoading: false });
    }
  };

  getPhotos = async (userid, setid) => {
    try {
      const token = await this.props.token();

      const body = JSON.stringify({
        setId: this.state.selectedPhotoset,
      });

      const response = await fetch(
        "https://api.shieldshot.com/admin/photosets/list/photos",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          body: body,
          method: "POST",
        }
      );

      const responseData = await response;
      if (responseData.status === 200) {
        const responseJson = await responseData.json();
        console.log(responseJson);

        const s3 = new this.props.AWS.S3();
        //let images = [];
        this.setState({ photoCount: responseJson.length });

        responseJson.forEach(async (item, index) => {
          let filename = item.fileName.S.replace(/\.[^/.]+$/, "") + ".jpg";
          let key = "photosets/" + userid + "/" + setid + "/thumbs/" + filename;

          let s3params = {
            Bucket: "shieldshot-uploads",
            Key: key,
            Expires: 1000 * 60 * 60,
          };

          let newImage = [];

          s3.getSignedUrlPromise("getObject", s3params, (err, url) => {
            if (err)
              NotificationManager.warning("Failed to get thumbs", "", 10000);
            else {
              newImage = {
                src: url,
                key: item.id.S,
                width: 0.5,
                height: 0.5,
                status: item.photoStatus.S,
                f: item.fileName.S,
                alt: "thumbs",
              };

              const newImages = this.state.modalPhotos.concat(newImage);

              if (this._isMounted) {
                this.setState({
                  modalPhotos: newImages,
                });
              }
            }
          });
        });
      } else {
        NotificationManager.warning(
          "Error " + responseData.status,
          "Something went wrong",
          10000
        );
      }
      this.setState({ modalLoading: false });
    } catch (err) {
      console.log("Error getting photoset list from endpoint", err);
      this.setState({ modalLoading: false });
    }
  };

  render() {
    if (this.state.loading) return <LoadingScreen />;

    if (!this.state.loading && this.state.selectedPages.length === 0)
      return (
        <div className="mt-5">
          <h1>Empty</h1>
        </div>
      );

    return (
      <div className=" p-5 text-left">
        <h1>Admin Dashboard</h1>

        <Modal
          size="lg"
          show={this.state.showModal}
          onHide={() => {
            this.setState({ showModal: false, modalMode: "thumbs" });
          }}
        >
          {this.state.modalLoading ? (
            <div className="text-center p-5">
              <Spinner animation="border" variant="primary" />
            </div>
          ) : (
            <React.Fragment>
              <Modal.Header closeButton>
                <Row className="w-100">
                  <Col>
                    <h5>{this.state.photosetDetails.photosetName.S}</h5>
                    <span
                      className="badge badge-pill ml-2"
                      style={{
                        backgroundColor: this.state.photosetDetails.TierColor.S,
                      }}
                    >
                      {this.state.photosetDetails.TierName.S}
                    </span>
                  </Col>
                  <Col>
                    {this.state.modalMode === "single" && (
                      <Button
                        className="btn-sm m-1"
                        onClick={() => this.setState({ modalMode: "thumbs" })}
                      >
                        Back to Thumbs
                      </Button>
                    )}
                  </Col>
                </Row>
              </Modal.Header>
              <Modal.Body>
                <p>Uploaded by {this.state.photosetDetails.userid.S}</p>
                <Row className="w-100">
                  {!this.state.modalPhotos ? (
                    <h3>No photos</h3>
                  ) : (
                    <React.Fragment>
                      {this.state.modalMode === "thumbs" ? (
                        <React.Fragment>
                          {this.state.modalPhotos.map((image, index) => (
                            <Col
                              lg={2}
                              md={3}
                              sm={4}
                              xs={6}
                              key={image.id + "-" + index}
                              className="p-1 m-0 rounded no-overflow col-thumb"
                            >
                              <Img
                                src={image.src}
                                loader={
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyItems: "center",
                                      justifyContent: "center",
                                    }}
                                  >
                                    <Spinner
                                      variant="primary"
                                      animation="border"
                                      className="mx-auto"
                                    />
                                  </div>
                                }
                                onClick={() =>
                                  this.openModal(
                                    image.f,
                                    image.key,
                                    index,
                                    image.status,
                                    index - 1,
                                    index + 1
                                  )
                                }
                                className="img-thumbnail pointer"
                              />

                              <div className="fileNamePopup text-secondary">
                                <small>
                                  <b>{image.f}</b>
                                </small>
                              </div>
                            </Col>
                          ))}
                        </React.Fragment>
                      ) : (
                        <React.Fragment>
                          {" "}
                          {this.state.prevItem && (
                            <div
                              className="prevButton"
                              onClick={() =>
                                this.openModal(
                                  this.state.prevItem.f,
                                  this.state.prevItem.key,
                                  this.state.prevIndex,
                                  this.state.prevItem.status,
                                  this.state.prevIndex - 1,
                                  this.state.prevIndex + 1
                                )
                              }
                            >
                              <FontAwesomeIcon icon={faArrowCircleLeft} />
                            </div>
                          )}
                          {this.state.nextItem && (
                            <div
                              className="nextButton"
                              onClick={() =>
                                this.openModal(
                                  this.state.nextItem.f,
                                  this.state.nextItem.key,
                                  this.state.nextIndex,
                                  this.state.nextItem.status,
                                  this.state.nextIndex - 1,
                                  this.state.nextIndex + 1
                                )
                              }
                            >
                              <FontAwesomeIcon icon={faArrowCircleRight} />
                            </div>
                          )}
                          {this.state.currentFullImage !== "" && (
                            <Img
                              className="w-100"
                              src={this.state.currentFullImage}
                              loader={
                                <div
                                  style={{
                                    display: "flex",
                                    justifyItems: "center",
                                    justifyContent: "center",
                                  }}
                                >
                                  <Spinner
                                    variant="primary"
                                    animation="border"
                                  />
                                </div>
                              }
                            />
                          )}
                        </React.Fragment>
                      )}
                    </React.Fragment>
                  )}
                </Row>
              </Modal.Body>
            </React.Fragment>
          )}
        </Modal>

        <Pagination>
          <Pagination.First onClick={() => this.pagination(1)} />
          <Pagination.Prev
            onClick={() => this.pagination(this.state.activePage - 1)}
          />
          {this.state.pages}{" "}
          <Pagination.Next
            onClick={() => this.pagination(this.state.activePage + 1)}
          />
          <Pagination.Last onClick={() => this.pagination(this.state.count)} />
        </Pagination>

        <Row>
          <Col md={12}>
            {this.state.selectedPages.map((item, index) => (
              <Row
                key={index}
                className="pointer bg-white rounded mb-2 p-2 shadow"
                onClick={() => this.showModal(item.id)}
              >
                <Col>
                  <Row>
                    <Col>
                      <h4>{item.photosetName}</h4>
                    </Col>
                    <Col md={4} className="text-right">
                      {Moment.unix(item.createdAt).format("LLL")}
                    </Col>
                  </Row>
                  <small>{item.id}</small>
                </Col>
              </Row>
            ))}
          </Col>
        </Row>
      </div>
    );
  }
}

export default AdminDashboard;
