import React, { Component } from "react";
import ListGroup from "react-bootstrap/ListGroup";
import history from "../../utils/history";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

class tierItem extends Component {
  render() {
    const redirect = "/tiers/" + this.props.data.rel_id;

    return (
      <ListGroup.Item
        action
        key={this.props.data.id}
        id={this.props.id}
        onClick={() => history.push(redirect)}
        className="text-left"
        style={{ borderLeft: "5px solid " + this.props.data.color }}
      >
        {/* <span className="font-weight-bold btn btn-sm ">
          {this.props.data.title}
        </span> */}

        <Row>
          <Col>
            <b>{this.props.data.nickname}</b>
          </Col>

          <>
            {this.props.data.inheritance.length > 0 && (
              <Col className="text-right">
                <span className="text-secondary small ml-3">
                  inherited by
                  {this.props.data.inheritance.map((item, index) => (
                    <small key={index}>
                      <span
                        className="badge- badge-pill badge-info mx-1"
                        variant="outline-secondary"
                      >
                        {item}
                      </span>
                    </small>
                  ))}
                </span>
              </Col>
            )}
          </>
        </Row>
      </ListGroup.Item>
    );
  }
}

export default tierItem;
