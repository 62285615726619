import React, { Component } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import FormControl from "react-bootstrap/FormControl";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
//import Dropdown from "react-bootstrap/Dropdown";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
class ControlBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ButtonLink: this.props.ButtonLink,
    };
  }

  render() {
    return (
      <div className="container mb-3 bg-white p-2 rounded shadow-sm">
        <Form inline>
          <Row className="w-100">
            <Col className="text-left pl-3" xs={12}>
              {this.props.Button1 && (
                <Link to={this.props.ButtonLink}>
                  <Button
                    variant="primary"
                    className="mr-2"
                    id="control-button-1"
                  >
                    <FontAwesomeIcon icon={faPlus} className="mr-2" />
                    {this.props.Button1}
                  </Button>
                </Link>
              )}

              {this.props.Button2 && (
                <Button
                  id="control-button-2"
                  variant="primary"
                  className="mr-2"
                  onClick={this.props.Button2Function}
                >
                  {this.props.Button2}
                </Button>
              )}
            </Col>
            <Col className="text-right p-0">
              <FormControl
                type="text"
                placeholder="Search"
                className="mr-sm-1 d-none"
              />
              {/* <Button variant="outline-primary">Search</Button> */}
            </Col>
          </Row>
        </Form>
      </div>
    );
  }
}

ControlBar.defaultProps = {
  Button2: "",
};

export default ControlBar;
