import React, { useState } from "react";
import { Switch, Route, Router } from "react-router-dom";

import Home from "./components/home";
import Tiers from "./components/tiers/tiers";
import NavBar from "./components/parts/navBar";

import Settings from "./components/settings/settings";

import Register from "./components/register";

import Activity from "./components/activity/activity";

import EmailList from "./components/emailList/emailList";

import NotFound from "./components/notFound";
import Dashboard from "./components/dashboard";
import PhotoSets from "./components/photosets/photoSets";
import LoadingScreen from "./components/parts/loadingScreen";
import PhotoSetDetails from "./components/photosets/photoSetDetails";

import PhotoSetAdd from "./components/photosets/photoSetAdd";
import TierAdd from "./components/tiers/tierAdd";
import TierDetails from "./components/tiers/tierDetails";

import Distribution from "./components/distribution/distribution";
import DistributionAdd from "./components/distribution/distributionAdd";
import DistributionDetails from "./components/distribution/distributionDetails";

import Feedback from "./components/parts/feedback";

import Store from "./components/store/store";

import PrivateRoute from "./components/PrivateRoute";
import { useAuth0 } from "./react-auth0-spa";
import history from "./utils/history";

import DashboardCenter from "./components/dashboard/dashboardCenter";

import AdminLayout from "./components/admin/adminLayout";

import Subscriptions from "./components/subscriptions";

import GettingStarted from "./components/parts/gettingStarted";

import Footer from "./components/footer";
import AWS from "aws-sdk";

import IdleTimer from "react-idle-timer";

import { Redirect } from "react-router-dom";

import "./App.css";

function App() {
  const {
    loading,
    user,
    getIdTokenClaims,
    getTokenSilently,
    isAuthenticated,
    logout,
    loginWithRedirect,
    loginWithPopup,
  } = useAuth0();

  const [active, setActive] = useState(true);

  let token = getTokenSilently;
  let isRegistered = false;

  let currentStorage = 0;
  let maxStorage = 0;
  let userType = "";

  //let idleTimer = null;
  let adminMode = false;
  let isAdmin = false;
  let showFooter = true;

  if (isAuthenticated) {
    getIdTokenClaims()
      .then((response) => {
        AWS.config.region = "us-east-1";
        AWS.config.credentials = new AWS.CognitoIdentityCredentials({
          IdentityPoolId: "us-east-1:06d8f03a-446c-42be-8e6f-4d602613e43b",
          Logins: {
            "shieldshot.auth0.com": response.__raw,
          },
        });
      })
      .catch((err) => {
        console.log("Appjs", "getClaims failed");
      });
  }

  if (loading) {
    return <LoadingScreen />;
  }

  if (isAuthenticated) {
    isRegistered = user["https://www.shieldshot.com/register"];
    currentStorage = user["https://www.shieldshot.com/current"].storage;
    maxStorage = user["https://www.shieldshot.com/limits"].storage;
    userType = user["https://www.shieldshot.com/accountType"];

    if (userType === "NTQzMzQyMzA2MTU3MzEzMTYzNzczZDNk") {
      isAdmin = true;
      if (localStorage.getItem("adminMode") === "true") adminMode = true;
    }

    if (!isRegistered) {
      history.push("/register");
      showFooter = false;
    }
  }

  const auth0vars = {
    loading: loading,
    user: user,
    isAuthenticated: isAuthenticated,
    loginWithRedirect: loginWithRedirect,
    logout: logout,
    token: token,
    loginWithPopup: loginWithPopup,
  };

  const onAction = (e) => {
    //console.log("user did something", e);
  };

  const onActive = (e) => {
    // console.log("active");
    if (!active) setActive(true);
  };

  const onIdle = (e) => {
    //console.log("idle");

    if (active) setActive(false);
  };

  if (!isAuthenticated) {
    return (
      <div className="App">
        <div className="content-wrapper">
          {/* <NavBar {...auth0vars} user={user} AWS={AWS} /> */}
          <Router history={history}>
            <Switch>
              <Route
                exact
                path="/"
                render={(props) => (
                  <Home
                    isAuthenticated={isAuthenticated}
                    loginWithRedirect={loginWithRedirect}
                  />
                )}
              />
              <Route component={NotFound} />
            </Switch>
          </Router>
        </div>
      </div>
    );
  }

  if (adminMode) {
    return (
      <React.Fragment>
        <div className="App">
          <IdleTimer
            // ref={(ref) => {
            //   idleTimer = ref;
            // }}
            element={document}
            onActive={onActive}
            onIdle={onIdle}
            onAction={onAction}
            debounce={250}
            timeout={1000 * 60}
          />

          <div className="content-wrapper h-100 min100vh">
            {/* <NavBar
              {...auth0vars}
              user={user}
              AWS={AWS}
              active={active}
            ></NavBar> */}

            <AdminLayout {...auth0vars} token={token} AWS={AWS} />
          </div>
        </div>
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      <div className="App App-with-footer">
        <IdleTimer
          // ref={(ref) => {
          //   idleTimer = ref;
          // }}
          element={document}
          onActive={onActive}
          onIdle={onIdle}
          onAction={onAction}
          debounce={250}
          timeout={1000 * 60}
        />

        <div className="content-wrapper">
          <NavBar
            {...auth0vars}
            user={user}
            AWS={AWS}
            active={active}
            isRegistered={isRegistered}
          ></NavBar>

          <Router history={history}>
            <Switch>
              <PrivateRoute
                path="/subscriptions"
                render={(props) => (
                  <Subscriptions {...props} token={token} user={user} />
                )}
              />

              <PrivateRoute
                path="/feedback"
                render={(props) => <Feedback {...props} token={token} />}
              />

              <PrivateRoute
                path="/register"
                render={(props) => (
                  <Register
                    {...props}
                    token={token}
                    isRegistered={isRegistered}
                    AWS={AWS}
                    user={user}
                  />
                )}
              />

              <PrivateRoute
                exact
                path="/"
                render={(props) => <Redirect to="/dashboard" />}
              />
              <PrivateRoute
                exact
                path="/dashboard"
                render={(props) => (
                  <Dashboard
                    currentStorage={currentStorage}
                    maxStorage={maxStorage}
                  >
                    <DashboardCenter
                      {...props}
                      token={token}
                      user={user}
                      isAdmin={isAdmin}
                    />
                  </Dashboard>
                )}
              />

              <PrivateRoute
                exact
                path="/photosets"
                render={(props) => (
                  <Dashboard
                    currentStorage={currentStorage}
                    maxStorage={maxStorage}
                  >
                    <PhotoSets {...props} token={token} />
                  </Dashboard>
                )}
              />

              <PrivateRoute
                exact
                strict
                path="/photosets/:id"
                render={(props) => (
                  <Dashboard
                    currentStorage={currentStorage}
                    maxStorage={maxStorage}
                  >
                    <PhotoSetDetails
                      {...props}
                      user={user}
                      token={token}
                      AWS={AWS}
                      active={active}
                    />
                  </Dashboard>
                )}
              />
              <PrivateRoute
                exact
                path="/photosets/add"
                render={(props) => (
                  <Dashboard
                    currentStorage={currentStorage}
                    maxStorage={maxStorage}
                  >
                    <PhotoSetAdd {...props} token={token} />
                  </Dashboard>
                )}
              />

              <PrivateRoute
                exact
                path="/tiers"
                render={(props) => (
                  <Dashboard
                    currentStorage={currentStorage}
                    maxStorage={maxStorage}
                  >
                    <Tiers {...props} token={token} />
                  </Dashboard>
                )}
              />

              <PrivateRoute
                exact
                path="/tiers/add/"
                render={(props) => (
                  <Dashboard
                    currentStorage={currentStorage}
                    maxStorage={maxStorage}
                  >
                    <TierAdd {...props} user={user} token={token} AWS={AWS} />
                  </Dashboard>
                )}
              />

              <PrivateRoute
                exact
                strict
                path="/tiers/:id"
                render={(props) => (
                  <Dashboard
                    currentStorage={currentStorage}
                    maxStorage={maxStorage}
                  >
                    <TierDetails
                      {...props}
                      user={user}
                      AWS={AWS}
                      token={token}
                    />
                  </Dashboard>
                )}
              />

              <PrivateRoute
                path="/activity"
                render={(props) => (
                  <Dashboard
                    currentStorage={currentStorage}
                    maxStorage={maxStorage}
                  >
                    <Activity {...props} token={token} />
                  </Dashboard>
                )}
              />

              <PrivateRoute
                exact
                path="/emailList"
                render={(props) => (
                  <Dashboard
                    currentStorage={currentStorage}
                    maxStorage={maxStorage}
                  >
                    <EmailList {...props} token={token} />
                  </Dashboard>
                )}
              />

              {/* <PrivateRoute path="/payments" component={Dashboard} /> */}
              <PrivateRoute
                path="/distribution"
                exact
                render={(props) => (
                  <Dashboard
                    currentStorage={currentStorage}
                    maxStorage={maxStorage}
                  >
                    <Distribution {...props} token={token} active={active} />
                  </Dashboard>
                )}
              />
              <PrivateRoute
                path="/distribution/add"
                exact
                render={(props) => (
                  <Dashboard
                    currentStorage={currentStorage}
                    maxStorage={maxStorage}
                  >
                    <DistributionAdd {...props} token={token} />
                  </Dashboard>
                )}
              />

              <PrivateRoute
                path="/distribution/:id"
                exact
                strict
                render={(props) => (
                  <Dashboard
                    currentStorage={currentStorage}
                    maxStorage={maxStorage}
                  >
                    <DistributionDetails
                      {...props}
                      token={token}
                      active={active}
                      user={user}
                    />
                  </Dashboard>
                )}
              />

              <PrivateRoute
                path="/settings"
                render={(props) => (
                  <Dashboard
                    currentStorage={currentStorage}
                    maxStorage={maxStorage}
                  >
                    <Settings {...props} token={token} AWS={AWS} user={user} />
                  </Dashboard>
                )}
              />

              <PrivateRoute
                exact
                path="/getting-started"
                render={(props) => (
                  <Dashboard
                    currentStorage={currentStorage}
                    maxStorage={maxStorage}
                  >
                    <GettingStarted />
                  </Dashboard>
                )}
              />

              <PrivateRoute
                path="/store"
                render={(props) => (
                  <Dashboard
                    currentStorage={currentStorage}
                    maxStorage={maxStorage}
                  >
                    <Store token={token} user={user} isAdmin={isAdmin} />
                  </Dashboard>
                )}
              />

              <Route path="/404" component={NotFound} />

              <Route component={NotFound} />
            </Switch>
          </Router>
        </div>
      </div>
      <Footer showFooter={showFooter} />
    </React.Fragment>
  );
}

export default App;
