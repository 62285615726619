import React, { Component, createRef } from "react";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
import { NotificationManager } from "react-notifications";
import Overlay from "react-bootstrap/Overlay";
import Popover from "react-bootstrap/Popover";
import Notifications from "./Notifications";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faImages, faBell, faBed } from "@fortawesome/free-solid-svg-icons";

import { Link, Router } from "react-router-dom";
import history from "../../utils/history";

import HeaderImage from "../../media/shieldshot_header.png";

import AWS from "aws-sdk";

//import IdleTimer from "react-idle-timer";

//import SockJS from "sockjs-client";

class NavBar extends Component {
  intervalID = null;
  deb = 0;

  constructor(props) {
    super(props);

    this.state = {
      sidebars: [
        { id: "Dashboard", href: "dashboard", active: true },
        { id: "Photosets", href: "photosets", active: false },
        { id: "Tiers", href: "tiers", active: false },
        { id: "E-mail List", href: "emailList", active: false },
        { id: "Activity Logs", href: "activity", active: false },
        { id: "Distribution", href: "distribution", active: false },
        { id: "Settings", href: "settings", active: false },
      ],
      link: this.props.isRegistered ? "/dashboard" : "/register",
      uploadPhotoToAnalyze: false,
      navExpanded: false,
      showNotifications: false,
      notificationNumber: 0,
      notificationLoading: true,
      totalNotifications: -1,
      notifications: [],
      target: null,
      ref: createRef(),
      notificationColor: "text-danger",
    };
  }

  componentDidMount = () => {
    if (localStorage.getItem("notifications")) {
      this.setState({
        notifications: JSON.parse(localStorage.getItem("notifications")),
        notificationNumber: parseInt(
          localStorage.getItem("notificationNumber")
        ),
        notificationColor: localStorage.getItem("notificationColor"),
        totalNotifications: parseInt(
          localStorage.getItem("totalNotifications")
        ),
        notificationLoading: false,
      });
    }

    this.getNotifications();
    this.intervalID = setInterval(this.getNotifications, 10000);
  };

  componentWillUnmount = () => {
    clearInterval(this.intervalID);
  };

  getNotifications = async () => {
    if (this.props.active) {
      try {
        const token = await this.props.token();

        // const body = JSON.stringify({ type: "notifications" });

        const response = await fetch(
          "https://api.shieldshot.com/notifications/list",
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
            method: "GET",
          }
        );

        const responseData = await response;

        // if (
        //   this.state.totalNotifications !== responseJson.Count ||
        //   isRead !== 0
        // ) {

        // }

        if (responseData.status === 200) {
          const responseJson = await responseData.json();

          let isRead = 0;
          responseJson.Items.forEach((item) => {
            if (!item.isRead.BOOL) {
              isRead++;
            }
          });

          let notificationColor = "text-danger";

          if (isRead === 0) notificationColor = "text-dark";

          localStorage.setItem("notifications", JSON.stringify(responseJson));
          localStorage.setItem("notificationNumber", isRead);
          localStorage.setItem("notificationColor", notificationColor);
          localStorage.setItem("totalNotifications", responseJson.Count);

          this.setState({
            notifications: responseJson,
            notificationNumber: isRead,
            notificationColor: notificationColor,
            notificationLoading: false,
            totalNotifications: responseJson.Count,
          });
        } else {
          NotificationManager.warning(
            "Error: " + responseData.status + ". Please try again.",
            "Failed to upload!",
            10000
          );
          console.log("failure on endpoint", responseData.status);
        }
      } catch (err) {
        NotificationManager.warning(
          "Error: " + err + ". Please try again.",
          "Failed to get Notifications",
          10000
        );
        console.log(err);
      }
    } else {
      // console.log("user is idle");
    }
  };

  hideNotifications = () => {
    this.deb = 10;
    this.setState({
      showNotifications: false,
      notificationColor: "text-dark",
      notificationNumber: 0,
    });

    setTimeout(() => (this.deb = 0), 100);
  };

  handleNotification = (e) => {
    this.setIsReadNotifications();

    if (this.deb === 0)
      this.setState({
        showNotifications: !this.state.showNotifications,
        notificationColor: "text-dark",
        target: e.target,
        notificationNumber: 0,
      });
  };

  locallyRead = (i) => {
    let notifs = this.state.notifications;
    notifs.Items[i].isRead.BOOL = true;
    this.setState({ notifications: notifs });
  };

  setIsReadNotifications = async () => {
    console.log("isRead start");
    try {
      const token = await this.props.token();

      const body = JSON.stringify({ type: "isRead", data: "true" });

      const response = await fetch(
        "https://api.shieldshot.com/notifications/update",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          body: body,
          method: "POST",
        }
      );

      const responseData = await response;

      if (responseData.status === 200) {
        console.log("done");
        // const responseJson = await responseData.json();
        //NotificationManager.success("Is read", "Successful", 10000);
      } else {
        // NotificationManager.warning(
        //   "Error: " + responseData.status + ". Please try again.",
        //   "Failed!",
        //   10000
        // );
        console.log("failure on endpoint", responseData.status);
      }
    } catch (err) {
      // NotificationManager.warning(
      //   "Error: " + err + ". Please try again.",
      //   "Failed to get Notifications",
      //   10000
      // );
      console.log(err);
    }
  };

  setNavExpanded = (expanded) => {
    this.setState({ navExpanded: expanded });
  };

  closeNav = () => {
    this.setState({ navExpanded: false });
  };

  analyze = async (ev) => {
    let files = ev.target.files;

    this.setState({ uploadPhotoToAnalyze: true });

    let file = ev.target.files[0];
    let reader = new FileReader();
    let name = ev.target.files[0].name;
    reader.readAsDataURL(files[0]);

    reader.onload = (ev) => {
      let s3 = new AWS.S3();

      let s3params = {
        Body: file,
        Bucket: "shieldshot-temp",
        Key: "analyze/" + this.props.user.sub + "/" + name,
        ServerSideEncryption: "AES256",
      };

      //console.log("uploading");

      s3.putObject(s3params, async (err, data) => {
        if (err) {
          console.log(err, err.stack);
          this.setState({ uploadPhotoToAnalyze: false });
        } else {
          console.log("File successfully uploaded to S3"); // successful response

          try {
            const token = await this.props.token();

            const body = JSON.stringify({ fileName: name });

            console.log(name);

            const response = await fetch(
              "https://api.shieldshot.com/analyze/add",
              {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
                body: body,
                method: "POST",
              }
            );

            const responseData = await response;

            if (responseData.status === 200) {
              //const responseJson = await responseData.json();
              NotificationManager.success(
                "Check on your activity logs in a few minutes for the result",
                "Upload Successful",
                10000
              );
              //  console.log(responseJson);
            } else {
              NotificationManager.warning(
                "Error: " + responseData.status + ". Please try again.",
                "Failed to upload!",
                10000
              );
              console.log("failure on endpoint", responseData.status);
            }
            this.setState({ uploadPhotoToAnalyze: false });
          } catch (err) {
            console.log("error on add endpoint", err);
            NotificationManager.warning(
              "Error: " + err + ". Please try again.",
              "Failed to upload!",
              10000
            );
            this.setState({ uploadPhotoToAnalyze: false });
          }
        }
      });
    };
    ev.target.value = null;
  };

  render() {
    return (
      <Navbar
        bg="light"
        expand="lg"
        className="fixed-top"
        onToggle={this.setNavExpanded}
        expanded={this.state.navExpanded}
        id="navbar"
      >
        <Router history={history}>
          <Link to={this.state.link}>
            <Navbar.Brand className="mr-auto" id="navLogo">
              <img src={HeaderImage} style={{ maxHeight: "25px" }} alt="logo" />
            </Navbar.Brand>
          </Link>
        </Router>

        <input
          id="analyzeButtonNavBar"
          type="file"
          name="file"
          onChange={this.analyze}
          className="hidden"
        />

        <Form className="force-form  mr-5 mr-lg-0 pr-4 pr-lg-0">
          {!this.state.notificationLoading && this.props.isAuthenticated ? (
            <React.Fragment>
              <Button
                ref={this.state.ref}
                variant="white"
                id="bellButton"
                className="pill-button"
                onClick={this.handleNotification}
              >
                <FontAwesomeIcon
                  icon={this.props.active ? faBell : faBed}
                  id="bell"
                  className={this.state.notificationColor}
                  style={{ pointerEvents: "none" }}
                />
                {this.state.notificationNumber ? (
                  <sup
                    style={{
                      marginLeft: "-3px",
                      marginRight: "-5px",
                      pointerEvents: "none",
                    }}
                  >
                    <small>
                      <span className="badge-pill badge-dark font-weight-bold">
                        {this.state.notificationNumber}
                      </span>
                    </small>
                  </sup>
                ) : (
                  ""
                )}
              </Button>

              <Overlay
                show={this.state.showNotifications}
                placement="bottom"
                target={this.state.target}
                container={this.state.ref.current}
                offset="10"
              >
                <Popover
                  id="popover-contained"
                  className="fancyScroll shadow"
                  ref={this.setWrapperRef}
                  style={{
                    minWidth: "350px",
                    maxWidth: "400px",
                    width: "100%",
                    maxHeight: "400px",
                    overflowY: "scroll",
                  }}
                >
                  <Notifications
                    setHidden={this.hideNotifications}
                    notifications={this.state.notifications}
                    locallyRead={this.locallyRead}
                  />
                </Popover>
              </Overlay>
            </React.Fragment>
          ) : (
            <Spinner size="sm" variant="primary" animation="border" />
          )}

          {this.props.isAuthenticated && (
            <React.Fragment>
              {this.props.user["https://www.shieldshot.com/register"] ? (
                <React.Fragment>
                  {!this.state.uploadPhotoToAnalyze ? (
                    <Button
                      id="analyzeButton"
                      className="d-none  d-lg-inline d-xl-inline"
                      variant="outline-primary mr-1 ml-2 btn-sm pill-button"
                      onClick={(ev) => {
                        document.getElementById("analyzeButtonNavBar").click();
                      }}
                    >
                      <FontAwesomeIcon icon={faImages} className="mr-1" />
                      Analyze a Photo
                    </Button>
                  ) : (
                    <Button
                      id="analyzeLoadingButton"
                      className="d-none  d-lg-inline d-xl-inline"
                      variant="primary mr-1 btn-sm"
                      disabled
                    >
                      <Spinner
                        as="span"
                        animation="grow"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                        className="mr-2"
                      />
                      Submitting...
                    </Button>
                  )}
                </React.Fragment>
              ) : (
                ""
              )}

              <Button
                variant="danger mr-1 ml-2 btn-sm pill-button"
                className="d-none  d-lg-inline d-xl-inline"
                id="logout-button"
                onClick={() => {
                  localStorage.clear();
                  this.props.logout();
                }}
              >
                Log out
              </Button>
            </React.Fragment>
          )}
        </Form>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />

        <Navbar.Collapse id="basic-navbar-nav">
          <Nav>
            {this.props.isAuthenticated && (
              <div className="d-block d-lg-none d-xl-none">
                <Router history={history}>
                  {this.state.sidebars.map((pills) => (
                    <Link
                      to={"/" + pills.href}
                      key={pills.id}
                      id={pills.id}
                      style={{ textDecoration: "none" }}
                      onClick={this.closeNav}
                    >
                      <div className="w-100 p-3  hover-grey">{pills.id}</div>
                    </Link>
                  ))}
                </Router>
              </div>
            )}
          </Nav>

          {this.props.isAuthenticated && (
            <React.Fragment>
              {this.props.user["https://www.shieldshot.com/register"] ? (
                <React.Fragment>
                  {!this.state.uploadPhotoToAnalyze ? (
                    <Button
                      className="d-inline d-lg-none"
                      variant="outline-primary mr-1 ml-2 btn-sm pill-button"
                      onClick={(ev) => {
                        document.getElementById("analyzeButtonNavBar").click();
                      }}
                    >
                      <FontAwesomeIcon icon={faImages} className="mr-1" />
                      Analyze a Photo
                    </Button>
                  ) : (
                    <Button
                      id="analyzeLoadingButton"
                      className="d-inline d-lg-none"
                      variant="primary mr-1 btn-sm"
                      disabled
                    >
                      <Spinner
                        as="span"
                        animation="grow"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                        className="mr-2"
                      />
                      Submitting...
                    </Button>
                  )}
                </React.Fragment>
              ) : (
                ""
              )}
              <br />

              <Button
                variant="danger mr-1 mt-2 btn-sm pill-button"
                className="d-inline d-lg-none"
                onClick={() => {
                  this.props.logout();
                  localStorage.clear();
                }}
              >
                Log out
              </Button>
            </React.Fragment>
          )}
        </Navbar.Collapse>
      </Navbar>
    );
  }
}

export default NavBar;
