import React, { Component } from "react";
import Alert from "react-bootstrap/Alert";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import renderHTML from "react-render-html";
import { NotificationManager } from "react-notifications";
import Spinner from "react-bootstrap/Spinner";
import ListGroup from "react-bootstrap/ListGroup";
import history from "../../utils/history";
import Moment from "moment";
import MomentLocalizer from "react-widgets-moment";
import Button from "react-bootstrap/Button";
import { Link } from "react-router-dom";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";

Moment.locale("en");
MomentLocalizer();

class DashboardCenter extends Component {
  _isMounted = false;
  state = {
    alertMessage: "",
    alertType: "",
    showAlert: false,
    activityList: [],
    distributionList: [],
    photosetList: [],
    tierList: [],
    isLoading: true,
    dismissAlert: false,
    tourTime: localStorage.getItem("tour"),
  };

  componentDidMount() {
    this._isMounted = true;
    this.getData();

    if (this.props.location) {
      if (this.props.location.state) {
        this.setState({
          alertMessage: this.props.location.state.alertMessage,
          alertType: this.props.location.state.alertType,
          showAlert: true,
        });
      }
    }

    if (localStorage.getItem("dashboard")) {
      const dashboard = JSON.parse(localStorage.getItem("dashboard"));
      const activityList = dashboard.activitylist;
      const distributionList = dashboard.distributionlist;
      const photosetList = dashboard.photosetlist;

      this.setState({
        activityList: activityList,
        distributionList: distributionList,
        photosetList: photosetList,
        isLoading: false,
        tierList: JSON.parse(localStorage.getItem("tierList")),
      });
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  getData = async () => {
    try {
      const token = await this.props.token();

      const response = await fetch(
        "https://api.shieldshot.com/dashboard/list",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          method: "GET",
        }
      );

      const responseData = await response;
      const responseJson = await responseData.json();

      if (responseData.status === 200) {
        // console.log(responseJson);

        let tierData = {
          "0": {
            color: "#EEEEEE",
            parentTier: "0",
            rel_id: 0,
            tierName: "No Tier",
          },
        };

        responseJson.tierlist.forEach((item) => {
          tierData[String(item.rel_id.N)] = {
            color: item.color.S,
            parentTier: item.parentTier.S,
            rel_id: item.rel_id.N,
            tierName: item.tierName.S,
          };
        });

        responseJson.photosetlist.forEach((item) => {
          item.rel_id.N = Number(item.rel_id.N);
        });

        responseJson.distributionlist.forEach((item) => {
          if (item.distributionStatus.S === "SCHEDULED") {
            item.startTime.N =
              " on " +
              Moment.unix(item.startTime.N).format("YYYY/MM/DD hh:mm a");
            item.badge = "badge-warning";
          } else {
            item.badge = "badge-success";
            item.startTime.N = "";
          }
        });

        localStorage.setItem("dashboard", JSON.stringify(responseJson));
        localStorage.setItem("tierList", JSON.stringify(tierData));

        if (this._isMounted)
          this.setState({
            activityList: responseJson.activitylist,
            distributionList: responseJson.distributionlist,
            photosetList: responseJson.photosetlist,
            tierList: tierData,
            isLoading: false,
          });

        //NotificationManager.success("Success", "Dashboard Endpoint", 10000);
      } else {
        NotificationManager.error(
          "Error " + responseData.status,
          "Dashboard Endpoint",
          10000
        );
      }
    } catch (err) {
      //alert("syntax error: " + err);
      NotificationManager.error(
        "Looks like something went wrong." + err,
        "Uh oh",
        10000
      );
      if (this._isMounted) this.setState({ isLoading: false });
    }
  };

  render() {
    if (this.state.isLoading)
      return <Spinner className="mt-4" animation="border" variant="primary" />;

    return (
      <div className="pt-0 pt-md-2">
        {this.state.showAlert && (
          <Alert className="text-left" variant={this.state.alertType}>
            {this.state.alertMessage}
          </Alert>
        )}

        {!this.props.user.email_verified && (
          <React.Fragment>
            {!this.state.dismissAlert && (
              <Alert
                className="text-left"
                variant="warning"
                dismissible
                onClose={() => this.setState({ dismissAlert: true })}
              >
                Your E-mail is not yet verified. Please verify it soon to
                prevent locking yourself out if ever you forget your password.
                <br />
              </Alert>
            )}
          </React.Fragment>
        )}

        <div className="mb-3 text-right pr-3 mx-3">
          {this.props.isAdmin && (
            <Button
              variant="danger"
              className="m-1"
              onClick={() => {
                localStorage.setItem("adminMode", "true");
                window.location.reload(false);
              }}
            >
              Toggle Admin Mode
            </Button>
          )}

          <DropdownButton
            as={ButtonGroup}
            variant="primary"
            title="Quick Add"
            className="m-1"
          >
            <Dropdown.Item as={Link} eventKey="1" to="/tiers/add/">
              Tier
            </Dropdown.Item>

            <Dropdown.Item as={Link} to="/photosets/add/" eventKey="2">
              Photoset
            </Dropdown.Item>

            <Dropdown.Item as={Link} to="/distribution/add/" eventKey="3">
              Distribution
            </Dropdown.Item>
          </DropdownButton>
        </div>

        <div className="rounded px-3 pb-3 pt-1 pt-md-3">
          <Row>
            <Col md={6}>
              <div className="text-left">
                <h6 className="mx-3 mb-3 mt-0 mt-md-3 text-secondary">
                  Distributions
                </h6>

                {this.state.distributionList.length > 0 ? (
                  <div className="mb-2 bg-white rounded  shadow-sm">
                    <small>
                      <ListGroup variant="flush">
                        {this.state.distributionList.map((item, index) => (
                          //
                          <ListGroup.Item
                            action
                            key={index}
                            onClick={() =>
                              history.push(
                                "/distribution/" + item.distributionId.S
                              )
                            }
                          >
                            <Row>
                              <Col className="text-left">
                                <b>{item.subject.S}</b>
                                <small>
                                  <span className="float-right">
                                    {Moment.unix(item.createdAt.S).format(
                                      "LLL"
                                    )}
                                  </span>
                                </small>
                              </Col>
                            </Row>
                            <Row>
                              <Col className="text-left">
                                <span className={"badge " + item.badge}>
                                  {item.distributionStatus.S}
                                </span>

                                {item.startTime.N}
                              </Col>
                            </Row>
                          </ListGroup.Item>
                        ))}
                      </ListGroup>
                    </small>
                  </div>
                ) : (
                  <small className="ml-3 text-secondary">
                    <i>No Distributions yet.</i>
                  </small>
                )}
              </div>
            </Col>
            <Col md={6} className="text-left">
              <h6 className="m-3 text-secondary">Photosets</h6>

              {this.state.photosetList.length > 0 ? (
                <small>
                  <div className="text-left bg-white rounded shadow-sm mr-3">
                    <ListGroup variant="flush" key="photosetListGroup">
                      {this.state.photosetList.map((photoset) => (
                        <ListGroup.Item
                          action
                          key={photoset.rel_id.N}
                          onClick={() =>
                            history.push("/photosets/" + photoset.rel_id.N)
                          }
                          className="text-left border-bottom"
                        >
                          <Row>
                            <Col lg={6}>
                              <Row>
                                <Col className="font-weight-bold">
                                  {photoset.photosetName.S}
                                </Col>
                              </Row>
                              <Row>
                                <Col>
                                  <span
                                    className="badge badge-pill"
                                    style={{
                                      backgroundColor: this.state.tierList[
                                        photoset.tier.S
                                      ].color,
                                      color: "#FFFFFF",
                                    }}
                                  >
                                    {
                                      this.state.tierList[photoset.tier.S]
                                        .tierName
                                    }
                                  </span>
                                </Col>
                              </Row>
                            </Col>
                            <Col className="text-right">
                              <small>
                                {Moment.unix(photoset.createdAt.S).format(
                                  "LLL"
                                )}
                                <br></br>
                              </small>
                            </Col>
                          </Row>
                        </ListGroup.Item>
                      ))}
                    </ListGroup>
                  </div>
                </small>
              ) : (
                <small className="ml-3  text-secondary">
                  <i>No photosets yet.</i>
                </small>
              )}
            </Col>
          </Row>
          <Row>
            <Col className="text-left">
              <h6 className="m-3 text-secondary">Activities</h6>

              {this.state.activityList.length > 0 ? (
                <div
                  className="text-left  rounded fancyScroll"
                  style={{ maxHeight: "450px", overflowY: "scroll" }}
                >
                  <ListGroup variant="flush" className="mr-2">
                    {this.state.activityList.map((item, index) => (
                      <ListGroup.Item
                        key={index}
                        className="bg-white  shadow-sm"
                      >
                        <small>
                          <Row className="pt-2">
                            <Col className="font-weight-bold  ">
                              {item.activity.S}
                            </Col>

                            <Col md={8} className="text-secondary text-right  ">
                              {Moment.unix(item.createdAt.S).format("LLL") ===
                              "Invalid date"
                                ? item.createdAt.S
                                : Moment.unix(item.createdAt.S).format("LLL")}
                            </Col>
                          </Row>
                          <Row className="pb-2">
                            <Col className="text-secondary">
                              {renderHTML(item.remarks.S)}
                            </Col>
                          </Row>
                        </small>
                      </ListGroup.Item>
                    ))}
                  </ListGroup>
                </div>
              ) : (
                <small className="ml-3  text-secondary">
                  <i>No activity yet.</i>
                </small>
              )}
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

export default DashboardCenter;
