import React, { Component } from "react";
// import Row from "react-bootstrap/Row";
// import Col from "react-bootstrap/Col";

class StoreAdd extends Component {
  render() {
    return (
      <div>
        <h3>Add Store Item</h3>
      </div>
    );
  }
}

export default StoreAdd;
