import React, { Component } from "react";
import ListGroup from "react-bootstrap/ListGroup";
import history from "../../utils/history";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Moment from "moment";
import MomentLocalizer from "react-widgets-moment";

Moment.locale("en");
MomentLocalizer();

class PhotoSetListItem extends Component {
  render() {
    const redirect = "/photosets/" + this.props.data.rel_id;

    if (this.props.data.tier === undefined) this.props.data.tier = "No Tier";

    return (
      <ListGroup.Item
        action
        key={this.props.data.id}
        id={this.props.id}
        onClick={() => history.push(redirect)}
        className="text-left border-bottom"
      >
        <Row>
          <Col lg={3}>
            <Row>
              <Col className="font-weight-bold"> {this.props.data.title}</Col>
            </Row>
            <Row>
              <Col>
                {" "}
                <span
                  className="badge badge-pill"
                  style={{
                    backgroundColor: this.props.data.tierColor,
                    color: this.props.data.fontColor,
                  }}
                >
                  {this.props.data.tierName}
                </span>
              </Col>
            </Row>
          </Col>
          <Col className="text-right">
            <small>
              Posted on{" "}
              {Moment.unix(this.props.data.date).format("LL") === "Invalid date"
                ? this.props.data.date
                : Moment.unix(this.props.data.date).format("LL")}
              <br />
            </small>
          </Col>
        </Row>
      </ListGroup.Item>
    );
  }
}

export default PhotoSetListItem;
