import React, { Component } from "react";
import { NotificationManager } from "react-notifications";
import Pagination from "react-bootstrap/Pagination";

import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Spinner from "react-bootstrap/Spinner";
import Modal from "react-bootstrap/Modal";

class AdminUserPhotosetsModal extends Component {
  _isMounted = false;

  state = {
    loading: false,
    activePage: 1,
    pages: [],
    count: this.props.count,
    photosets: this.props.data.photosetlist,
    tiers: this.props.data.tierList,
    selectedPages: [],
    photolist: this.props.photos,
    showModal: false,
    selectedPhotoset: 0,
    modalLoading: true,
    modalPhotos: [],
    photosetDetails: [],
    photoCount: 0,
  };

  componentDidMount() {
    this._isMounted = true;
    this.pagination();
    //this.getAdminList();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  pagination = (i = this.state.activePage) => {
    const x = 20;
    let divisions = Math.ceil(this.state.count / x);
    if (divisions < 1) divisions = 1;

    let pages = [];

    if (i < 1) i = 1;
    if (i > divisions) i = divisions;
    this.setState({ activePage: i });

    for (let number = 1; number <= divisions; number++) {
      pages.push(
        <Pagination.Item
          key={number}
          active={number === i}
          onClick={() => {
            this.setState({ activePage: number });
            this.pagination(number);
          }}
        >
          {number}
        </Pagination.Item>
      );
    }

    // select the pages to show
    const floor = (i - 1) * x;
    const ceil = (i - 1) * x + x;

    console.log("floor", floor);
    console.log("ceil", ceil);

    const photosets = this.state.photosets;
    const selectedPages = photosets.slice(floor, ceil);

    console.log(selectedPages);

    let sp = [];

    selectedPages.forEach((item) => {
      let sp_list = {
        photosetName: item.photosetName.S,
        createdAt: item.createdAt.S,
        id: item.id.S,
        photosetStatus: item.photosetStatus.S,
        userid: item.userid.S,
        photos: this.state.photolist[item.id.S],
      };

      sp.push(sp_list);
    });

    console.log(sp);

    if (this._isMounted)
      this.setState({
        pages: pages,
        selectedPages: sp,
        loading: false,
      });
  };

  getAdminList = async () => {
    //admin/photosets/list
    try {
      const token = await this.props.token();

      const body = JSON.stringify({
        // type: "photosets",
      });

      const response = await fetch(
        "https://api.shieldshot.com/admin/photosets/list",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          body: body,
          method: "POST",
        }
      );

      const responseData = await response;
      if (responseData.status === 200) {
        const responseJson = await responseData.json();

        console.log(responseJson);
        let photos = [];
        const photolist = responseJson.photolist;

        photolist.forEach((item) => {
          const setid = item.setid.S;
          photos[setid] = item.photos;
        });

        this.setState({
          count: responseJson.photosetlist.length,
          photosets: responseJson.photosetlist,
          photolist: photos,
          tiers: responseJson.tierList,
        });

        this.pagination();
      } else {
        NotificationManager.warning(
          "Error " + responseData.status,
          "Something went wrong",
          10000
        );
        this.setState({ loading: false });
      }
    } catch (err) {
      console.log("Error getting photoset list from endpoint", err);
    }
  };

  showModal = (id) => {
    if (this._isMounted)
      this.setState({
        showModal: true,
        selectedPhotoset: id,
        modalPhotos: [],
        photosetDetails: [],
        modalLoading: true,
      });

    this.getPhotosetDetails();
  };

  getPhotos = async (userid, setid) => {
    try {
      const token = await this.props.token();

      const body = JSON.stringify({
        setId: this.state.selectedPhotoset,
      });

      const response = await fetch(
        "https://api.shieldshot.com/admin/photosets/list/photos",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          body: body,
          method: "POST",
        }
      );

      const responseData = await response;
      if (responseData.status === 200) {
        const responseJson = await responseData.json();
        console.log(responseJson);

        const s3 = new this.props.AWS.S3();

        this.setState({ photoCount: responseJson.length });

        responseJson.forEach(async (item, index) => {
          let filename = item.fileName.S.replace(/\.[^/.]+$/, "") + ".jpg";
          let key = "photosets/" + userid + "/" + setid + "/thumbs/" + filename;

          let s3params = {
            Bucket: "shieldshot-uploads",
            Key: key,
            Expires: 100,
          };

          let newImage = [];

          s3.getSignedUrlPromise("getObject", s3params, (err, url) => {
            if (err)
              NotificationManager.warning("Failed to get thumbs", "", 10000);
            else {
              newImage = {
                src: url,
                key: item.id.S,
                width: 0.5,
                height: 0.5,
                status: item.photoStatus.S,
                f: item.fileName.S,
                alt: "thumbs",
              };

              const newImages = this.state.modalPhotos.concat(newImage);

              if (this._isMounted) {
                this.setState({
                  modalPhotos: newImages,
                });
              }
            }
          });
        });
      } else {
        NotificationManager.warning(
          "Error " + responseData.status,
          "Something went wrong",
          10000
        );
      }
      this.setState({ modalLoading: false });
    } catch (err) {
      console.log("Error getting photoset list from endpoint", err);
      this.setState({ modalLoading: false });
    }
  };

  render() {
    if (this.state.loading)
      return <Spinner animation="border" variant="primary" />;

    if (!this.state.loading && this.state.selectedPages.length === 0)
      return (
        <div className="mt-5">
          <h1>Empty</h1>
        </div>
      );

    return (
      <React.Fragment>
        <Modal.Header closeButton>
          <Pagination>
            <Pagination.First onClick={() => this.pagination(1)} />
            <Pagination.Prev
              onClick={() => this.pagination(this.state.activePage - 1)}
            />
            {this.state.pages}{" "}
            <Pagination.Next
              onClick={() => this.pagination(this.state.activePage + 1)}
            />
            <Pagination.Last
              onClick={() => this.pagination(this.state.count)}
            />
          </Pagination>
        </Modal.Header>
        <Modal.Body>
          {this.state.selectedPages.map((item, index) => (
            <Row
              key={index}
              className="pointer bg-white rounded mb-2 p-2 shadow"
              onClick={() => this.props.setMode(item.id)}
            >
              <Col>
                <Row>
                  <Col>
                    <h4>{item.photosetName}</h4>
                  </Col>
                  <Col md={3} className="text-right">
                    {item.createdAt}
                  </Col>
                </Row>
                <small>{item.id}</small>
              </Col>
            </Row>
          ))}
        </Modal.Body>
      </React.Fragment>
    );
  }
}

export default AdminUserPhotosetsModal;
