import React, { Component, createRef } from "react";
import Moment from "moment";
import MomentLocalizer from "react-widgets-moment";
import ListGroup from "react-bootstrap/ListGroup";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { Link } from "react-router-dom";
//import renderHTML from "react-render-html";

Moment.locale("en");
MomentLocalizer();

class Notifications extends Component {
  state = {
    notifications: this.props.notifications,
    node: createRef(),
  };

  handleClick = (e) => {
    if (this.state.node.current.contains(e.target)) {
      // inside click
      return;
    }
    // outside click
    this.props.setHidden();
  };

  componentDidMount() {
    document.addEventListener("mousedown", this.handleClick);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClick);
  }

  render() {
    return (
      <div ref={this.state.node}>
        <ListGroup variant="flush" className="rounded" id="notificationList">
          <small>
            {this.state.notifications.Count ? (
              <React.Fragment>
                {this.state.notifications.Items.map((i, index) => (
                  <React.Fragment key={index}>
                    {i.link.S.charAt(0) === "/" ? (
                      <React.Fragment>
                        <Link to={i.link.S}>
                          <ListGroup.Item
                            onClick={this.props.locallyRead.bind(null, index)}
                            key={index}
                            className={
                              "rounded " + (!i.isRead.BOOL && "selectedNav")
                            }
                          >
                            <Row className="">
                              <Col>{i.message.S}</Col>
                            </Row>
                            <Row>
                              <Col className="text-secondary">
                                {Moment.unix(i.createdAt.S).format(
                                  "YYYY/MM/DD hh:mm a"
                                )}
                              </Col>
                            </Row>
                          </ListGroup.Item>
                        </Link>
                      </React.Fragment>
                    ) : (
                      <React.Fragment key={index}>
                        {i.link.S === "empty" ? (
                          <ListGroup.Item
                            onClick={this.props.locallyRead.bind(null, index)}
                            key={index}
                            className={
                              "rounded " + (!i.isRead.BOOL && "selectedNav")
                            }
                          >
                            <Row className="">
                              <Col>{i.message.S}</Col>
                            </Row>
                            <Row>
                              <Col className="text-secondary">
                                {Moment.unix(i.createdAt.S).format(
                                  "YYYY/MM/DD hh:mm a"
                                )}
                              </Col>
                            </Row>
                          </ListGroup.Item>
                        ) : (
                          <ListGroup.Item
                            onClick={this.props.locallyRead.bind(null, index)}
                            key={index}
                            className={
                              "rounded " + (!i.isRead.BOOL && "selectedNav")
                            }
                            action
                            href={i.link.S}
                            target="_blank"
                          >
                            <Row className="">
                              <Col>{i.message.S}</Col>
                            </Row>
                            <Row>
                              <Col className="text-secondary">
                                {Moment.unix(i.createdAt.S).format(
                                  "YYYY/MM/DD hh:mm a"
                                )}
                              </Col>
                            </Row>
                          </ListGroup.Item>
                        )}
                      </React.Fragment>
                    )}
                  </React.Fragment>
                ))}
              </React.Fragment>
            ) : (
              <ListGroup.Item className="rounded text-center">
                No Notifcations yet.
              </ListGroup.Item>
            )}
          </small>
        </ListGroup>
      </div>
    );
  }
}

export default Notifications;
