import React, { Component } from "react";
import { NavLink } from "react-router-dom";

class sidePills extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    let cls = this.props.active;

    return (
      <NavLink
        exact
        style={{ textDecoration: "none" }}
        className={"mb-2 text-left nav-link " + cls}
        id={"v-pills-" + this.props.href + "-tab"}
        data-toggle="pill"
        href={"/" + this.props.href}
        role="tab"
        aria-controls={"v-pills-" + this.props.href}
        aria-selected={this.props.active}
        to={"/" + this.props.href}
      >
        {this.props.icon} <span className="ml-2"> {this.props.id}</span>
      </NavLink>
    );
  }
}

export default sidePills;
