import React from "react";
import Png from "../media/Error_404_PNG.png";
import Image from "react-bootstrap/Image";

const notFound = () => {
  return (
    <div id="notfound">
      <div className="notfound">
        <div className="notfound-404">
          <Image src={Png} width="100%" />
          <h2>We are sorry, but the page you requested was not found</h2>
        </div>
      </div>
    </div>
  );
};

export default notFound;
