import React, { Component } from "react";
//import StoreItem from "./storeItem";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import FadeIn from "react-fade-in";

import { Link } from "react-router-dom";

class StoreList extends Component {
  state = {
    items: [
      {
        id: "1",
        name: "Item 1",
        image:
          "https://scontent.fmnl16-1.fna.fbcdn.net/v/t1.0-9/99252337_10215637941493836_4254467884702498816_n.jpg?_nc_cat=103&_nc_sid=dd9801&_nc_eui2=AeFrs3kSkvFWAvYc_2yw9Xw5kpVxk6CNVVGSlXGToI1VUbD1qhIkeGWym80SQdni7ZQ&_nc_oc=AQkKzztJ49tATEjSDH9LQvTQDyduJmgRZbkkUeVxXDH9jWgPbYzvz-8KUu3tNPJYVmc&_nc_ht=scontent.fmnl16-1.fna&oh=472e074e2df700f4a32f3e55c9a84ad9&oe=5EFE633E",
        description: "stuff",
        title: "Shop item",
        price: "$10.00",
        sale: false,
      },
      {
        id: "2",
        name: "Item 2",
        image:
          "https://scontent.fmnl16-1.fna.fbcdn.net/v/t1.0-9/83495912_2651323911645032_605574630500990976_o.jpg?_nc_cat=101&_nc_sid=6e5ad9&_nc_eui2=AeFgz7-qAvzYc7QCPsIMqzR-lAPDf-1X-k2UA8N_7Vf6TXLBg0QbhiUG_TruSz0K_NA&_nc_oc=AQlkzhN_Jbwu60L-FUX7DoXNzHzS3iLyJg5fupbsCMK4avXpG0Vt8YAM4AK3WIWIiJg&_nc_ht=scontent.fmnl16-1.fna&oh=9b0bb44688c5bb93c5b434c7b90c1972&oe=5EFFE91F",
        description: "none",
        title: " another item",
        price: "$10.00",
        sale: true,
      },
      {
        id: "3",
        name: "Item 3",
        image:
          "https://scontent.fmnl16-1.fna.fbcdn.net/v/t1.0-9/99252337_10215637941493836_4254467884702498816_n.jpg?_nc_cat=103&_nc_sid=dd9801&_nc_eui2=AeFrs3kSkvFWAvYc_2yw9Xw5kpVxk6CNVVGSlXGToI1VUbD1qhIkeGWym80SQdni7ZQ&_nc_oc=AQkKzztJ49tATEjSDH9LQvTQDyduJmgRZbkkUeVxXDH9jWgPbYzvz-8KUu3tNPJYVmc&_nc_ht=scontent.fmnl16-1.fna&oh=472e074e2df700f4a32f3e55c9a84ad9&oe=5EFE633E",
        description: "none",
        title: " another item",
        price: "$10.00",
        sale: true,
      },
      {
        id: "4",
        name: "Item 4",
        image:
          "https://scontent.fmnl16-1.fna.fbcdn.net/v/t1.0-9/99252337_10215637941493836_4254467884702498816_n.jpg?_nc_cat=103&_nc_sid=dd9801&_nc_eui2=AeFrs3kSkvFWAvYc_2yw9Xw5kpVxk6CNVVGSlXGToI1VUbD1qhIkeGWym80SQdni7ZQ&_nc_oc=AQkKzztJ49tATEjSDH9LQvTQDyduJmgRZbkkUeVxXDH9jWgPbYzvz-8KUu3tNPJYVmc&_nc_ht=scontent.fmnl16-1.fna&oh=472e074e2df700f4a32f3e55c9a84ad9&oe=5EFE633E",
        description: "none",
        title: " another item",
        price: "$10.00",
        sale: true,
      },
      {
        id: "5",
        name: "Item 5",
        image:
          "https://scontent.fmnl16-1.fna.fbcdn.net/v/t1.0-9/99252337_10215637941493836_4254467884702498816_n.jpg?_nc_cat=103&_nc_sid=dd9801&_nc_eui2=AeFrs3kSkvFWAvYc_2yw9Xw5kpVxk6CNVVGSlXGToI1VUbD1qhIkeGWym80SQdni7ZQ&_nc_oc=AQkKzztJ49tATEjSDH9LQvTQDyduJmgRZbkkUeVxXDH9jWgPbYzvz-8KUu3tNPJYVmc&_nc_ht=scontent.fmnl16-1.fna&oh=472e074e2df700f4a32f3e55c9a84ad9&oe=5EFE633E",
        description: "none",
        title: " another item",
        price: "$10.00",
        sale: true,
      },
      {
        id: "6",
        name: "Item 6",
        image:
          "https://scontent.fmnl16-1.fna.fbcdn.net/v/t1.0-9/99252337_10215637941493836_4254467884702498816_n.jpg?_nc_cat=103&_nc_sid=dd9801&_nc_eui2=AeFrs3kSkvFWAvYc_2yw9Xw5kpVxk6CNVVGSlXGToI1VUbD1qhIkeGWym80SQdni7ZQ&_nc_oc=AQkKzztJ49tATEjSDH9LQvTQDyduJmgRZbkkUeVxXDH9jWgPbYzvz-8KUu3tNPJYVmc&_nc_ht=scontent.fmnl16-1.fna&oh=472e074e2df700f4a32f3e55c9a84ad9&oe=5EFE633E",
        description: "none",
        title: " another item",
        price: "$10.00",
        sale: true,
      },
    ],
  };

  render() {
    return (
      <div>
        <div className="my-3 rounded p-1 bg-white shadow-sm">
          <Button className="m-1">Add an Item</Button>
          <Button className="m-1">View Analytics</Button>
        </div>

        <Row>
          {this.state.items.map((item, index) => (
            <Col className="p-2" md={3} key={index}>
              <FadeIn className="d-inline">
                <Link to={"/store/" + item.id} className="no-underline">
                  <Card className="pointer borderable">
                    <Card.Img variant="top" src={item.image} />
                    <Card.Body>
                      <Card.Title className="text-sm">
                        <small>{item.title}</small>
                      </Card.Title>
                      <Card.Text>
                        <span className="text-secondary">{item.price}</span>
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </Link>
              </FadeIn>
            </Col>
          ))}
        </Row>
      </div>
    );
  }
}

export default StoreList;
