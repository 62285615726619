import React, { Component } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";

class StoreDetails extends Component {
  render() {
    return (
      <div>
        <Row>
          <Col>
            <h3 className="m-0">Store Item Details</h3>
          </Col>
          <Col className="text-right">
            <h3 className="text-secondary m-0">$10.00</h3>
          </Col>
        </Row>
        <Row>
          <Col>
            <small className="text-success">Total Sales: $120.00</small>
          </Col>
          <Col className="text-right" md={4}>
            <Button className="m-1  btn-sm">Disable</Button>
            <Button className="m-1  btn-sm">Disable</Button>
            <Button className="ml-1 my-1 btn-sm" variant="outline-danger">
              Disable
            </Button>
          </Col>
        </Row>
      </div>
    );
  }
}

export default StoreDetails;
