import React, { Component } from "react";

import StoreList from "./storeList";
import StoreAdd from "./storeAdd";
import StoreDetails from "./storeDetails";

import Image from "react-bootstrap/Image";
import Pic from "../../media/Web_design_PNG.png";
import { Switch, Route } from "react-router-dom";

class Store extends Component {
  render() {
    return (
      <>
        <div className="mt-4 text-left">
          {this.props.isAdmin ? (
            <>
              <Switch>
                <Route
                  path="/store"
                  exact
                  render={(props) => <StoreList token={this.props.token} />}
                />
                <Route
                  path="/store/:id"
                  exact
                  render={(props) => <StoreDetails token={this.props.token} />}
                />
                <Route
                  path="/store/add"
                  exact
                  render={(props) => <StoreAdd token={this.props.token} />}
                />
              </Switch>
            </>
          ) : (
            <div className="text-center py-auto h-100 d-flex align-items-center">
              <div className="container">
                <Image src={Pic} width={150} height={150} />
                <h3>Under Construction</h3>
                This is a planned feature. This will be available in the future.
              </div>
            </div>
          )}
        </div>
      </>
    );
  }
}

export default Store;
