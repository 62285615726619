import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import TierDropdown from "./parts/tierDropdown";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Collapse from "react-bootstrap/Collapse";

class TierDropdownPill extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      tiers: [],
      isLoading: true,
      t: [],
      default: 0,
      newDefault: this.props.defaultValue,
      color: "#FFFFFF",
      tierTitle: "",
      fontColor: "#000000",
      id: "",
      editTier: false,
      tierData: this.props.tierData,
      customId: this.props.customId === null ? null : this.props.customId,
    };
  }

  componentDidMount() {
    this._isMounted = true;
  }

  initResponse = (color, id, name) => {
    let c = color.substring(1); // strip #
    let rgb = parseInt(c, 16); // convert rrggbb to decimal
    let r = (rgb >> 16) & 0xff; // extract red
    let g = (rgb >> 8) & 0xff; // extract green
    let b = (rgb >> 0) & 0xff; // extract blue

    let luma = 0.2126 * r + 0.7152 * g + 0.0722 * b; // per ITU-R BT.709

    let fontColor = "#333333";

    if (luma < 100) fontColor = "#FFFFFF";

    this.setState({
      color: color,
      tierTitle: name,
      id: id,
      fontColor: fontColor,
    });
  };

  setPill = (e) => {};

  toggleSelectMode = () => {
    if (this._isMounted) this.setState({ editTier: true });
  };

  togglePillMode = () => {
    if (this._isMounted)
      this.setState({
        editTier: false,
      });
  };

  componentWillUnmount() {
    this._isMounted = false;
  }

  setTierData = (color, id, name, tierId) => {
    if (this._isMounted) this.setState({ color: color, tierTitle: name });

    let c = color.substring(1); // strip #
    let rgb = parseInt(c, 16); // convert rrggbb to decimal
    let r = (rgb >> 16) & 0xff; // extract red
    let g = (rgb >> 8) & 0xff; // extract green
    let b = (rgb >> 0) & 0xff; // extract blue

    let luma = 0.2126 * r + 0.7152 * g + 0.0722 * b; // per ITU-R BT.709

    let fontColor = "#333333";

    if (luma < 100) fontColor = "#FFFFFF";

    this.setState({ fontColor: fontColor });

    this.props.action(color, id, name, tierId);
  };

  render() {
    return (
      <span>
        <Collapse in={this.state.editTier}>
          <Row>
            <Col className="m-0 pl-3" xs={8}>
              <TierDropdown
                token={this.props.token}
                action={this.setTierData}
                defaultValue={this.props.defaultValue}
                size="sm"
                initResponse={this.initResponse}
                followUp={this.togglePillMode}
                tierData={this.state.tierData}
                customId={this.state.customId}
              />
            </Col>
            <Col className="my-auto ml-1 p-0">
              <Button
                className=" btn-danger btn-sm"
                onClick={this.togglePillMode}
              >
                <FontAwesomeIcon icon={faTimesCircle} />
              </Button>
            </Col>
          </Row>
        </Collapse>
        <Collapse in={!this.state.editTier}>
          {this.state.customId !== null ? (
            <span
              id={"tierPill" + this.state.customId}
              className="badge badge-pill mr-2 pointer glow "
              style={{
                backgroundColor: this.state.color,
                color: this.state.fontColor,
              }}
              onClick={this.toggleSelectMode}
            >
              {this.state.tierTitle}
            </span>
          ) : (
            <span
              className="badge badge-pill mr-2 pointer glow "
              style={{
                backgroundColor: this.state.color,
                color: this.state.fontColor,
              }}
              onClick={this.toggleSelectMode}
            >
              {this.state.tierTitle}
            </span>
          )}
        </Collapse>
      </span>
    );
  }
}

export default TierDropdownPill;
