import React from "react";
import renderHTML from "react-render-html";

const Terms = () => {
  const html = ` <div class="boiler">
    <div boiler="boiler" fields="fields" cyclicals="cyclicals" questions="questions" question-index="questionIndex"
        class="ng-isolate-scope">
        <div class="ng-scope">
            <p style="text-align:center"><span style="font-weight:700;font-family:Jost;font-size:14pt">Terms
                    and Conditions</span></p>
            <p>&nbsp;</p>
            <p><span style="font-weight:700;font-family:Jost;font-size:12pt">Agreement between User and
                </span><span field="{index: 11, boiler: 0}" ng-model="fields[11].data"
                    style="font-weight:700;font-family:Jost;font-size:12pt"
                    class="ng-pristine ng-untouched ng-valid ng-not-empty seen">https://www.shieldshot.com/</span></p>
            <p>Welcome to <span field="{index: 11, boiler: 1}" ng-model="fields[11].data"
                    class="ng-pristine ng-untouched ng-valid ng-not-empty seen">https://www.shieldshot.com/</span>. The
                <span field="{index: 11, boiler: 2}" ng-model="fields[11].data"
                    class="ng-pristine ng-untouched ng-valid ng-not-empty seen">https://www.shieldshot.com/</span>
                website (the "Site") is comprised of various web pages operated by <span field="{index: 117, boiler: 3}"
                    ng-model="fields[117].data" class="ng-pristine ng-untouched ng-valid ng-not-empty seen">ShieldShot
                    LLC</span><!-- ngIf: restrictions[0].results[0] --><span ng-if="restrictions[0].results[0]"
                    class="ng-scope"> ("<span field="{index: 94, boiler: 4}" ng-model="fields[94].data"
                        class="ng-pristine ng-untouched ng-valid ng-not-empty">ShieldShot</span>")</span>
                <!-- end ngIf: restrictions[0].results[0] -->. <span field="{index: 11, boiler: 5}"
                    ng-model="fields[11].data"
                    class="ng-pristine ng-untouched ng-valid ng-not-empty seen">https://www.shieldshot.com/</span> is
                offered to you conditioned on your acceptance without modification of the terms, conditions, and notices
                contained herein (the "Terms"). Your use of <span field="{index: 11, boiler: 6}"
                    ng-model="fields[11].data"
                    class="ng-pristine ng-untouched ng-valid ng-not-empty seen">https://www.shieldshot.com/</span>
                constitutes your agreement to all such Terms. Please read these terms carefully, and keep a copy of them
                for your reference.</p>
            <p>&nbsp;</p>
            <p><span field="{index: 11, boiler: 7}" ng-model="fields[11].data"
                    class="ng-pristine ng-untouched ng-valid ng-not-empty seen">https://www.shieldshot.com/</span> is a
                <!-- ngIf: restrictions[1].results[0] -->
                <!-- ngIf: restrictions[2].results[0] -->
                <!-- ngIf: restrictions[3].results[0] --><span ng-if="restrictions[3].results[0]"
                    class="ng-scope">&nbsp;<span field="{index: 55, boiler: 9}" ng-model="fields[55].data"
                        class="ng-pristine ng-untouched ng-valid ng-not-empty seen">Photo Protection, Storage and
                        Distribution</span></span><!-- end ngIf: restrictions[3].results[0] --> Site.</p>
            <p>&nbsp;</p>
            <p><span field="{index: 46, boiler: 10}" ng-model="fields[46].data"
                    class="ng-pristine ng-untouched ng-valid ng-not-empty seen">Use ShieldShot to upload your photos and
                    instantly embed copyright protection directly into them. Once processed, you can distribute your
                    photosets to your customers and supporters via our automated distribution tool. Photosets sent to
                    customers will have their copyright protect re-applied along with the user's information, so if a
                    photo is shared without your permission, you can identify exactly who shared it.</span></p>
            <p>&nbsp;</p><!-- ngIf: restrictions[4].results[0] -->
            <!-- ngIf: restrictions[5].results[0] -->
            <!-- ngIf: restrictions[6].results[0] -->
            <p><span style="font-weight:700;font-family:Jost;font-size:12pt">Electronic Communications</span>
            </p>
            <p>Visiting <span field="{index: 11, boiler: 13}" ng-model="fields[11].data"
                    class="ng-pristine ng-untouched ng-valid ng-not-empty seen">https://www.shieldshot.com/</span> or
                sending emails to <span field="{index: 94, boiler: 14}" ng-model="fields[94].data"
                    class="ng-pristine ng-untouched ng-valid ng-not-empty">ShieldShot</span> constitutes electronic
                communications. You consent to receive electronic communications and you agree that all agreements,
                notices, disclosures and other communications that we provide to you electronically, via email and on
                the Site, satisfy any legal requirement that such communications be in writing.</p>
            <p>&nbsp;</p><!-- ngIf: restrictions[7].results[0] -->
            <p ng-if="restrictions[7].results[0]" class="ng-scope"><span
                    style="font-weight:700;font-family:Jost;font-size:12pt">Your Account</span></p>
            <!-- end ngIf: restrictions[7].results[0] -->
            <!-- ngIf: restrictions[8].results[0] -->
            <p ng-if="restrictions[8].results[0]" class="ng-scope">If you use this site, you are responsible for
                maintaining the confidentiality of your account and password and for restricting access to your
                computer, and you agree to accept responsibility for all activities that occur under your account or
                password. You may not assign or otherwise transfer your account to any other person or entity. You
                acknowledge that <span field="{index: 94, boiler: 15}" ng-model="fields[94].data"
                    class="ng-pristine ng-untouched ng-valid ng-not-empty">ShieldShot</span> is not responsible for
                third party access to your account that results from theft or misappropriation of your account. <span
                    field="{index: 94, boiler: 16}" ng-model="fields[94].data"
                    class="ng-pristine ng-untouched ng-valid ng-not-empty">ShieldShot</span> and its associates reserve
                the right to refuse or cancel service, terminate accounts, or remove or edit content in our sole
                discretion.</p><!-- end ngIf: restrictions[8].results[0] -->
            <!-- ngIf: restrictions[9].results[0] -->
            <p ng-if="restrictions[9].results[0]" class="ng-scope">&nbsp;</p>
            <!-- end ngIf: restrictions[9].results[0] -->
            <p><span style="font-weight:700;font-family:Jost;font-size:12pt">Children Under Thirteen</span>
            </p><!-- ngIf: restrictions[10].results[0] -->
            <p ng-if="restrictions[10].results[0]" class="ng-scope"><span field="{index: 94, boiler: 17}"
                    ng-model="fields[94].data" class="ng-pristine ng-untouched ng-valid ng-not-empty">ShieldShot</span>
                does not knowingly collect, either online or offline, personal information from persons under the age of
                thirteen. If you are under 18, you may use <span field="{index: 11, boiler: 18}"
                    ng-model="fields[11].data"
                    class="ng-pristine ng-untouched ng-valid ng-not-empty seen">https://www.shieldshot.com/</span> only
                with permission of a parent or guardian. </p><!-- end ngIf: restrictions[10].results[0] -->
            <!-- ngIf: restrictions[11].results[0] -->
            <p>&nbsp;</p>
        </div><!-- ngRepeat: i in fields[1].data track by $index -->
        <div class="ng-scope">
            <!-- ngIf: restrictions[13].results[0] -->
            <!-- ngIf: restrictions[15].results[0] -->
            <!-- ngIf: restrictions[16].results[0] -->
            <p ng-if="restrictions[16].results[0]" class="ng-scope"><span
                    style="font-weight:700;font-family:Jost;font-size:12pt">Cancellation/Refund Policy</span>
            </p><!-- end ngIf: restrictions[16].results[0] -->
            <!-- ngIf: restrictions[17].results[0] -->
            <p ng-if="restrictions[17].results[0]" class="ng-scope"><span field="{index: 100, boiler: 23}"
                    ng-model="fields[100].data" class="ng-pristine ng-untouched ng-valid ng-not-empty seen">You may
                    cancel your subscription at any time. After cancellation, your subscription will remain in place
                    until the end of your subscription term. At which point, the cancellation will be completed. No
                    refunds are provided at this time. All sales are final.</span></p>
            <!-- end ngIf: restrictions[17].results[0] -->
            <!-- ngIf: restrictions[18].results[0] -->
            <p ng-if="restrictions[18].results[0]" class="ng-scope">&nbsp;</p>
            <!-- end ngIf: restrictions[18].results[0] -->
            <p><span style="font-weight:700;font-family:Jost;font-size:12pt">Links to Third Party Sites/Third
                    Party Services</span></p>
            <p><span field="{index: 11, boiler: 24}" ng-model="fields[11].data"
                    class="ng-pristine ng-untouched ng-valid ng-not-empty seen">https://www.shieldshot.com/</span> may
                contain links to other websites ("Linked Sites"). The Linked Sites are not under the control of <span
                    field="{index: 94, boiler: 25}" ng-model="fields[94].data"
                    class="ng-pristine ng-untouched ng-valid ng-not-empty">ShieldShot</span> and <span
                    field="{index: 94, boiler: 26}" ng-model="fields[94].data"
                    class="ng-pristine ng-untouched ng-valid ng-not-empty">ShieldShot</span> is not responsible for the
                contents of any Linked Site, including without limitation any link contained in a Linked Site, or any
                changes or updates to a Linked Site. <span field="{index: 94, boiler: 27}" ng-model="fields[94].data"
                    class="ng-pristine ng-untouched ng-valid ng-not-empty">ShieldShot</span> is providing these links to
                you only as a convenience, and the inclusion of any link does not imply endorsement by <span
                    field="{index: 94, boiler: 28}" ng-model="fields[94].data"
                    class="ng-pristine ng-untouched ng-valid ng-not-empty">ShieldShot</span> of the site or any
                association with its operators.</p>
            <p>&nbsp;</p>
            <p>Certain services made available via <span field="{index: 11, boiler: 29}" ng-model="fields[11].data"
                    class="ng-pristine ng-untouched ng-valid ng-not-empty seen">https://www.shieldshot.com/</span> are
                delivered by third party sites and organizations. By using any product, service or functionality
                originating from the <span field="{index: 11, boiler: 30}" ng-model="fields[11].data"
                    class="ng-pristine ng-untouched ng-valid ng-not-empty seen">https://www.shieldshot.com/</span>
                domain, you hereby acknowledge and consent that <span field="{index: 94, boiler: 31}"
                    ng-model="fields[94].data" class="ng-pristine ng-untouched ng-valid ng-not-empty">ShieldShot</span>
                may share such information and data with any third party with whom <span field="{index: 94, boiler: 32}"
                    ng-model="fields[94].data" class="ng-pristine ng-untouched ng-valid ng-not-empty">ShieldShot</span>
                has a contractual relationship to provide the requested product, service or functionality on behalf of
                <span field="{index: 11, boiler: 33}" ng-model="fields[11].data"
                    class="ng-pristine ng-untouched ng-valid ng-not-empty seen">https://www.shieldshot.com/</span> users
                and customers.</p>
            <p>&nbsp;</p>
            <p><span style="font-weight:700;font-family:Jost;font-size:12pt">No Unlawful or Prohibited
                    Use/Intellectual Property </span></p>
            <p>You are granted a non-exclusive, non-transferable, revocable license to access and use <span
                    field="{index: 11, boiler: 34}" ng-model="fields[11].data"
                    class="ng-pristine ng-untouched ng-valid ng-not-empty seen">https://www.shieldshot.com/</span>
                strictly in accordance with these terms of use. As a condition of your use of the Site, you warrant to
                <span field="{index: 94, boiler: 35}" ng-model="fields[94].data"
                    class="ng-pristine ng-untouched ng-valid ng-not-empty">ShieldShot</span> that you will not use the
                Site for any purpose that is unlawful or prohibited by these Terms. You may not use the Site in any
                manner which could damage, disable, overburden, or impair the Site or interfere with any other party's
                use and enjoyment of the Site. You may not obtain or attempt to obtain any materials or information
                through any means not intentionally made available or provided for through the Site. </p>
            <p>&nbsp;</p>
            <p>All content included as part of the Service, such as text, graphics, logos, images, as well as the
                compilation thereof, and any software used on the Site, is the property of <span
                    field="{index: 94, boiler: 36}" ng-model="fields[94].data"
                    class="ng-pristine ng-untouched ng-valid ng-not-empty">ShieldShot</span> or its suppliers and
                protected by copyright and other laws that protect intellectual property and proprietary rights. You
                agree to observe and abide by all copyright and other proprietary notices, legends or other restrictions
                contained in any such content and will not make any changes thereto. </p>
            <p>&nbsp;</p>
            <p>You will not modify, publish, transmit, reverse engineer, participate in the transfer or sale, create
                derivative works, or in any way exploit any of the content, in whole or in part, found on the Site.
                <span field="{index: 94, boiler: 37}" ng-model="fields[94].data"
                    class="ng-pristine ng-untouched ng-valid ng-not-empty">ShieldShot</span> content is not for resale.
                Your use of the Site does not entitle you to make any unauthorized use of any protected content, and in
                particular you will not delete or alter any proprietary rights or attribution notices in any content.
                You will use protected content solely for your personal use, and will make no other use of the content
                without the express written permission of <span field="{index: 94, boiler: 38}"
                    ng-model="fields[94].data" class="ng-pristine ng-untouched ng-valid ng-not-empty">ShieldShot</span>
                and the copyright owner. You agree that you do not acquire any ownership rights in any protected
                content. We do not grant you any licenses, express or implied, to the intellectual property of <span
                    field="{index: 94, boiler: 39}" ng-model="fields[94].data"
                    class="ng-pristine ng-untouched ng-valid ng-not-empty">ShieldShot</span> or our licensors except as
                expressly authorized by these Terms. </p>
            <p>&nbsp;</p>
        </div><!-- ngIf: restrictions[19].results[0] -->
        <div class="ng-scope">
            <!-- ngIf: restrictions[20].results[0] -->
            <!-- ngIf: restrictions[21].results[0] -->
            <!-- ngIf: restrictions[22].results[0] -->
            <p><span style="font-weight:700;font-family:Jost;font-size:12pt">International Users</span></p>
            <p>The Service is controlled, operated and administered by <span field="{index: 94, boiler: 59}"
                    ng-model="fields[94].data" class="ng-pristine ng-untouched ng-valid ng-not-empty">ShieldShot</span>
                from our offices within the USA. If you access the Service from a location outside the USA, you are
                responsible for compliance with all local laws. You agree that you will not use the <span
                    field="{index: 94, boiler: 60}" ng-model="fields[94].data"
                    class="ng-pristine ng-untouched ng-valid ng-not-empty">ShieldShot</span> Content accessed through
                <span field="{index: 11, boiler: 61}" ng-model="fields[11].data"
                    class="ng-pristine ng-untouched ng-valid ng-not-empty seen">https://www.shieldshot.com/</span> in
                any country or in any manner prohibited by any applicable laws, restrictions or regulations.</p>
            <p>&nbsp;</p>
            <p><span style="font-weight:700;font-family:Jost;font-size:12pt">Indemnification</span></p>
            <p>You agree to indemnify, defend and hold harmless <span field="{index: 94, boiler: 62}"
                    ng-model="fields[94].data" class="ng-pristine ng-untouched ng-valid ng-not-empty">ShieldShot</span>,
                its officers, directors, employees, agents and third parties, for any losses, costs, liabilities and
                expenses (including reasonable attorney's fees) relating to or arising out of your use of or inability
                to use the Site or services, any user postings made by you, your violation of any terms of this
                Agreement or your violation of any rights of a third party, or your violation of any applicable laws,
                rules or regulations. <span field="{index: 94, boiler: 63}" ng-model="fields[94].data"
                    class="ng-pristine ng-untouched ng-valid ng-not-empty">ShieldShot</span> reserves the right, at its
                own cost, to assume the exclusive defense and control of any matter otherwise subject to indemnification
                by you, in which event you will fully cooperate with <span field="{index: 94, boiler: 64}"
                    ng-model="fields[94].data" class="ng-pristine ng-untouched ng-valid ng-not-empty">ShieldShot</span>
                in asserting any available defenses.</p>
            <p>&nbsp;</p><!-- ngIf: restrictions[23].results[0] -->
            <p ng-if="restrictions[23].results[0]" class="ng-scope"><span
                    style="font-weight:700;font-family:Jost;font-size:12pt">Arbitration</span></p>
            <!-- end ngIf: restrictions[23].results[0] -->
            <!-- ngIf: restrictions[24].results[0] -->
            <p ng-if="restrictions[24].results[0]" class="ng-scope">In the event the parties are not able to resolve any
                dispute between them arising out of or concerning these Terms and Conditions, or any provisions hereof,
                whether in contract, tort, or otherwise at law or in equity for damages or any other relief, then such
                dispute shall be resolved only by final and binding arbitration pursuant to the Federal Arbitration Act,
                conducted by a single neutral arbitrator and administered by the American Arbitration Association, or a
                similar arbitration service selected by the parties, in a location mutually agreed upon by the parties.
                The arbitrator's award shall be final, and judgment may be entered upon it in any court having
                jurisdiction. In the event that any legal or equitable action, proceeding or arbitration arises out of
                or concerns these Terms and Conditions, the prevailing party shall be entitled to recover its costs and
                reasonable attorney's fees. The parties agree to arbitrate all disputes and claims in regards to these
                Terms and Conditions or any disputes arising as a result of these Terms and Conditions, whether directly
                or indirectly, including Tort claims that are a result of these Terms and Conditions. The parties agree
                that the Federal Arbitration Act governs the interpretation and enforcement of this provision. The
                entire dispute, including the scope and enforceability of this arbitration provision shall be determined
                by the Arbitrator. This arbitration provision shall survive the termination of these Terms and
                Conditions.</p><!-- end ngIf: restrictions[24].results[0] -->
            <!-- ngIf: restrictions[25].results[0] -->
            <p ng-if="restrictions[25].results[0]" class="ng-scope">&nbsp;</p>
            <!-- end ngIf: restrictions[25].results[0] -->
            <!-- ngIf: restrictions[26].results[0] -->
            <p ng-if="restrictions[26].results[0]" class="ng-scope"><span
                    style="font-weight:700;font-family:Jost;font-size:12pt">Class Action Waiver</span>&nbsp;
            </p><!-- end ngIf: restrictions[26].results[0] -->
            <!-- ngIf: restrictions[27].results[0] -->
            <p ng-if="restrictions[27].results[0]" class="ng-scope">Any arbitration under these Terms and Conditions
                will take place on an individual basis; class arbitrations and class/representative/collective actions
                are not permitted. THE PARTIES AGREE THAT A PARTY MAY BRING CLAIMS AGAINST THE OTHER ONLY IN EACH'S
                INDIVIDUAL CAPACITY, AND NOT AS A PLAINTIFF OR CLASS MEMBER IN ANY PUTATIVE CLASS, COLLECTIVE AND/ OR
                REPRESENTATIVE PROCEEDING, SUCH AS IN THE FORM OF A PRIVATE ATTORNEY GENERAL ACTION AGAINST THE OTHER.
                Further, unless both you and <span field="{index: 94, boiler: 65}" ng-model="fields[94].data"
                    class="ng-pristine ng-untouched ng-valid ng-not-empty">ShieldShot</span> agree otherwise, the
                arbitrator may not consolidate more than one person's claims, and may not otherwise preside over any
                form of a representative or class proceeding. </p><!-- end ngIf: restrictions[27].results[0] -->
            <!-- ngIf: restrictions[28].results[0] -->
            <p ng-if="restrictions[28].results[0]" class="ng-scope">&nbsp;</p>
            <!-- end ngIf: restrictions[28].results[0] -->
            <p><span style="font-weight:700;font-family:Jost;font-size:12pt">Liability Disclaimer</span></p>
            <p>THE INFORMATION, SOFTWARE, PRODUCTS, AND SERVICES INCLUDED IN OR AVAILABLE THROUGH THE SITE MAY INCLUDE
                INACCURACIES OR TYPOGRAPHICAL ERRORS. CHANGES ARE PERIODICALLY ADDED TO THE INFORMATION HEREIN. <span
                    field="{index: 30, boiler: 66}" ng-model="fields[30].data"
                    class="ng-pristine ng-untouched ng-valid ng-not-empty">SHIELDSHOT LLC</span> AND/OR ITS SUPPLIERS
                MAY MAKE IMPROVEMENTS AND/OR CHANGES IN THE SITE AT ANY TIME. </p>
            <p>&nbsp;</p>
            <p><span field="{index: 30, boiler: 67}" ng-model="fields[30].data"
                    class="ng-pristine ng-untouched ng-valid ng-not-empty">SHIELDSHOT LLC</span> AND/OR ITS SUPPLIERS
                MAKE NO REPRESENTATIONS ABOUT THE SUITABILITY, RELIABILITY, AVAILABILITY, TIMELINESS, AND ACCURACY OF
                THE INFORMATION, SOFTWARE, PRODUCTS, SERVICES AND RELATED GRAPHICS CONTAINED ON THE SITE FOR ANY
                PURPOSE. TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, ALL SUCH INFORMATION, SOFTWARE, PRODUCTS,
                SERVICES AND RELATED GRAPHICS ARE PROVIDED "AS IS" WITHOUT WARRANTY OR CONDITION OF ANY KIND. <span
                    field="{index: 30, boiler: 68}" ng-model="fields[30].data"
                    class="ng-pristine ng-untouched ng-valid ng-not-empty">SHIELDSHOT LLC</span> AND/OR ITS SUPPLIERS
                HEREBY DISCLAIM ALL WARRANTIES AND CONDITIONS WITH REGARD TO THIS INFORMATION, SOFTWARE, PRODUCTS,
                SERVICES AND RELATED GRAPHICS, INCLUDING ALL IMPLIED WARRANTIES OR CONDITIONS OF MERCHANTABILITY,
                FITNESS FOR A PARTICULAR PURPOSE, TITLE AND NON-INFRINGEMENT. </p>
            <p>&nbsp;</p>
            <p>TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT SHALL <span
                    field="{index: 30, boiler: 69}" ng-model="fields[30].data"
                    class="ng-pristine ng-untouched ng-valid ng-not-empty">SHIELDSHOT LLC</span> AND/OR ITS SUPPLIERS BE
                LIABLE FOR ANY DIRECT, INDIRECT, PUNITIVE, INCIDENTAL, SPECIAL, CONSEQUENTIAL DAMAGES OR ANY DAMAGES
                WHATSOEVER INCLUDING, WITHOUT LIMITATION, DAMAGES FOR LOSS OF USE, DATA OR PROFITS, ARISING OUT OF OR IN
                ANY WAY CONNECTED WITH THE USE OR PERFORMANCE OF THE SITE, WITH THE DELAY OR INABILITY TO USE THE SITE
                OR RELATED SERVICES, THE PROVISION OF OR FAILURE TO PROVIDE SERVICES, OR FOR ANY INFORMATION, SOFTWARE,
                PRODUCTS, SERVICES AND RELATED GRAPHICS OBTAINED THROUGH THE SITE, OR OTHERWISE ARISING OUT OF THE USE
                OF THE SITE, WHETHER BASED ON CONTRACT, TORT, NEGLIGENCE, STRICT LIABILITY OR OTHERWISE, EVEN IF <span
                    field="{index: 30, boiler: 70}" ng-model="fields[30].data"
                    class="ng-pristine ng-untouched ng-valid ng-not-empty">SHIELDSHOT LLC</span> OR ANY OF ITS SUPPLIERS
                HAS BEEN ADVISED OF THE POSSIBILITY OF DAMAGES. BECAUSE SOME STATES/JURISDICTIONS DO NOT ALLOW THE
                EXCLUSION OR LIMITATION OF LIABILITY FOR CONSEQUENTIAL OR INCIDENTAL DAMAGES, THE ABOVE LIMITATION MAY
                NOT APPLY TO YOU. IF YOU ARE DISSATISFIED WITH ANY PORTION OF THE SITE, OR WITH ANY OF THESE TERMS OF
                USE, YOUR SOLE AND EXCLUSIVE REMEDY IS TO DISCONTINUE USING THE SITE. </p>
            <p>&nbsp;</p>
            <p><span style="font-weight:700;font-family:Jost;font-size:12pt">Termination/Access Restriction
                </span></p>
            <p><span field="{index: 94, boiler: 71}" ng-model="fields[94].data"
                    class="ng-pristine ng-untouched ng-valid ng-not-empty">ShieldShot</span> reserves the right, in its
                sole discretion, to terminate your access to the Site and the related services or any portion thereof at
                any time, without notice. To the maximum extent permitted by law, this agreement is governed by the laws
                of the
                <!-- ngIf: restrictions[29].results[0] --><span ng-if="restrictions[29].results[0]"
                    class="ng-scope">State of </span><!-- end ngIf: restrictions[29].results[0] -->
                <!-- ngIf: restrictions[30].results[0] --><span field="{index: 74, boiler: 72}"
                    ng-model="fields[74].data"
                    class="ng-pristine ng-untouched ng-valid ng-not-empty seen">Arizona</span> and you hereby consent to
                the exclusive jurisdiction and venue of courts in
                <!-- ngIf: restrictions[31].results[0] --><span field="{index: 74, boiler: 73}"
                    ng-model="fields[74].data"
                    class="ng-pristine ng-untouched ng-valid ng-not-empty seen">Arizona</span> in all disputes arising
                out of or relating to the use of the Site. Use of the Site is unauthorized in any jurisdiction that does
                not give effect to all provisions of these Terms, including, without limitation, this section. </p>
            <p>&nbsp;</p>
            <p>You agree that no joint venture, partnership, employment, or agency relationship exists between you and
                <span field="{index: 94, boiler: 74}" ng-model="fields[94].data"
                    class="ng-pristine ng-untouched ng-valid ng-not-empty">ShieldShot</span> as a result of this
                agreement or use of the Site. <span field="{index: 94, boiler: 75}" ng-model="fields[94].data"
                    class="ng-pristine ng-untouched ng-valid ng-not-empty">ShieldShot</span>'s performance of this
                agreement is subject to existing laws and legal process, and nothing contained in this agreement is in
                derogation of <span field="{index: 94, boiler: 76}" ng-model="fields[94].data"
                    class="ng-pristine ng-untouched ng-valid ng-not-empty">ShieldShot</span>'s right to comply with
                governmental, court and law enforcement requests or requirements relating to your use of the Site or
                information provided to or gathered by <span field="{index: 94, boiler: 77}" ng-model="fields[94].data"
                    class="ng-pristine ng-untouched ng-valid ng-not-empty">ShieldShot</span> with respect to such use.
                If any part of this agreement is determined to be invalid or unenforceable pursuant to applicable law
                including, but not limited to, the warranty disclaimers and liability limitations set forth above, then
                the invalid or unenforceable provision will be deemed superseded by a valid, enforceable provision that
                most closely matches the intent of the original provision and the remainder of the agreement shall
                continue in effect. </p>
            <p>&nbsp;</p>
            <p>Unless otherwise specified herein, this agreement constitutes the entire agreement between the user and
                <span field="{index: 94, boiler: 78}" ng-model="fields[94].data"
                    class="ng-pristine ng-untouched ng-valid ng-not-empty">ShieldShot</span> with respect to the Site
                and it supersedes all prior or contemporaneous communications and proposals, whether electronic, oral or
                written, between the user and <span field="{index: 94, boiler: 79}" ng-model="fields[94].data"
                    class="ng-pristine ng-untouched ng-valid ng-not-empty">ShieldShot</span> with respect to the Site. A
                printed version of this agreement and of any notice given in electronic form shall be admissible in
                judicial or administrative proceedings based upon or relating to this agreement to the same extent and
                subject to the same conditions as other business documents and records originally generated and
                maintained in printed form. It is the express wish to the parties that this agreement and all related
                documents be written in English. </p>
            <p>&nbsp;</p>
            <p><span style="font-weight:700;font-family:Jost;font-size:12pt">Changes to Terms</span></p>
            <p><span field="{index: 94, boiler: 80}" ng-model="fields[94].data"
                    class="ng-pristine ng-untouched ng-valid ng-not-empty">ShieldShot</span> reserves the right, in its
                sole discretion, to change the Terms under which <span field="{index: 11, boiler: 81}"
                    ng-model="fields[11].data"
                    class="ng-pristine ng-untouched ng-valid ng-not-empty seen">https://www.shieldshot.com/</span> is
                offered. The most current version of the Terms will supersede all previous versions. <span
                    field="{index: 94, boiler: 82}" ng-model="fields[94].data"
                    class="ng-pristine ng-untouched ng-valid ng-not-empty">ShieldShot</span> encourages you to
                periodically review the Terms to stay informed of our updates.</p>
            <p>&nbsp;</p>
            <p><span style="font-weight:700;font-family:Jost;font-size:12pt">Contact Us</span></p>
            <p><span field="{index: 94, boiler: 83}" ng-model="fields[94].data"
                    class="ng-pristine ng-untouched ng-valid ng-not-empty">ShieldShot</span> welcomes your questions or
                comments regarding the Terms:</p>
            <p>&nbsp;</p>
            <p><span field="{index: 117, boiler: 84}" ng-model="fields[117].data"
                    class="ng-pristine ng-untouched ng-valid ng-not-empty seen">ShieldShot LLC</span></p>
            <p><span field="{index: 99, boiler: 85}" ng-model="fields[99].data"
                    class="ng-pristine ng-untouched ng-valid ng-not-empty seen">3101 N. Central Avenue Suite 183
                    #357</span></p>
            <p><span field="{index: 29, boiler: 86}" ng-model="fields[29].data"
                    class="ng-pristine ng-untouched ng-valid ng-not-empty seen">Phoenix</span>, <span
                    field="{index: 20, boiler: 87}" ng-model="fields[20].data"
                    class="ng-pristine ng-untouched ng-valid ng-not-empty seen">Arizona</span>&nbsp;<span
                    field="{index: 101, boiler: 88}" ng-model="fields[101].data"
                    class="ng-pristine ng-untouched ng-valid ng-not-empty seen">85012</span></p>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
            <p>Email Address:</p>
            <p><span field="{index: 84, boiler: 89}" ng-model="fields[84].data"
                    class="ng-pristine ng-untouched ng-valid ng-not-empty seen">feedback@shieldshot.com</span></p>
            <p>&nbsp;</p>
            <p>Telephone number:</p>
            <p><span field="{index: 68, boiler: 90}" ng-model="fields[68].data"
                    class="ng-pristine ng-untouched ng-valid ng-not-empty seen">+1 (480) 420-9064</span></p>
            <p>&nbsp;</p>
            <p>Effective as of <span field="{index: 78, boiler: 91}" ng-model="fields[78].data"
                    class="ng-pristine ng-untouched ng-valid date ng-not-empty seen">May 29, 2020</span></p>
            <p>&nbsp;</p>
        </div><!-- ngIf: restrictions[32].results[0] -->
    </div>
</div>
    </div>`;

  return (
    <div className="mx-3 mb-3 px-3 py-5 sinked" style={{ marginTop: "-35px" }}>
      <small>{renderHTML(html)}</small>{" "}
    </div>
  );
};

export default Terms;
