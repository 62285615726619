import React, { useMemo } from "react";
import { useDropzone } from "react-dropzone";

const baseStyle = {
  alignItems: "center",
  borderWidth: 2,
  borderRadius: 2,
  borderColor: "#eeeeee",
  borderStyle: "dashed",
  backgroundColor: "  ",
  color: "#bdbdbd",
  outline: "none",
  position: "fixed",
  left: 0,
  top: 0,
  width: "100%",
  height: "100%",
  zIndex: 100000,
  opacity: "0%",
  transition: "all 1 ease",
};

const activeStyle = {
  borderColor: "#2196f3",
};

const acceptStyle = {
  borderColor: "#00e676",
  backgroundColor: "#00e676DD",
  opacity: "100%",
};

const rejectStyle = {
  borderColor: "#ff1744",
};

const StyledDropzone = ({ onDrop, disableMe }) => {
  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({ onDrop });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragActive, isDragReject, isDragAccept]
  );

  return (
    <div className="text-center" onClick={disableMe}>
      <div {...getRootProps({ style })} className=" d-flex align-items-center">
        <input {...getInputProps()} disabled />
        <div className="container justify-content-center align-self-center no-pointer-events">
          <div className="bigRound bg-white shadow-lg mx-5 no-pointer-events m-5 p-5">
            <h1>Drop files here to upload</h1>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StyledDropzone;
