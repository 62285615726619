import React, { Component } from "react";
import Button from "react-bootstrap/Button";

class EndTour extends Component {
  state = {
    tourTime: localStorage.getItem("tour") === "true" ? true : false,
  };

  componentDidUpdate = () => {
    if (this.props.show)
      if (!this.state.tourTime) this.setState({ tourTime: true });
  };

  promptEndTour = () => {
    if (window.confirm("Do you want to end the tour?")) {
      localStorage.setItem("tour", "false");
      localStorage.setItem("tourStep", "0");
      this.setState({ tourTime: false });

      if (this.props.end) this.props.end();
    }
  };

  render() {
    if (this.state.tourTime)
      return (
        <div className="endTour">
          <Button
            id="endTourButton"
            className="endTourButton"
            onClick={this.promptEndTour}
          >
            End Tour
          </Button>
        </div>
      );

    return "";
  }
}

export default EndTour;
