import React, { Component } from "react";
import Nav from "react-bootstrap/Nav";
import SidePills from "./sidePills";
import ProgressBar from "react-bootstrap/ProgressBar";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Link } from "react-router-dom";
import Button from "react-bootstrap/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  faHome,
  faImages,
  faListOl,
  faMailBulk,
  faClipboardList,
  faCog,
  faTruckLoading,
  faStore,
} from "@fortawesome/free-solid-svg-icons";

class sideBar extends Component {
  state = {
    sidebars: [
      {
        id: "Dashboard",
        href: "dashboard",
        active: true,
        icon: <FontAwesomeIcon icon={faHome} style={{ width: "18px" }} />,
      },
      {
        id: "Tiers",
        href: "tiers",
        active: false,
        icon: <FontAwesomeIcon icon={faListOl} style={{ width: "18px" }} />,
      },
      {
        id: "Photosets",
        href: "photosets",
        active: false,
        icon: <FontAwesomeIcon icon={faImages} style={{ width: "18px" }} />,
      },

      {
        id: "E-mail List",
        href: "emailList",
        active: false,
        icon: <FontAwesomeIcon icon={faMailBulk} style={{ width: "18px" }} />,
      },

      {
        id: "Distribution",
        href: "distribution",
        active: false,
        icon: (
          <FontAwesomeIcon icon={faTruckLoading} style={{ width: "18px" }} />
        ),
      },
      {
        id: "Store",
        href: "store",
        active: false,
        icon: <FontAwesomeIcon icon={faStore} style={{ width: "18px" }} />,
      },
      {
        id: "Activities",
        href: "activity",
        active: false,
        icon: (
          <FontAwesomeIcon icon={faClipboardList} style={{ width: "18px" }} />
        ),
      },

      {
        id: "Settings",
        href: "settings",
        active: false,
        icon: <FontAwesomeIcon icon={faCog} style={{ width: "18px" }} />,
      },
    ],
    currentStorage: this.props.currentStorage,
    maxStorage: this.props.maxStorage,
    storageGb: 0,
    storageGbLimit: 0,
    percent: 0,
    showStorage: true,
  };

  componentDidMount() {
    this.setState({
      storageGb: (this.state.currentStorage / 1073741824).toFixed(2),
      storageGbLimit: this.state.maxStorage / 1073741824,
    });

    const percent = Math.round(
      (this.state.currentStorage / this.state.maxStorage) * 100
    );

    this.setState({
      percent: percent,
    });

    if (!this.state.maxStorage) this.setState({ showStorage: false });
  }

  render() {
    return (
      <Nav
        defaultActiveKey="dashboard"
        className="d-none  d-xl-block d-lg-block mt-4"
      >
        <div
          className="nav flex-column nav-pills position-fixed"
          id="v-pills-tab"
          role="tablist"
          aria-orientation="vertical"
        >
          {this.state.sidebars.map((pills) => (
            <SidePills
              key={pills.id}
              id={pills.id}
              href={pills.href}
              active={pills.active}
              icon={pills.icon}
            />
          ))}

          {this.state.showStorage ? (
            <Nav.Item className="pt-3 text-secondary">
              <div
                style={{ fontSize: "0.7rem" }}
                className="text-left text-secondary"
              >
                Storage
              </div>
              <ProgressBar style={{ height: "5px" }} now={this.state.percent} />
              <Row style={{ fontSize: "0.7rem" }} className="text-secondary">
                <Col className="text-left">{this.state.storageGb} GB</Col>
                <Col className="text-right">{this.state.storageGbLimit} GB</Col>
              </Row>
            </Nav.Item>
          ) : (
            <Link to="/subscriptions">
              <Button variant="outline-primary" className="mt-3 w-100">
                Subscribe
              </Button>
            </Link>
          )}
        </div>
      </Nav>
    );
  }
}

export default sideBar;
