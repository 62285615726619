import React, { Component } from "react";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Nav from "react-bootstrap/Nav";
import History from "../../utils/history";
import { NavLink, Router, Switch, Route } from "react-router-dom";

import { NotificationContainer } from "react-notifications";

import "../../admin.css";

import AdminDashboard from "./adminDashboard";
import AdminSettings from "./adminSettings";
import AdminUsers from "./adminUsers";

class AdminLayout extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <Router history={History}>
        <NotificationContainer />
        <Row className=" min100vh max100vh  w-100 mr-0 ml-0">
          <Col md="2" sm="2" className="min100vh max-100vh text-dark pl-0">
            <div
              className="  min100vh max100vh "
              style={{ backgroundColor: "#EEEEEE" }}
            >
              <Nav defaultActiveKey="dashboard">
                <NavLink
                  exact
                  className="adminNav"
                  data-toggle="adminNav-active"
                  to="/dashboard"
                  role="tab"
                >
                  Dashboard
                </NavLink>
                <NavLink
                  exact
                  className="adminNav"
                  data-toggle="adminNav-active"
                  to="/users"
                  role="tab"
                >
                  Users
                </NavLink>
                <NavLink
                  exact
                  className="adminNav"
                  data-toggle="adminNav-active"
                  to="/settings"
                  role="tab"
                >
                  Settings
                </NavLink>

                <div
                  className="adminNav"
                  style={{ color: "#999999" }}
                  onClick={() => {
                    localStorage.setItem("adminMode", "false");
                    History.push("/");
                    window.location.reload(false);
                  }}
                >
                  Go Back to User Mode
                </div>

                <div
                  className="adminNav text-danger logout"
                  onClick={() => this.props.logout()}
                >
                  Sign Out
                </div>
              </Nav>
            </div>
          </Col>
          <Col xs="10" className="fancyScroll inheritMaxHeight pr-0 pl-0">
            <Switch>
              <Route
                path="/dashboard"
                render={(props) => (
                  <AdminDashboard
                    {...props}
                    token={this.props.token}
                    AWS={this.props.AWS}
                  />
                )}
              />
              <Route
                path="/settings"
                render={(props) => (
                  <AdminSettings {...props} token={this.props.token} />
                )}
              />

              <Route
                path="/users"
                render={(props) => (
                  <AdminUsers
                    {...props}
                    token={this.props.token}
                    AWS={this.props.AWS}
                  />
                )}
              />
            </Switch>
          </Col>
        </Row>
      </Router>
    );
  }
}

export default AdminLayout;
