import React, { Component } from "react";

import Form from "react-bootstrap/Form";
import TierDropdownPill from "../tierDropdownPill";

class EmailListItem extends Component {
  state = {
    disabled: "",
    editNote: false,
    note: this.props.data.note,
    email: this.props.data.email,
    editEmail: false,
    tierRelId: this.props.data.tier_rel_id,
    tierData: this.props.tierData,
    emailList: [this.props.data.email],
    customId: this.props.customId,
  };

  _isMounted = false;

  componentDidMount() {
    this._isMounted = true;
  }

  // handleDeletion = () => {
  //   if (this._isMounted) this.setState({ disabled: "disabled" });
  //   this.props.onDel(this.props.data.id, this.props.index);
  // };

  toggleEditNote = () => {
    if (this._isMounted) this.setState({ editNote: true });
  };

  toggleEditEmail = () => {
    if (this._isMounted) this.setState({ editEmail: true });
  };

  handleEditNote = (e) => {
    if (this._isMounted) this.setState({ note: e.target.value });
  };
  handleEditEmail = (e) => {
    if (this._isMounted) this.setState({ email: e.target.value });
  };

  submitEditNote = (e) => {
    e.preventDefault();

    if (this.state.note === "") {
      this.setState({ note: "No notes." });
    }

    const response = this.props.submitNote(this.props.data.id, this.state.note);

    if (response) {
      if (this._isMounted) this.setState({ editNote: false });
    }
  };

  submitEditEmail = (e) => {
    e.preventDefault();

    const response = this.props.submitEmail(
      this.props.data.id,
      this.state.email
    );

    if (response) {
      if (this._isMounted) this.setState({ editEmail: false });
    }
  };

  handleTierChange = (color, id, name) => {
    console.log(color);
    console.log(id);
    console.log(name);

    this.props.submitTier(this.props.data.id, id);
    return;
  };

  render() {
    return (
      <React.Fragment key={this.props.data.id}>
        <tr
          key={"tr" + this.props.id}
          className={this.state.disabled + " text-left"}
        >
          <td>
            <Form.Check
              name="emailCheckbox"
              id={"checkbox-" + this.state.customId}
              aria-label="option 1"
              onClick={() => this.props.toggleSelect(this.props.data)}
            />
          </td>
          <td className="col-xs-1" id={"td-0-" + this.state.customId}>
            <TierDropdownPill
              token={this.props.token}
              action={this.handleTierChange}
              className="m-3"
              defaultValue={this.state.tierRelId}
              tierData={this.state.tierData}
              customId={this.state.customId}
            />
          </td>
          <td onClick={this.toggleEditEmail}>
            {!this.state.editEmail ? (
              <small>
                <span
                  className="pointer text-secondary"
                  id={"email-1-" + this.state.customId}
                >
                  {this.state.email}
                </span>
              </small>
            ) : (
              <div>
                <Form onSubmit={this.submitEditEmail}>
                  <Form.Control
                    type="email"
                    size="sm"
                    required
                    onChange={this.handleEditEmail}
                    defaultValue={this.state.email}
                  />
                </Form>
              </div>
            )}
          </td>
          <td
            onClick={this.toggleEditNote}
            id={"notes-2-" + this.state.customId}
          >
            {!this.state.editNote ? (
              <small>
                <span className="pointer">{this.state.note}</span>{" "}
              </small>
            ) : (
              <div>
                <Form onSubmit={this.submitEditNote}>
                  <Form.Control
                    type="text"
                    size="sm"
                    onChange={this.handleEditNote}
                    defaultValue={this.state.note}
                  />
                </Form>
              </div>
            )}
          </td>
          {/* <td className="text-right">
            <Link
              to={{
                pathname: "/distribution/add",
                state: { emailList: this.state.emailList },
              }}
            >
              <Button
                variant="outline-secondary"
                size="sm"
                className=" btn-sm mr-2"
              >
                Send Rewards
              </Button>
            </Link>

            <Button
              index={this.props.data.id}
              className=" btn-danger btn-sm mr-2 "
              onClick={this.handleDeletion}
            >
              <FontAwesomeIcon icon={faTimesCircle} />
            </Button>
          </td> */}
        </tr>
      </React.Fragment>
    );
  }
}

export default EmailListItem;
