import React, { Component } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import { PayPalButton } from "react-paypal-button-v2";
import Collapse from "react-bootstrap/Collapse";
import { Redirect, Switch } from "react-router-dom";
import { NotificationManager } from "react-notifications";
import Button from "react-bootstrap/Button";
//import Spinner from "react-bootstrap/Spinner";
import Modal from "react-bootstrap/Modal";

class Subscriptions extends Component {
  _isMounted = false;
  newWindow = null;
  url = null;
  constructor(props) {
    super(props);
    this.state = {
      selectedBool: false,
      redirect: false,
      selected: "",
      subscribing: false,
      selectedId: 0,
      selectedCode: "",
      selectedCss: "",
      tier: "",
      subLength: "monthly",
      prices: [0.99, 9.99, 19.99, 49.99],
      token: null,
      codes: {
        monthly: {
          1: "P-0A567403AE014523SL2SV5VA",
          2: "P-5EA92592SF6216803L2SUW7Q",
          3: "P-4B300478WA858405NL2SUXUA",
          4: "P-74W54867R5834763SL2SVXQI",
        },
        annual: {
          1: "P-5MN41327MW5214611L2472CI",
          2: "P-6G584562CL687962ML2472QY",
          3: "P-2DV6233730682072VL2474CQ",
          4: "P-8TK44277WJ223641DL2474PA",
        },
      },
      css: {
        1: "starter-subscription-border-wrap border-0 p-2 font-weight-bold rounded text-white",
        2: "basic-subscription-border-wrap border-0 p-2 font-weight-bold  rounded text-white",
        3: "plus-subscription-border-wrap border-0 p-2 font-weight-bold  rounded  text-white",
        4: "premium-subscription-border-wrap border-0 p-2 font-weight-bold  rounded text-white",
      },
      successMessage: "Congratulations! You are now subscribed to ",
      reviseMode: false,
    };
  }

  checkIfClosed = () => {
    if (this.newWindow) {
      try {
        console.log(this.newWindow.postMessage("onApprove", this.url));
      } catch (err) {
        console.log("CORB");
      }

      //console.log(this.newWindow.postMessage("name"));
      if (this.newWindow.closed) {
        this.setState({
          successMessage:
            "You have attempted to update your subscription. If successful, your new subscription will be reflected next billing cycle.",
          redirect: true,
        });
      } else {
        setTimeout(this.checkIfClosed, 500);
      }
    }
  };

  popupCenter = ({ url, title, w, h }) => {
    // Fixes dual-screen position                             Most browsers      Firefox
    const dualScreenLeft =
      window.screenLeft !== undefined ? window.screenLeft : window.screenX;
    const dualScreenTop =
      window.screenTop !== undefined ? window.screenTop : window.screenY;

    const width = window.innerWidth
      ? window.innerWidth
      : document.documentElement.clientWidth
      ? document.documentElement.clientWidth
      : window.screen.width;
    const height = window.innerHeight
      ? window.innerHeight
      : document.documentElement.clientHeight
      ? document.documentElement.clientHeight
      : window.screen.height;

    const systemZoom = width / window.screen.availWidth;
    const left = (width - w) / 2 / systemZoom + dualScreenLeft;
    const top = (height - h) / 2 / systemZoom + dualScreenTop;
    this.newWindow = window.open(
      url,
      title,
      `
      scrollbars=yes,
      width=${w / systemZoom}, 
      height=${h / systemZoom}, 
      top=${top}, 
      left=${left}
      `
    );

    if (this.newWindow.focus) this.newWindow.focus();
  };

  componentDidMount = () => {
    this._isMounted = true;
    const subscription = this.props.user[
      "https://www.shieldshot.com/subscription"
    ];

    if (parseInt(subscription) !== 0) {
      this.setState({ reviseMode: true });
    }
  };

  changeSubscription = async () => {
    const token = await this.props.token();
    // NotificationManager.success(
    //   "Subscription successful. Redirecting to the dashboard.",
    //   "Congratulations!",
    //   3000
    // );

    this.setState({ subscribing: true });

    // OPTIONAL: Call your server to save the subscription
    const response = await fetch(
      "https://api.shieldshot.com/users/subscribe",
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          type: "revise",
          planID: this.state.selectedCode,
        }),
      }
    );

    if (response.status === 200) {
      //alert("revise request success");
      const responseJson = await response.json();
      // this.newWindow = window.open(
      //   responseJson.url,
      //   "Change Subscription",
      //   "height=600,width=500"
      // );

      this.url = responseJson.url;

      this.popupCenter({
        url: responseJson.url,
        title: "Change subscription",
        w: 500,
        h: 600,
      });
      this.checkIfClosed();
      // if (this.newWindow.focus) {

      //   this.newWindow.focus();
      // }
    } else {
      alert("uh oh, we can't access our servers!");
    }
  };

  componentWillUnmount() {
    this._isMounted = false;
    if (this.newWindow) this.newWindow.close();
  }

  handleSubscription = async (tier, tierId) => {
    //set selected tier
    this.setState({
      selected: tier,
      selectedId: tierId,
      selectedCode: this.state.codes[this.state.subLength][tierId],
      selectedBool: true,
      selectedCss: this.state.css[tierId],
      successMessage:
        "Congratulations! You are now upgraded to " + tier + " status!",
    });
  };

  toggleLength = () => {
    let length;
    let price;
    const price1 = [0.99, 9.99, 19.99, 49.99];
    const price2 = [9.99, 99.99, 199.99, 499.99];

    console.log(this.state.subLength);
    if (this.state.subLength === "monthly") {
      length = "annual";
      price = price2;
    } else {
      length = "monthly";
      price = price1;
    }
    if (this._isMounted) {
      this.setState({ subLength: length, prices: price });
      if (this.state.selectedId !== 0) {
        this.setState({
          selectedCode: this.state.codes[length][this.state.selectedId],
        });
      }
    }

    // console.log(this.state.subLength);
  };

  render() {
    return (
      <>
        <Modal
          size="lg"
          show={this.state.subscribing}
          style={{ marginTop: "10%" }}
        >
          <Modal.Header></Modal.Header>
          <Modal.Body className="text-center p-5">
            <h3 className="text-primary">Transaction Ongoing...</h3>
            <h5 className="text-secondary">
              A paypal window will pop-up. Please close it when you are done.
            </h5>
            <p className="mt-5 text-secondary">
              This will automatically close when you're done.
            </p>
          </Modal.Body>
        </Modal>
        <Row className="mt-5 pt-5 mx-auto w-100">
          <Col lg={10} md={10} xl={8} className="mx-auto">
            <Row className="mb-5 ">
              <Col>
                <h3> Choose a Subscription that's perfect for your needs</h3>

                <div>
                  <Row className="mx-auto text-center">
                    <Col md={5}></Col>
                    <Col md={1}>Monthly</Col>
                    <Col md={1}>
                      <Form.Check
                        type="switch"
                        id="custom-switch"
                        label="Annual"
                        onChange={this.toggleLength}
                      />
                    </Col>
                    <Col md={5}></Col>
                  </Row>
                  <Row>
                    <Col>
                      <small>Annual Subscriptions get 2 months for free!</small>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
            <Row>
              <Col sm={12} md={6} lg={3}>
                <div
                  className="starter-subscription-border-wrap rounded glow-yellow pointer w-100 mb-2"
                  onClick={this.handleSubscription.bind(null, "Starter", 1)}
                  tabIndex="-1"
                >
                  <div className="starter-subscription rounded w-100">
                    <h5>Starter</h5>
                    <h6 className="text-primary">USD {this.state.prices[0]}</h6>
                    <hr />
                    <small>
                      <div>
                        <div>5 Gigabytes of storage</div>
                        <div>100 Photos per month</div>
                        <div>20 E-mails per month</div>
                      </div>
                    </small>
                  </div>
                  <div className="m-3 text-white font-weight-bold">Try it!</div>
                </div>
                <small>* First month is free! Cancel anytime</small>
              </Col>
              <Col sm={12} md={6} lg={3}>
                <div
                  className="basic-subscription-border-wrap rounded  glow-yellow pointer  mb-2"
                  onClick={this.handleSubscription.bind(null, "Basic", 2)}
                  tabIndex="-1"
                >
                  <div className="basic-subscription rounded">
                    <h5>Basic</h5>
                    <h6 className="text-primary">USD {this.state.prices[1]}</h6>
                    <hr />
                    <small>
                      <div>
                        <div>30 Gigabytes of storage</div>
                        <div>1000 Photos per month</div>
                        <div>500 E-mails per month</div>
                      </div>
                    </small>
                  </div>
                  <div className="m-3 text-white font-weight-bold">
                    Subscribe Now
                  </div>
                </div>
              </Col>
              <Col sm={12} md={6} lg={3}>
                <div
                  className="plus-subscription-border-wrap rounded  glow-yellow pointer  mb-2"
                  onClick={this.handleSubscription.bind(null, "Plus", 3)}
                  tabIndex="-1"
                >
                  <div className="plus-subscription rounded">
                    <h5>Plus</h5>
                    <h6 className="text-primary">USD {this.state.prices[2]}</h6>
                    <hr />
                    <small>
                      <div>
                        <div>100 Gigabytes of storage</div>
                        <div>Unlimited Photos per month</div>
                        <div>Send 5000 E-mails per month</div>
                      </div>
                    </small>
                  </div>
                  <div className="m-3 text-white font-weight-bold">
                    Subscribe Now
                  </div>
                </div>
              </Col>
              <Col sm={12} md={6} lg={3}>
                <div
                  className="premium-subscription-border-wrap rounded glow-yellow pointer  mb-2"
                  onClick={this.handleSubscription.bind(null, "Premium", 4)}
                  tabIndex="-1"
                >
                  <div className="premium-subscription rounded">
                    <h5>Premium</h5>
                    <h6 className="text-primary">USD {this.state.prices[3]}</h6>
                    <hr />
                    <small>
                      <div>
                        <div>1000 Gigabytes of storage</div>
                        <div>Unlimited Photos per month</div>
                        <div>Unlimited E-mails per month</div>
                      </div>
                    </small>
                  </div>
                  <div className="m-3 text-white font-weight-bold">
                    Best Value!
                  </div>
                </div>
              </Col>
            </Row>

            {this.state.redirect ? (
              <Switch>
                <Redirect
                  to={{
                    pathname: "/dashboard",
                    state: {
                      alertMessage: this.state.successMessage,
                      alertType: "success",
                    },
                  }}
                />
              </Switch>
            ) : (
              ""
            )}

            <Collapse in={this.state.selectedBool}>
              <Row>
                <Col className="text-center pt-3 ">
                  You have selected
                  <br />
                  <br />
                  <span
                    className={this.state.selectedCss}
                    style={{ fontSize: "1.5rem" }}
                  >
                    {this.state.selected} / {this.state.subLength}
                  </span>
                  <br />
                  <br />
                  <small>Check-out by clicking the link below.</small>
                  <div className="d-flex justify-content-center">
                    <div>
                      {this.state.reviseMode ? (
                        <>
                          {!this.state.subscribing ? (
                            <Button
                              variant="primary"
                              onClick={this.changeSubscription}
                            >
                              Change Subscription
                            </Button>
                          ) : (
                            <p>
                              A window will pop-up. Please close it when you are
                              done.
                            </p>
                          )}
                        </>
                      ) : (
                        <PayPalButton
                          options={{
                            vault: true,
                            clientId:
                              "AS_3cAEbiMNUaxm5Uu6ma3mEYefoquq-PUlEI3IXTDgBAf0DoPGuXhR5zLKeAO2wbUVmWUm6evgnGDHq",
                          }}
                          createSubscription={(data, actions) => {
                            return actions.subscription.create({
                              plan_id: this.state.selectedCode,
                            });
                          }}
                          onApprove={(data, actions) => {
                            // Capture the funds from the transaction

                            return actions.subscription
                              .get()
                              .then(async (details) => {
                                const token = await this.props.token();
                                //alert("Subscription completed");
                                NotificationManager.warning(
                                  "Subscription successful. Redirecting to the dashboard.",
                                  "Congratulations!",
                                  3000
                                );

                                // OPTIONAL: Call your server to save the subscription
                                const response = await fetch(
                                  "https://api.shieldshot.com/users/subscribe",
                                  {
                                    method: "POST",
                                    headers: {
                                      Authorization: `Bearer ${token}`,
                                    },
                                    body: JSON.stringify({
                                      orderID: data.orderID,
                                      subscriptionID: data.subscriptionID,
                                      subEndDate:
                                        details.billing_info.next_billing_time,
                                      planID: this.state.selectedCode,
                                      type: "subscribe",
                                    }),
                                  }
                                );

                                if (response.status === 200) {
                                  this.setState({ redirect: true });
                                  window.location.replace("/dashboard");
                                } else {
                                  alert("uh oh, we can't access our servers!");
                                }
                              });
                          }}
                        />
                      )}
                    </div>
                  </div>
                </Col>
              </Row>
            </Collapse>

            <Row className="d-flex justify-content-center">
              <Col>
                <div className="d-flex justify-content-center"></div>
              </Col>
            </Row>

            <Row className="d-none">
              <Col>
                <div className="starter-subscription-border-wrap rounded">
                  <div className="starter-subscription rounded">
                    <h5>Starter</h5>
                    <h6>USD 9.99</h6>
                    <hr />
                    <small>
                      <div>
                        <div>5 Gigabytes of storage</div>
                        <div>100 Photos per month</div>
                        <div>20 E-mails per month</div>
                      </div>
                    </small>
                  </div>
                  <div className="m-3 text-white font-weight-bold">
                    Subscribe Now
                  </div>
                </div>
              </Col>
              <Col>
                <div className="basic-subscription-border-wrap rounded">
                  <div className="basic-subscription rounded">
                    <h5>Basic</h5>
                    <h6>USD 99.99</h6>
                    <hr />
                    <small>
                      <div>
                        <div>30 Gigabytes of storage</div>
                        <div>1000 Photos per month</div>
                        <div>500 E-mails per month</div>
                      </div>
                    </small>
                  </div>
                  <div className="m-3 text-white font-weight-bold">
                    Subscribe Now
                  </div>
                </div>
              </Col>
              <Col>
                <div className="plus-subscription-border-wrap rounded">
                  <div className="plus-subscription rounded">
                    <h5>Plus</h5>
                    <h6>USD 199.99</h6>
                    <hr />
                    <small>
                      <div>
                        <div>100 Gigabytes of storage</div>
                        <div>Unlimited Photos per month</div>
                        <div>Send 5000 E-mails per month</div>
                      </div>
                    </small>
                  </div>
                  <div className="m-3 text-white font-weight-bold">
                    Subscribe Now
                  </div>
                </div>
              </Col>
              <Col>
                <div className="premium-subscription-border-wrap rounded">
                  <div className="premium-subscription rounded">
                    <h5>Premium</h5>
                    <h6>USD 499.99</h6>
                    <hr />
                    <small>
                      <div>
                        <div>1000 Gigabytes of storage</div>
                        <div>Unlimited Photos per month</div>
                        <div>Unlimited E-mails per month</div>
                      </div>
                    </small>
                  </div>
                  <div className="m-3 text-white font-weight-bold">
                    Best Value!
                  </div>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </>
    );
  }
}

export default Subscriptions;
