import React, { Component } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Table from "react-bootstrap/Table";
import Spinner from "react-bootstrap/Spinner";

import EmailListItem from "./emailListItem";
import EmailListImport from "./emailListImport";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import TierDropdown from "../parts/tierDropdown";
import { NotificationManager } from "react-notifications";
import Modal from "react-bootstrap/Modal";
import { Link } from "react-router-dom";
import Collapse from "react-bootstrap/Collapse";
import History from "../../utils/history";

import { confirm } from "../confirmation";

import { CSVLink } from "react-csv";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import Tour from "reactour";
import EndTour from "../parts/endTour";

import {
  faFileExcel,
  faExclamationTriangle,
  faList,
  faDownload,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";

class EmailList extends Component {
  _isMounted = false;
  state = {
    emailList: [],
    id: "",
    AWS: null,
    email: "",
    tier: 0,
    note: "",
    tiers: [],
    iTiers: [],
    modified: false,
    isUploading: true,
    message: "",
    init: true,
    FormTier: 0,
    showModal: false,
    simpleTiers: [],
    isDeleting: false,
    tableSize: "md",
    filteredEmailList: [],
    filter: "",
    csvList: [],
    tourTime: localStorage.getItem("tour") === "true" ? true : false,
    subStep: 0,
    selectedEmails: [],
    selectedEmailIds: [],
    selectedEmailIndexes: [],
    typeStep: 0,
    selectAll: false,
  };

  i = 0;

  typeWriterName(txt) {
    console.log("typing...");
    let name = this.state.email;
    if (this.i < txt.length) {
      name = name + txt.charAt(this.i);
      this.i++;
      if (this._isMounted) this.setState({ email: name });
      setTimeout(() => this.typeWriterName(txt), 10);
    }
  }

  componentDidMount(props) {
    this._isMounted = true;
    window.scrollTo(0, 0);

    if (
      localStorage.getItem("emailList") &&
      localStorage.getItem("emailListTiers")
    ) {
      this.setState({
        tiers: JSON.parse(localStorage.getItem("emailListTiers")),
        emailList: JSON.parse(localStorage.getItem("emailList")),
        iTiers: JSON.parse(localStorage.getItem("emailListiTiers")),
        simpleTiers: JSON.parse(localStorage.getItem("emailListSimpleTiers")),
        csvList: JSON.parse(localStorage.getItem("emailListCsvList")),
        init: false,
        isUploading: false,
      });
    }

    // console.log(this.state.csvList);

    if (localStorage.getItem("emailListTableSize")) {
      this.setState({
        tableSize: localStorage.getItem("emailListTableSize"),
      });
    }

    this.getEmailListEndpoint();
  }

  toggleSelect = (data) => {
    let selectedEmails = this.state.selectedEmails;
    let selectedEmailIds = this.state.selectedEmailIds;
    let selectedEmailIndexes = this.state.selectedEmailIndexes;

    const indx = selectedEmailIds.indexOf(data.id);

    if (this.state.selectAll) {
      this.setState({ selectAll: false });
    }

    if (indx === -1) {
      selectedEmailIds.push(data.id);
      selectedEmails.push(data.email);
      selectedEmailIndexes.push(data);
    } else {
      selectedEmails.splice(indx, 1);
      selectedEmailIds.splice(indx, 1);
      selectedEmailIndexes.splice(indx, 1);
    }

    if (
      selectedEmails.length === this.state.emailList.length &&
      selectedEmails.length !== 0
    )
      this.setState({ selectAll: true });

    this.setState({
      selectedEmailIds: selectedEmailIds,
      selectedEmailIndexes: selectedEmailIndexes,
      selectedEmails: selectedEmails,
    });
  };

  getEmailListEndpoint = async () => {
    console.log("endpoint", this.state.emailList.length);

    try {
      const token = await this.props.token();
      //const body = JSON.stringify({ type: "emails" });
      const response = await fetch(
        "https://api.shieldshot.com/emails/list",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          //body: body,
          method: "POST",
        }
      );

      if (this._isMounted) {
        const responseData = await response;

        if (responseData.status === 200) {
          const responseJson = await responseData.json();

          let list = [];
          let simpleList = [];

          responseJson.tierlist.forEach((item) => {
            //
            let list_item = {
              title: item.rel_id.N,
              rel_id: item.rel_id.N,
              nickname: item.tierName.S,
              color: item.color.S,
              parentTier: item.parentTier.S,
            };

            list.push(list_item);
            simpleList.push(item.tierName.S);
          });

          let ztier = {};

          list.forEach((list) => {
            let id = list.rel_id;
            ztier[id] = list;
          });

          this.setState({
            tiers: list,
            iTiers: ztier,
            isUploading: false,
            simpleTiers: simpleList,
          });

          localStorage.setItem("emailListTiers", JSON.stringify(list));
          localStorage.setItem("emailListiTiers", JSON.stringify(ztier));
          localStorage.setItem(
            "emailListSimpleTiers",
            JSON.stringify(simpleList)
          );

          let emailList = [];
          let csvList = [["email", "tier", "note"]];

          responseJson.emaillist.forEach((item) => {
            let tier = parseInt(item.tier.S);

            const tier_rel_id = this.state.iTiers[tier].rel_id;
            const tcolor = this.state.iTiers[tier].color;
            const tn = this.state.iTiers[tier].nickname;

            //
            let list_item = {
              id: item.id.S,
              note: item.note.S,
              tier: tn,
              tier_rel_id: tier_rel_id,
              email: item.email.S,
              color: tcolor,
            };

            emailList.push(list_item);
            csvList.push([item.email.S, tn, item.note.S]);
          });

          localStorage.setItem("emailList", JSON.stringify(emailList));
          localStorage.setItem("emailListCsvList", JSON.stringify(csvList));

          this.setState({
            emailList: emailList,
            isLoading: false,
            count: emailList.length,
            init: false,
            isUploading: false,
            csvList: csvList,
          });
        }

        // console.log(this.state.csvList);
      } else {
        console.log("there's a problem with the endpoint");
      }
    } catch (err) {
      console.log("Error getting photoset list from endpoint", err);
    }
  };

  handleEmail = (e) => {
    this.setState({ email: e.target.value });
  };

  handleTierChange = (color, id, name, tierId) => {
    if (this._isMounted)
      this.setState({ FormTier: id, TierColor: color, TierName: name });
    this.modified();
  };

  modified = (status = true) => {
    this.setState({ modified: status });
  };

  deleteAllEmails = async () => {
    if (await confirm("Are you sure you want to delete all emails?")) {
      const token = await this.props.token();

      this.setState({ isDeleting: true });

      try {
        const body = JSON.stringify({
          bulk: true,
        });

        const response = await fetch(
          "https://api.shieldshot.com/emails/delete",
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
            body: body,
            method: "POST",
          }
        );

        const responseData = await response;

        if (responseData.status === 200) {
          console.log("successful deletion");
          NotificationManager.success("Successful Deletion", "", 10000);
          if (this._isMounted)
            this.setState({ emailList: [], isDeleting: false });
        } else {
          if (this._isMounted) this.setState({ isDeleting: false });
          console.log("failure on endpoint", responseData.status);
          NotificationManager.warning(
            "Error: " + responseData.status,
            "Uh oh, something went wrong",
            10000
          );
        }
      } catch (err) {
        if (this._isMounted) this.setState({ isDeleting: false });
        console.log("Error on deletion", err);
        NotificationManager.warning(
          "Error.",
          "Uh oh, something went wrong",
          10000
        );
      }
    }
  };

  emailDeleteEndpoint = async (id, index) => {
    console.log("delete endpoint", this.state.emailList.length);
    try {
      const token = await this.props.token();

      const body = JSON.stringify({
        emailId: id,
      });

      const response = await fetch(
        "https://api.shieldshot.com/emails/delete",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          body: body,
          method: "POST",
        }
      );

      const responseData = await response;

      if (responseData.status === 200) {
        console.log("successful deletion");
        let emailList = this.state.emailList;
        emailList.splice(index, 1);
        if (this._isMounted) this.setState({ emailList: emailList });
      } else {
        console.log("failure on endpoint", responseData.status);
      }
    } catch (err) {
      console.log("Error on deletion", err);
    }
  };

  emailDeleteEndpointV2 = async (i = 0) => {
    console.log("delete endpoint v2", this.state.emailList.length);
    try {
      let selectedEmailIds = this.state.selectedEmailIds;
      let selectedEmailIndexes = this.state.selectedEmailIndexes;
      console.log("i", i);
      if (selectedEmailIndexes.length > i) {
        this.setState({ isDeleting: true });

        const id = selectedEmailIds[i];
        this.del(id, i);
      } else {
        let csvList = [["email", "tier", "note"]];

        let emailList = this.state.emailList;
        this.state.emailList.forEach((item, index) => {
          let tier = parseInt(item.tier_rel_id);
          const tn = this.state.iTiers[tier].nickname;

          csvList.push([item.email.S, tn, item.note.S]);
        });

        this.state.selectedEmailIndexes.forEach((item) => {
          const index = emailList.indexOf(item);
          if (index !== -1) emailList.splice(index, 1);
        });

        if (this._isMounted) {
          this.setState({
            isDeleting: false,
            csvList: csvList,
            selectedEmails: [],
            selectedEmailIds: [],
            selectedEmailIndexes: [],
            emailList: emailList,
          });
          localStorage.setItem("emailList", JSON.stringify(emailList));
        }
      }
    } catch (err) {
      console.log("Error on deletion", err);
      if (this._isMounted) this.setState({ isDeleting: false });
    }
  };

  del = async (id, i) => {
    console.log("del", this.state.emailList.length);
    const token = await this.props.token();
    const body = JSON.stringify({
      emailId: id,
    });

    const response = await fetch(
      "https://api.shieldshot.com/emails/delete",
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: body,
        method: "POST",
      }
    );

    const responseData = await response;

    if (responseData.status === 200) {
      this.emailDeleteEndpointV2(i + 1);
      console.log("deleted");
    } else {
      console.log("failure on endpoint", responseData.status);
    }
  };

  submitEditNote = async (id, notes) => {
    try {
      const token = await this.props.token();

      const body = JSON.stringify({
        emailId: id,
        data: notes,
        type: "note",
      });

      const response = await fetch(
        "https://api.shieldshot.com/emails/update",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          body: body,
          method: "POST",
        }
      );

      const responseData = await response;

      if (responseData.status === 200) {
        console.log("successful update");
        return true;
      } else {
        console.log("failure on endpoint", responseData.status);
        return false;
      }
    } catch (err) {
      console.log("Error on connection", err);
      return false;
    }
  };

  submitEditEmail = async (id, email) => {
    try {
      const token = await this.props.token();

      const body = JSON.stringify({
        emailId: id,
        data: email,
        type: "email",
      });

      const response = await fetch(
        "https://api.shieldshot.com/emails/update",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          body: body,
          method: "POST",
        }
      );

      const responseData = await response;

      if (responseData.status === 200) {
        console.log("successful update");
        return true;
      } else {
        console.log("failure on endpoint", responseData.status);
        return false;
      }
    } catch (err) {
      console.log("Error on connection", err);
      return false;
    }
  };

  submitEditTier = async (id, tier) => {
    console.log("tier", tier);

    try {
      const token = await this.props.token();

      const body = JSON.stringify({
        emailId: id,
        data: tier,
        type: "tier",
      });

      const response = await fetch(
        "https://api.shieldshot.com/emails/update",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          body: body,
          method: "POST",
        }
      );

      const responseData = await response;

      if (responseData.status === 200) {
        console.log("successful update");
        return true;
      } else {
        console.log("failure on endpoint", responseData.status);
        return false;
      }
    } catch (err) {
      console.log("Error on connection", err);
      return false;
    }
  };

  checkAll = () => {
    console.log("CheckAll", this.state.emailList.length);

    let selectAll = this.state.selectAll;

    let checkboxes = document.getElementsByName("emailCheckbox");

    let i, n;

    if (!selectAll) {
      for (i = 0, n = checkboxes.length; i < n; i++) {
        if (!checkboxes[i].checked) checkboxes[i].click();
      }
    } else {
      for (i = 0, n = checkboxes.length; i < n; i++) {
        if (checkboxes[i].checked) checkboxes[i].click();
      }
    }
  };

  submitEmailEndpoint = async (e) => {
    console.log("submit email endpoint", this.state.emailList.length);
    if (e) e.preventDefault();
    document.getElementById("tour-2").focus();

    const token = await this.props.token();

    if (this.state.email === "") {
      NotificationManager.warning("Email is blank", "Oops", 10000);
    } else {
      console.log("email submission", this.state.FormTier);

      const body = JSON.stringify({
        email: this.state.email,
        tier: this.state.FormTier.toString(),
        note: "No notes",
        // type: "email",
      });

      this.setState({ email: "" });

      NotificationManager.success("Email added", "", 5000);

      try {
        const response = await fetch(
          "https://api.shieldshot.com/emails/add",
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
            body: body,
            method: "POST",
          }
        );

        const responseData = await response;
        if (responseData.status === 200) {
          //this.getEmailList();
          this.getEmailListEndpoint();

          if (this._isMounted) {
            this.setState({ isUploading: false, email: "", subStep: 1 });
            if (localStorage.getItem("tour") === "true")
              this.setState({ tourTime: true });
          }
        } else {
          console.log("failed to add email");
          NotificationManager.error(
            "Error: " + responseData.status,
            "Failed to add Email",
            10000
          );
        }
      } catch (err) {
        NotificationManager.error(
          "Error: " + err,
          "Failed to add Email",
          10000
        );
      }
    }
  };

  render() {
    let steps;
    if (this.state.subStep === 0)
      steps = [
        {
          content:
            "Welcome to the Email List Page. You can manage emails here.",
        },
        {
          selector: "#tour-1",
          content: "You can add emails here",
        },
        {
          selector: "#tour-2",
          content: "Type email here",
          action: () => {
            if (this.state.typeStep === 0) {
              this.typeWriterName("tutorial@shieldshot.com");
              this.setState({ typeStep: 1 });
            }
          },
        },
        {
          selector: "#tour-3",
          content: "Select the tier for that email",
        },
        {
          selector: "#tour-4",
          content: "Click this button to submit the email",
        },
        {
          content: "Wait for the email to be submitted...",
          action: () => {
            if (this.state.typeStep === 1) {
              this.submitEmailEndpoint();
              this.setState({ typeStep: 2 });
            }
          },
        },
      ];
    else
      steps = [
        {
          content:
            "Welcome to the Email List Page. You can manage emails here.",
        },
        {
          selector: "#tour-1",
          content: "You can add emails here",
        },
        {
          selector: "#tour-2",
          content: "Type email here",
        },
        {
          selector: "#tour-3",
          content: "Select the tier for that email",
        },
        {
          selector: "#tour-4",
          content: "Click this button to submit the email",
        },
        {
          selector: "#tour-5",
          content: "Your newly added email will show up here",
        },
        {
          selector: "#tierPill0",
          content: "You can change the email's tier by clicking this button",
        },
        {
          selector: "#email-1-0",
          content: "Edit an email by clicking the email.",
        },
        {
          selector: "#notes-2-0",
          content: "Add or edit notes by clicking the notes",
        },
        {
          selector: "#checkbox-0",
          content:
            "You can select or deselect multiple emails by clicking this checkbox",
          action: () => {
            if (this.state.selectedEmails.length === 0) {
              document.getElementById("checkbox-0").checked = true;
              this.setState({ selectedEmails: [0, 1, 2] });
            }
          },
        },
        {
          selector: "#collapseArea",
          content:
            "Notice this top part has changed. These are the actions you can do with the selected emails. The send rewards button will take you to the distributions page with the emails you selected. Delete emails is... you know.",
        },
        {
          content: "Let's look at the other actions next.",
          action: () => {
            if (this.state.selectedEmails.length !== 0) {
              document.getElementById("checkbox-0").checked = false;
              this.setState({ selectedEmails: [] });
            }
          },
        },
        {
          selector: "#tour-7",
          content:
            "Click this to change email list density. Useful if you want to see more emails in the page.",
        },
        {
          selector: "#tour-8",
          content: "Download a CSV file of your email list",
        },
        {
          selector: "#tour-9",
          content: "Delete all your emails with this button",
        },
        {
          selector: "#tour-10",
          content:
            "Import a CSV file. This will be very useful if you want to add a lot of emails and have a CSV file from patreon, or if you made your own CSV file. ",
        },
        {
          content: "Let's take a quick look at Importing a CSV file right now.",
          action: () => {
            if (!this.state.showModal) this.setState({ showModal: true });
          },
        },
        {
          selector: "#tour-11",
          content: "This is the import CSV page",
        },
        {
          selector: "#tour-12",
          content:
            "Select the template type if you're either uploading a CSV file from patreon or a simple CSV file that has simply email, tier, and notes on its three columns. If so, select the shieldshot template",
        },
        {
          selector: "#tour-13",
          content: "Then select your CSV file by clicking here.",
        },
        {
          content:
            "We won't be delving into this part much as this is an advanced feature.",
        },

        {
          selector: "#v-pills-distribution-tab",
          content: "Let's go to distributions next",
          action: () => {
            if (this.state.showModal) this.setState({ showModal: false });
          },
        },
        {
          content: "",
          action: () => {
            History.push("/distribution");
          },
        },
      ];

    if (this.state.init)
      return <Spinner className="mt-4" animation="border" variant="primary" />;

    return (
      <>
        <EndTour end={() => this.setState({ tourTime: false })} />
        <Tour
          steps={steps}
          rounded={25}
          isOpen={this.state.tourTime}
          closeWithMask={false}
          onRequestClose={() => {
            this.setState({ tourTime: false });
          }}
          lastStepNextButton={
            <span className="btn btn-outline-primary">Okay</span>
          }
        />
        <div className="mt-4">
          <Modal
            size="lg"
            id="tour-11"
            show={this.state.showModal}
            onHide={() => {
              this.setState({ showModal: false });
            }}
          >
            <EmailListImport
              tiers={this.state.tiers}
              ztiers={this.state.iTiers}
              simpleTiers={this.state.simpleTiers}
              token={this.props.token}
              hideModal={() => {
                this.setState({ showModal: false });
                this.getEmailListEndpoint();
              }}
            />
          </Modal>

          <div className="p-2 bg-white mb-3 shadow-sm rounded-5" id="tour-1">
            <Form onSubmit={this.submitEmailEndpoint}>
              <Form.Row>
                <Form.Group as={Col} md={6} className="mb-0">
                  <Form.Control
                    type="email"
                    onChange={this.handleEmail}
                    required
                    placeholder="Email"
                    className="m-1"
                    value={this.state.email}
                    id="tour-2"
                  />
                </Form.Group>
                <Form.Group as={Col} md={4} className="m-1" id="tour-3">
                  <TierDropdown
                    token={this.props.token}
                    action={this.handleTierChange}
                    defaultValue={0}
                  />
                </Form.Group>
                <Form.Group as={Col} className="mb-0">
                  <Button className="m-1" type="submit" id="tour-4">
                    {!this.state.isUploading ? (
                      " Add Email"
                    ) : (
                      <Spinner
                        animation="border"
                        size="sm"
                        variant="secondary"
                      />
                    )}
                  </Button>
                </Form.Group>
              </Form.Row>
            </Form>
          </div>

          <div className="mb-2 h40onLarge" id="collapseArea">
            <Collapse
              in={this.state.selectedEmails.length === 0 ? true : false}
            >
              <Row className="mb-2 " id="tour-6">
                <Col md={3} className="text-left align-bottom text-secondary">
                  <small>
                    {this.state.emailList.length}{" "}
                    {this.state.emailList.length === 1 ? "email" : "emails"}
                  </small>
                </Col>
                <Col sm={12} md={9} className="text-left text-md-right">
                  <Button
                    id="tour-7"
                    size="sm"
                    className="m-1 shadow-sm"
                    variant="outline-warning"
                    onClick={() => {
                      if (this.state.tableSize === "md") {
                        this.setState({ tableSize: "sm" });
                        localStorage.setItem("emailListTableSize", "sm");
                      } else {
                        this.setState({ tableSize: "md" });
                        localStorage.setItem("emailListTableSize", "md");
                      }
                    }}
                  >
                    <FontAwesomeIcon icon={faList} />
                  </Button>

                  <CSVLink
                    data={this.state.csvList}
                    filename="ShieldShot-Email-Export.csv"
                  >
                    <Button
                      size="sm"
                      variant="outline-primary"
                      className="m-1 shadow-sm"
                      id="tour-8"
                    >
                      <FontAwesomeIcon icon={faDownload} className="mr-2" />
                      Download Email List
                    </Button>
                  </CSVLink>

                  <Button
                    size="sm"
                    id="tour-9"
                    variant="outline-danger"
                    disabled={this.state.isDeleting}
                    className="m-1 shadow-sm"
                    onClick={() => {
                      this.setState(this.deleteAllEmails);
                    }}
                  >
                    {this.state.isDeleting ? (
                      <Spinner animation="border" size="sm" variant="danger" />
                    ) : (
                      <React.Fragment>
                        <FontAwesomeIcon
                          icon={faExclamationTriangle}
                          className="mr-2"
                        />
                        <span> Delete All Emails</span>
                      </React.Fragment>
                    )}
                  </Button>

                  <Button
                    size="sm"
                    id="tour-10"
                    variant="outline-success"
                    className=" m-1 shadow-sm"
                    onClick={() => {
                      this.setState({ showModal: true });
                    }}
                  >
                    <FontAwesomeIcon icon={faFileExcel} className="mr-2" />
                    Import CSV
                  </Button>
                </Col>
              </Row>
            </Collapse>

            <Collapse
              in={this.state.selectedEmails.length === 0 ? false : true}
            >
              <div className="py-1 text-left">
                <Link
                  disabled={this.state.isDeleting}
                  to={{
                    pathname: "/distribution/add",
                    state: {
                      emailList: this.state.selectedEmails,
                      version: "old",
                    },
                  }}
                >
                  <Button
                    variant="outline-secondary"
                    size="sm"
                    className=" btn-sm m-1"
                    disabled={this.state.isDeleting}
                  >
                    Send Rewards
                  </Button>
                </Link>
                <Button
                  className=" btn-danger btn-sm m-1 "
                  onClick={() => this.emailDeleteEndpointV2(0)}
                  disabled={this.state.isDeleting}
                >
                  {this.state.isDeleting ? (
                    <Spinner animation="border" variant="light" size="sm" />
                  ) : (
                    <>
                      <FontAwesomeIcon icon={faTrash} className="mr-1" /> Delete
                      Emails
                    </>
                  )}
                </Button>
              </div>
            </Collapse>
          </div>

          <div
            className="fancyScroll pr-2 "
            style={{
              maxHeight: "700px",
              overflowY: "auto",
              overflowX: "hidden",
            }}
          >
            <Table
              striped
              size={this.state.tableSize}
              responsive
              hover
              className={
                !this.state.isDeleting
                  ? " text-left bg-white mb-0 animated shadow-sm "
                  : " text-left bg-white mb-0 animated shadow-sm disabled"
              }
              id="tour-5"
            >
              <thead>
                <tr className="font-weight-bold text-left">
                  <th>
                    {" "}
                    <Form.Check
                      id="checkbox-all"
                      aria-label="option 1"
                      onChange={this.checkAll}
                      checked={this.state.selectAll}
                    />
                  </th>
                  <th className="col-xs-1">Tier</th>
                  <th>E-mail</th>
                  <th>Notes</th>
                  {/* <th className="text-right pr-4">Actions</th> */}
                </tr>
              </thead>

              {!this.state.emailList.length ? (
                <tbody>
                  <tr>
                    <td colSpan="5" className="text-center">
                      {this.state.init ? (
                        <Spinner animation="grow" />
                      ) : (
                        <h5 className="text-secondary">
                          You don't have any emails added yet.
                        </h5>
                      )}
                    </td>
                  </tr>
                </tbody>
              ) : (
                <tbody>
                  {this.state.emailList.map((list, index) => (
                    <EmailListItem
                      key={list.id}
                      data={list}
                      index={index}
                      onDel={this.emailDeleteEndpoint}
                      submitEmail={this.submitEditEmail}
                      submitNote={this.submitEditNote}
                      tierData={this.state.tiers}
                      submitTier={this.submitEditTier}
                      customId={index}
                      toggleSelect={this.toggleSelect}
                    />
                  ))}
                </tbody>
              )}
            </Table>
          </div>
        </div>
      </>
    );
  }
}

export default EmailList;
