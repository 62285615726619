import React, { Component } from "react";
import ListGroup from "react-bootstrap/ListGroup";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

class TierDetailsEmailListItem extends Component {
  render() {
    return (
      <ListGroup.Item>
        <Row>
          <Col>{this.props.data.email}</Col>
        </Row>
      </ListGroup.Item>
    );
  }
}

export default TierDetailsEmailListItem;
