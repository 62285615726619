import React, { Component } from "react";
import ListGroup from "react-bootstrap/ListGroup";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import renderHTML from "react-render-html";
import Spinner from "react-bootstrap/Spinner";
import Card from "react-bootstrap/Card";
import Nav from "react-bootstrap/Nav";
//import { Link } from "react-router-dom";

import Moment from "moment";
import MomentLocalizer from "react-widgets-moment";
Moment.locale("en");
MomentLocalizer();

class Activity extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      activities: [],
      loading: true,
      message: "",
      uniques: [],
      sortedActivities: [],
      selectedActivity: [],
      baseUnique: "",
    };
  }

  componentDidMount() {
    this._isMounted = true;
    window.scrollTo(0, 0);

    if (localStorage.getItem("activity")) {
      const sortedActivities = JSON.parse(localStorage.getItem("activity"));

      if (sortedActivities.length) {
        const uniques = JSON.parse(localStorage.getItem("uniqueActivities"));

        this.setState({
          sortedActivities: sortedActivities,
          selectedActivity: sortedActivities[uniques[0]],
          baseUnique: "#" + uniques[0],
          uniques: uniques,
          loading: false,
        });
      }
    }

    this.getActivityEndpoint();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  getActivityEndpoint = async () => {
    try {
      const token = await this.props.token();
      const response = await fetch(
        "https://api.shieldshot.com/activities/list",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          method: "POST",
        }
      );

      const responseData = await response;
      const responseJson = await responseData.json();

      if (responseData.status === 200) {
        let message = "";
        if (responseJson.length === 0) message = "No logs yet.";
        if (this._isMounted)
          this.setState({
            message: message,
            activities: responseJson,
          });

        let uniques = [];

        responseJson.forEach((item) => {
          if (!uniques.includes(item.activity.S.split(" ")[0])) {
            uniques.push(item.activity.S.split(" ")[0]);
          }
        });

        if (this._isMounted) this.setState({ uniques });

        let sortedActivities = {};

        responseJson.forEach((item) => {
          // if (item.remarks.S.search("<a href=") !== -1) {
          //   //let oldLink = item.remarks.S.match(/(?<==)(.*?)(?=>)/g);
          //   let link = item.remarks.S.match(/(?<=shieldshot.com)(.*?)(?=>)/g);
          //   let tag = item.remarks.S.match(/(<a href=)([^"]*>[^<]*<\/a>)/g);
          //   let indexOf = item.remarks.S.indexOf(tag[0]);
          //   let linkContent = tag[0].match(/(?<=>)(.*?)(?=<\/a>)/g);

          //   try {
          //     let body = item.remarks.S.replace(tag[0], "");

          //     if (indexOf === 0) {
          //       item.remarks.S = (
          //         <>
          //           <Link to={link[0]}>{linkContent[0]}</Link>{" "}
          //           {renderHTML(body)}
          //         </>
          //       );
          //     } else {
          //       item.remarks.S = (
          //         <>
          //           {renderHTML(body)}{" "}
          //           <Link to={link[0]}>{linkContent[0]}</Link>
          //         </>
          //       );
          //     }
          //   } catch (err) {
          //     console.log("err", err);
          //   }
          // } else
          //item.remarks.S = renderHTML(item.remarks.S);
          // item.remarks.S = item.remarks.S.replace("<a href=", "<Link to=");
          // item.remarks.S = item.remarks.S.replace("</a>", "</Link>");

          uniques.forEach((activity) => {
            if (!sortedActivities[activity]) sortedActivities[activity] = [];

            if (activity === item.activity.S.split(" ")[0]) {
              sortedActivities[activity].push(item);
            }
          });
        });

        localStorage.setItem("activity", JSON.stringify(sortedActivities));
        localStorage.setItem("uniqueActivities", JSON.stringify(uniques));

        let sorter = uniques[0];

        if (this.props.location.hash) {
          sorter = this.props.location.hash.substr(1);
        }

        if (this._isMounted)
          this.setState({
            sortedActivities: sortedActivities,
            selectedActivity: sortedActivities[sorter],
            baseUnique: "#" + sorter,
            loading: false,
          });

        // console.log(this.state.baseUnique);
      } else if (responseData.status === 502) {
        if (this._isMounted) this.setState({ loading: false });
      } else {
        console.log("async failed");
        if (this._isMounted) this.setState({ loading: false });
      }

      //console.log("ASYNC RESPONSE COMPLETE", responseData);
    } catch (error) {
      console.warn("ASYNC  SUBMIT FAILURE", error);
      if (this._isMounted) this.setState({ loading: false });
    }
  };

  changeSelection = (e) => {
    const tab = e.substr(1);

    if (this._isMounted)
      this.setState({ selectedActivity: this.state.sortedActivities[tab] });
  };

  render() {
    if (this.state.loading)
      return <Spinner className="mt-4" animation="border" variant="primary" />;

    if (this.state.message === "No logs yet.")
      return (
        <div className="align-center pt-5">
          <div className="mt-5 p-5 shadow-lg max-400 text mx-auto">
            <h4 className="text-secondary futura fnt-700">No logs here yet.</h4>
            <small>
              This section gets filled up when you use ShieldShot's service
            </small>
          </div>
        </div>
      );

    return (
      <Card className="mt-4">
        <Card.Header className="bodyBackground">
          <Nav
            variant="tabs"
            onSelect={this.changeSelection}
            defaultActiveKey={this.state.baseUnique}
          >
            {this.state.uniques.map((item, index) => (
              <Nav.Item key={index}>
                <Nav.Link
                  href={"#" + item.split(" ")[0]}
                  className="font-weight-bold"
                >
                  {item.split(" ")[0] + "s"}
                </Nav.Link>
              </Nav.Item>
            ))}
          </Nav>
        </Card.Header>
        <Card.Body className="p-0">
          <ListGroup variant="flush" className="text-left">
            {this.state.message ? (
              <h5>{this.state.message}</h5>
            ) : (
              <>
                {this.state.selectedActivity.map((item, index) => (
                  <ListGroup.Item key={index}>
                    <small>
                      <Row>
                        <Col className="font-weight-bold">
                          {item.activity.S}
                        </Col>

                        <Col md={8} className="text-secondary text-right">
                          {Moment.unix(item.createdAt.S).format("LLL")}
                        </Col>
                      </Row>
                      <Row>
                        <Col> {renderHTML(item.remarks.S)}</Col>
                        {/* <Col> {item.remarks.S}</Col> */}
                      </Row>
                    </small>
                  </ListGroup.Item>
                ))}
              </>
            )}
          </ListGroup>
        </Card.Body>
      </Card>
    );
  }
}

export default Activity;
